import {
    GET_REQUEST,
    SET_REQUEST,
} from '../constants/actiontypes';
import RequestService from "../services/request.service";
import { ErrorHandler } from '../services/ErrorHandler';

export const getRequest = (query) => {
    return dispatch => {
        return ErrorHandler(dispatch)(RequestService.getRequest(query))
            .then(data => {
                dispatch({
                    type: GET_REQUEST,
                    payload: { data },
                });
                return Promise.resolve(data);
            }).catch(error => {
                const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
                return Promise.reject(message);
            });
    }
}

export const setRequest = () => {
    return dispatch => {
        return ErrorHandler(dispatch)(RequestService.setRequest())
            .then(data => {
                dispatch({
                    type: SET_REQUEST,
                    payload: { data },
                });
                return Promise.resolve(data);
            }).catch(error => {
                const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
                return Promise.reject(message);
            });
    }
}

export const updateRequestReviewed = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(RequestService.updateRequestReviewed(id))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
                return Promise.reject(message);
            });
    }
}

export const deleteRequest = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(RequestService.deleteRequest(id))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
                return Promise.reject(message);
            });
    }
}