import {
    GET_ALL_FORUM,
    GET_EDIT_FORUM,
    POST_NEW_FORUM,
    UPDATE_FORUM,
    PINNED_FORUM
} from '../constants/actiontypes';
import ForumService from "../services/forum.service";
import { ErrorHandler } from '../services/ErrorHandler';

export const getAllForum = (query) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ForumService.getAllForum(query))
        .then(data => {
            dispatch({
                type: GET_ALL_FORUM,
                payload: { forums: data },
            });
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const getEditForum = (id, editPost) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ForumService.getEditForum(id, editPost))
        .then(data => {
            dispatch({
                type: GET_EDIT_FORUM,
                payload: { forums: data }
    
            });
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
}

export const postForumEntries = (posts) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ForumService.postForumEntries(posts))
        .then(data => {
            dispatch({
                type: POST_NEW_FORUM,
                payload: { forums: data }
    
            });
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const forumposts = (post) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ForumService.forumposts(post))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const updateForumEntries = (id, editForum) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ForumService.updateForumEntries(id, editForum))
        .then(data => {
            dispatch({
                type: UPDATE_FORUM,
                payload: { forums: data }
            });
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const deleteForumPost = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ForumService.deleteForumPosts(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const deleteForum = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ForumService.deleteForum(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        }); 
    }
}

export const pinnedForums = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ForumService.pinnedForums(id))
        .then(data => {
            dispatch({
                type: PINNED_FORUM,
                payload: { data }
            });
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
            return Promise.reject(message);
        });
    }
}
export const restoreForum = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ForumService.restoreForum(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
            return Promise.reject(message);
        });
    }
}