import {
    GET_USERS,
    UPDATE_USER,
    RESTORE_USER,
    GET_CURRENT_USER,
    UPDATE_ADMIN_PROFILE
} from '../constants/actiontypes';
import UsersService from "../services/users.service";
import { ErrorHandler } from '../services/ErrorHandler';
import store from '../store/index'
import TokenService from '../services/token.service';

export const getUsers = (query) => {
    return dispatch => {
        return ErrorHandler(dispatch)(UsersService.getUsers(query))
        .then(data => {
            dispatch({
                type: GET_USERS,
                payload: { users: data },
            });
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const getUser = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(UsersService.getUser(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const addNewUser = (post) => {
    return dispatch => {
        return ErrorHandler(dispatch)(UsersService.addNewUser(post))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) ||  error.response.data || error.message || error.toString();
            return Promise.reject(message);
        });
    }
}

export const updateUser = (id, post) => {
    return dispatch => {
        return ErrorHandler(dispatch)(UsersService.updateUser(id, post))
        .then(data => {
            dispatch({
                type: UPDATE_USER,
                payload: { post }
            });
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
            return Promise.reject(message);
        });
    }
}

export const deleteUser = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(UsersService.deleteUser(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const restoreUser = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(UsersService.restoreUser(id))
        .then(data => {
            dispatch({
                type: RESTORE_USER,
                payload: { id }
            });
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error)
        }); 
    }
}

export const getCurrentUser = () => {
    return dispatch => {
        return ErrorHandler(dispatch)(UsersService.getCurrentUser())
        .then(data => {
            const localStorageUserAndToken = store.getState().LoginReducer.user;
            localStorageUserAndToken.user = data?.data;
            TokenService.setUser(localStorageUserAndToken);

            dispatch({
                type: GET_CURRENT_USER,
                payload: { data }
            });
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error)
        });
    }
}

export const updateAdminProfile = (profileData) => {
    return dispatch => {
        return ErrorHandler(dispatch)(UsersService.updateAdminProfile(profileData))
        .then(data => {
            dispatch({
                type: UPDATE_ADMIN_PROFILE,
                payload: { data }
            });
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
            return Promise.reject(message);
        });     
    }
}