import React, { useState } from 'react'
import SelectUserModal from '../Modal/SelectUserModal';
import UserImage from './User/UserImage';

const SelectUserComponent = ({ multiple = false, selected, onSelect, user, label="Connect user", canChange=false, error="" }) => {
    
    const [showModal, setShowModal] = useState(false);
    return (
        <div className={`select-user-box ${error?.length > 0 ? 'validation-error': ''}`}>
            {!user ?
                <div className="empty-user" onClick={() => setShowModal(true)}>
                    <div className="plus-wrapper">
                        <i className="fa fa-plus"></i>
                    </div>

                    <p className="placeholder-text">{label}</p>                   
                </div>
            :
                <div className="filled-user">
                    <div className="user-image-wrapper">
                        <UserImage
                            role={user?.user_role}
                            image_url={user?.profile_picture}
                        />
                    </div>
                    <div className="user-text">
                        <p className='detail-text name'>{user?.name}</p>
                        <p className="detail-text username">@{user?.username}</p>
                        <span className="detail-text userrole">{user?.user_role}</span>
                    </div>
                    {canChange &&
                        <div className="change-btn-wrapper ms-auto">
                            <button type="button" onClick={() => setShowModal(true)} className="main-btn-capsule">Change</button>
                        </div>
                    }
                </div>
            }
            
            <SelectUserModal
                show={showModal}
                closeModal={() => setShowModal(false)}
                multiple={multiple}
                onSelect={(details) => {
                    setShowModal(false)
                    onSelect(details)
                }}
            />

            {error?.length > 0 && <p className='validation-error-text'>{error}</p>}
        </div>
    )
}

export default SelectUserComponent