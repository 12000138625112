import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import Profile from '../components/Profile';
import { ImageResizer } from "../Common/ImageResizer";
import { ARTICLE_IMAGE_RATIO } from "../../constants/imageRatio";
import { ErrorMessage } from '../../constants/ErrorMessage';
import ToggleCheckbox from '../components/ToggleCheckbox';
import ButtonComponent from '../components/General/ButtonComponent';
import { storeGalleryMethod } from '../../actions/GalleryAction';
import AddGalleryEmptyBox from '../components/Gallery/AddGalleryEmptyBox';
import GalleryFile from '../components/Gallery/GalleryFile';
import { CloudinaryFolders, MediaTypes } from '../../constants/Constants';
import SelectUserComponent from '../components/SelectUserComponent';
import { UploadFile } from '../../services/upload.service';
import TitleTextArea from '../components/TitleTextArea';
import FeaturedImageComponent from '../components/FeaturedImageComponent';
import swal from 'sweetalert';
import { deleteAssetsMethod } from '../../actions/CloudinaryAction';

const CreateGallery = () => {
    const navigate = useNavigate();

    const featuredImageResizeRef = useRef();

    /** Redux */
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.UsersReducer.current_user.data);

    /** State declaration */
    const [localFiles, setLocalFiles] = useState([]);
    const [errorFiles, setErrorFiles] = useState([]);
    const [currentUploadingIndex, setCurrentUploadingIndex] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);   
    const [gallery, setGallery] = useState({title : "", featured_image: "", gallery_files: [], featured: false});

    useEffect(() => {
        UploadLocalFile()
    }, [localFiles])

    const [refContainer, setRefContainer] = useState({
        featureImageRef: useRef(),
        container: {},
        current: null
    })

    const featuredStatus = (e) => {
        const { checked } = e.target;
        setGallery(prev => ({
            ...prev,
            featured: checked,
        }));
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setGallery((prev) => {
            return {...prev, [name]: value }
        });
    };

    const HandleSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true)

        let toastMessage = null;

        if(localFiles.length > 0 || currentUploadingIndex){
            toastMessage = "Please wait, some files are uploading";
        }

        if (gallery?.title === "") {
            toastMessage = "Gallery title is required"
        }

        if (gallery?.featured_image === "") {
            toastMessage = "Gallery featured image is required";
        }

        if(toastMessage){
            toast.error(ErrorMessage.Title);
            setSubmitting(false);
            return false;
        }

        dispatch(storeGalleryMethod(gallery))
            .then(response => {
                toast.success(response.message);
                setSubmitting(false);
                navigate('/dashboard/galleries');
            })
            .catch(error => {
                console.log(error);
                setSubmitting(false);
            });
    };

    const resizeImage = (e) => {
        setRefContainer(prevState => {
            return {
                ...prevState,
                current: { ref: e.target }
            }
        })
        featuredImageResizeRef.current.resizeImage(e)
    }

    const removeErrorFile = (index) => {
        let tmpErrorFiles = [...errorFiles];
        tmpErrorFiles.splice(index, 1);
        setErrorFiles(tmpErrorFiles);
    }

    const removeFileFromCloudinary = (index, item) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to remove this gallery?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                const payload = {public_ids: [item.public_id], resource_type: item.resource_type}
                dispatch(deleteAssetsMethod(payload))
                    .then(res => {
                        setGallery(prev => {
                            let tmpGalleryFiles = [...prev.gallery_files];
                            tmpGalleryFiles.splice(index, 1);
                            return {...prev, gallery_files: tmpGalleryFiles}
                        })
                    }).catch(e => {
                        swal("Something went wrong", "", "error");
                        console.log(e);
                    });
            }
        });
    }

    const HandleAddFiles = (e) => {

        const tmpLocalFiles = e.target.files;

        const arrayFiles = [...localFiles];

        for (let fileIndex = 0; fileIndex <  tmpLocalFiles.length; fileIndex++) {
            const singleLocalFile = tmpLocalFiles[fileIndex];

            const objectUrl = URL.createObjectURL(singleLocalFile)
            singleLocalFile.objectUrl = objectUrl;

            let media_type = MediaTypes.IMAGE;

            if(singleLocalFile.type.startsWith('video/')){
                media_type = MediaTypes.VIDEO;
            }

            singleLocalFile.media_type = media_type;
            arrayFiles.push(singleLocalFile);            
        }

        setLocalFiles(arrayFiles);
    }

    const UploadLocalFile = async () => {
        const tmpLocalFiles = [...localFiles];

        if(tmpLocalFiles.length){
            setCurrentUploadingIndex(0);
            const uploadableFile = tmpLocalFiles[0];
            
            // const data = await fetch(uploadableFile.objectUrl);
            // const blob = await data.blob();

            // let uploadableFileName = uploadableFile?.media_type === "image" ? "Content.jpg" : "Content.mp4";
            UploadFile(uploadableFile, ({ loaded, total })  => {
                
            }, CloudinaryFolders.GalleryFile).then(resp => {
                return resp.data
            })
            .then(data => {
                setGallery(prev => {

                    let tmpGalleryFiles = [...prev.gallery_files]
                    tmpGalleryFiles.push(data);
                    
                    return {...prev, gallery_files : tmpGalleryFiles}
                })

                tmpLocalFiles.splice(0, 1);
                setCurrentUploadingIndex(null);
                setLocalFiles(tmpLocalFiles);
            }).catch(err => {
                let tmpErrorFiles = [...errorFiles];
                uploadableFile.error = err?.response?.data?.error?.message ?? err.toString();
                tmpErrorFiles.push(uploadableFile);
                setErrorFiles(tmpErrorFiles);

                setCurrentUploadingIndex(null);
                tmpLocalFiles.splice(0, 1);
                setLocalFiles(tmpLocalFiles);
            })
        }
    }

    const renderLocalFiles = localFiles.map((item, index) => {
        return <GalleryFile key={`local_${index}`}
            file={{...item, secure_url : item.objectUrl}}
            canUpload
            isLocal
            isUploading={index === currentUploadingIndex}
        />
    })

    const uploadedFiles = gallery.gallery_files.map((item, index) => {
        return <GalleryFile key={`uploaded_${index}`}
            file={{...item, media_type : item.resource_type}}
            onDelete={() => removeFileFromCloudinary(index, item)}
        />
    })

    const errorFilesRender = errorFiles.map((item, index) => {
        return <GalleryFile key={`error_${index}`}
            file={{...item, secure_url : item.objectUrl}}
            onDelete={() => removeErrorFile(index)}
            isLocal
        />
    })


    return (
        <>
            <div className="admin-dashboard users-panel">
                <header>
                    <Profile heading="Galleries" pathurl="" showbtn="no" />
                    <div className="article-action-panel">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-7 col-md-7 col-12">
                                <div className="action-box pe-0">
                                    <div className="back-to-user">
                                        <Link to="/dashboard/galleries">
                                            <img src="/images/Refund_back.svg" className="img-fluid" alt='' />
                                        </Link>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-12">
                                <div className="action-box align-items-center">                                    
                                    <div className="article-featured"></div>
                                    <div className="article-featured">
                                        <ToggleCheckbox
                                            isActive={gallery?.featured}
                                            label={`Featured`}
                                            onChange={featuredStatus}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="admin-dashboard-panel">
                    <div className="edit-article-section">
                        <form className="article-edit" action="#" method="POST">
                            <div className="row">
                                <div className="col-md-7 col-lg-7 col-12">
                                    <div className="chat-board">
                                        <div className="inner-chat">
                                            <TitleTextArea 
                                                value={gallery?.title}
                                                maxLength={30}
                                                onChange={(e) => handleInputChange(e)}
                                            />

                                            <div className='row gallery-files-wrapper'>
                                                <AddGalleryEmptyBox onChange={HandleAddFiles} />
                                                {uploadedFiles}
                                                {errorFilesRender}
                                                {renderLocalFiles}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-5 col-lg-5 col-12 ms-auto">
                                    <div className="right-section">
                                        <FeaturedImageComponent onChange={resizeImage} image_url={gallery?.featured_image} />
                                        <SelectUserComponent user={currentUser} />
                                        <div className="col-12 text-end mt-4">
                                            <ButtonComponent
                                                label='Save'
                                                disabled={gallery?.gallery_files?.length === 0}
                                                onClick={HandleSubmit}
                                                isLoading={isSubmitting}
                                                className='w-100'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
            <ImageResizer
                ref={featuredImageResizeRef}
                uploadedImageCallback={(data) => {
                    setGallery(prevState => ({
                        ...prevState,
                        featured_image: data.secure_url
                    }));
                }}
                resetState={() => {
                    refContainer.current.value = null
                }}
                IMAGE_RATION={ARTICLE_IMAGE_RATIO}
                folder={CloudinaryFolders.Gallery}
            />
        </>
    )
}

export default CreateGallery
