import {useEffect, useState} from "react";

export const SizeUtils = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [isMobile, setIsMobile] = useState(false)

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    },[])

    useEffect(() => {
       setIsMobile(width >= 320 && width <= 992)
    }, [width])

    return {
        width: width,
        height: height,
        isMobile: isMobile
    }
}