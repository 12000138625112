// Authorization
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";


// ARTICLES
export const GET_All_ARTICLES = "GET_All_ARTICLES";
export const GET_ARTICLES = "GET_ARTICLES";
export const CREATE_ARTICLES = "CREATE_ARTICLES";
export const UPDATE_ARTICLES = "UPDATE_ARTICLES";
export const PINNED_ARTICLES = "PINNED_ARTICLES";

//common
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";

// STORIES
export const GET_All_STORIES = "GET_All_STORIES";
export const GET_EDIT_STORIES = "GET_EDIT_STORIES";
export const POST_NEW_STORIES = "POST_NEW_STORIES";
export const UPDATE_STORIES = "UPDATE_STORIES";
export const PINNED_STORIES = "PINNED_STORIES";

// FORUM
export const GET_ALL_FORUM = 'GET_ALL_FORUM';
export const GET_EDIT_FORUM = 'GET_EDIT_FORUM';
export const POST_NEW_FORUM = 'POST_NEW_FORUM';
export const UPDATE_FORUM = 'UPDATE_FORUM';
export const FORUM_POSTS = 'FORUM_POSTS';
export const FORUM_POST_DELETE = 'FORUM_POST_DELETE'
export const DELETE_FORUM = 'DELETE_FORUM'
export const PINNED_FORUM = "PINNED_FORUM";

// Users
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const RESTORE_USER = 'RESTORE_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_EMPTY = 'GET_CURRENT_USER_EMPTY';

export const UPDATE_ADMIN_PROFILE = 'UPDATE_ADMIN_PROFILE';

// Common
export const SEND_MESSAGE = 'SEND_MESSAGE';

// Ads
export const GET_ADS = 'GET_ADS';
export const SET_ADS = 'GET_ADS';
export const UPDATE_ADS = 'UPDATE_ADS';

// Request 
export const GET_REQUEST = 'GET_REQUEST';
export const SET_REQUEST = 'SET_REQUEST';

// Event
export const GET_EVENT = 'GET_EVENT';
export const SET_EVENT = 'SET_EVENT';

// Dashboard
export const SET_DASHBOARD_PREFERENCES = 'SET_DASHBOARD_PREFERENCES';