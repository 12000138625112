import { 
    GET_ADS,
    SET_ADS
} from '../constants/actiontypes';

const initialState = {
    ads: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ADS:
            return {
                ...state,
                ads: payload,
            };
        case SET_ADS:
            return {
                ...state,
                ads: payload,
        };
        default:
            return state;
    }
}