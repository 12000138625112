import React, { useState } from 'react'
import { SerachIcons } from '../Icon'

const SearchComponent = ({ isLoading, onChange, placeholder="Search" }) => {

    const [timeInterval, setTimeInterval] = useState(null);

    const HandleOnChange = (e) => {
        const { value } = e.target;

        if (timeInterval) {
            clearInterval(timeInterval);
        }

        setTimeInterval(setTimeout(() => onChange(value), 1000));
    }

    return (
        <div className="search-form w-100">
            <div className="form-group">
                <div className="user-types">
                    <input type="text" name="search_list" onChange={HandleOnChange} placeholder={placeholder} className='form-control' />
                    {!isLoading ? (<SerachIcons />) : (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                </div>
            </div>
        </div>
    )
}

export default SearchComponent