import React from 'react'
import { CameraSVG, TrashSVG, VideoSVG } from '../Icon'
import { MediaTypes } from '../../../constants/Constants'
import { convertUrlToJpg, getThumbnail } from '../../Common/AHImage'

const GalleryFile = ({file, onDelete, canUpload=false, isUploading = false, isLocal=false}) => {
  return (
    <div className="col-lg-3 col-md-3">
        <div className="gallery-file">
          {!isLocal && file?.media_type === MediaTypes.VIDEO ?
            <img className='gallry-file-img img-fluid' src={convertUrlToJpg(getThumbnail(file?.secure_url))} alt="" />
            :
            <img className='gallry-file-img img-fluid' src={getThumbnail(file?.secure_url)} alt="" />
          }

          {file?.error &&
            <div className="overlay">
                <p className="m-0 text-danger">{file?.error}</p>
            </div>
          }

          {canUpload &&
            <div className="overlay">
              <button type='button' disabled={true} className="btn main-btn">{isUploading ? 'Uploading' : 'Waiting to upload'}</button>
            </div>
          }
          <div className="media-type-icon">
            {file?.media_type === MediaTypes.IMAGE && <CameraSVG /> }
            {file?.media_type === MediaTypes.VIDEO && <VideoSVG /> }
          </div>

          {onDelete &&
            <div className="delete-icon" onClick={onDelete}>
              <TrashSVG />
            </div>
          }
        </div>
    </div>
  )
}

export default GalleryFile