import api from "./api";

const getUsers = async (query) => {
    let users = "";
    if (query !== undefined){
         users = "users" + query;
    }else{
         users = "users";
    }
    return await api.get(users)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
    });
};

const getUser = async (id) => {
    return await api.get(`users/${id}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        });
};

const addNewUser = async (post) => {
    return await api.post("users", post)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                return response.data;
            }
        });
};

const updateUser = async (id, post) => {
    return await api.put(`users/${id}`, post)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                return response.data;
            }
        });
};

const deleteUser = async (id, res, req) => {
    return await api.delete(`users/${id}`, (req, res))
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        });
};

const restoreUser = async (id) => {
    return await api.get(`users/${id}/restore`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        });
};

const getCurrentUser = async () => {
    return await api.get("user")
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    });
};

const updateAdminProfile = async (profileData) => {
    return await api.post("user", profileData)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        });
};

export default {
    getUsers,
    getUser,
    addNewUser,
    updateUser,
    deleteUser,
    restoreUser,
    updateAdminProfile,
    getCurrentUser
}