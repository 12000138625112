const ToggleCheckbox = ({ isActive, onChange, label, className, value }) => {
    const randomNumber = Math.random();
    return (
        <div className={`form-switch ${className ? className : ''} ${isActive && "active"}`} >
            <label className={`form-check-label`} htmlFor={`checkbox_random_${randomNumber}`}>{label}</label>
            <input
                value={value ? value : 1}
                onChange={onChange}
                className="form-check-input"
                type="checkbox"
                checked={isActive}
                role="switch"
                id={`checkbox_random_${randomNumber}`}
            />
        </div>
    )
}

export default ToggleCheckbox;