import api from "./api";

const getAllForum = async (query) => {

    const url = "forums" + query;
    return await api.get(url)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        });
};

const getEditForum = async (id, editPost) => {
    return await api.get(`forums/${id}`, editPost)
        .then((response) => {
            if (response.status === 200) {
                return response.data
            }
            else {
                return response.data;
            }
        })
}
const postForumEntries = async (posts) => {
    return await api.post('forums', posts)
        .then((response) => {
            if (response.status === 200) {
                return response.data.data
            } else {
                return response.data
            }
        });
}
const updateForumEntries = async (id, editForum) => {
    return await api.put(`forums/${id}`, editForum)
        .then((response) => {
            if (response.status === 200) {
                return response.data
            } else {
                return response.data
            }
        });
}

// forum posts
const forumposts = async (post) => {
    return await api.post('forums/posts', post)
        .then((response) => {
            if (response.status === 200) {
                return response.data.data
            } else {
                return response.data
            }
        });
}

const deleteForumPosts = async (id, res, req) => {
    const url = `forums/posts/${id}`;
    return await api.delete(url, (res, req))
        .then((response) => {
            if (response.status === 200) {
                return response.data.data
            } else {
                return response.data
            }
        });
}


const deleteForum = async (id, res, req) => {
    return await api.delete(`forums/${id}`, (res, req))
        .then((response) => {
            if (response.status === 200) {
                return response.data.data
            } else {
                return response.data
            }
        });
}


const pinnedForums = async (id) => {
    const url = `forums/${id}/toggle-pinned`;
    return await api.get(url)
    .then((response) => {
        if (response.status === 200) {
            return response.data.data
        }
    });
}

const restoreForum = async (id) => {
    const url = `forums/${id}/restore`;
    return await api.get(url)
    .then((response) => {
        if (response.status === 200) {
            return response.data.data
        }
    });
}

export default {
    getAllForum,
    getEditForum,
    postForumEntries,
    updateForumEntries,
    forumposts,
    deleteForumPosts,
    deleteForum,
    pinnedForums,
    restoreForum
}