import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { ReadOnlyIcon } from '../components/Icon';
import { NewTimetableScoreObject } from '../../constants/Constants';
import { useDispatch } from 'react-redux';
import { setTimeTableScoreMethod } from '../../actions/TimeTableAction';
import { toast } from 'react-hot-toast';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const TimetableScoreModal = ({ show, closeModal, timetable, getTimetables }) => {

    const dispatch = useDispatch();
    const [editing, setEditing] = useState(null);
    const [scores, setScores] = useState([]);
    const [deletableScores, setDeletableScores] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [scoreErrors, setScoreErrors] = useState({});

    useEffect(() => {
        if (timetable?.scores?.length) {
            setScores([...timetable?.scores]);
        } else {
            setScores([]);
        }
    }, [timetable])

    const handleDeleteScore = (index, id) => {
        const rows = [...scores];
        rows.splice(index, 1);
        setScores(rows);

        if (id !== 0) {
            setDeletableScores([...deletableScores, id]);
        }
    }

    const handleChangeScoring = (evnt, index) => {
        if (evnt.target.checkValidity()) {
            const { name, value } = evnt.target;
            const list = [...scores];
            list[index][name] = value;
            setScores(list);
        }
    }

    const handleClickAddNewScore = () => {
        setScoreErrors({})
        let tmpScores = [...scores];
        tmpScores.push({ ...NewTimetableScoreObject })
        setScores(tmpScores)
        setEditing(tmpScores?.length - 1);
    }

    const HandleSubmitScores = (e) => {
        setScoreErrors({})
        e.preventDefault();
        setIsSubmitting(true);
        const payload = { scores: scores, deletable: deletableScores }

        dispatch(setTimeTableScoreMethod(timetable?.event_id, timetable?.id, payload))
            .then((res) => {
                setIsSubmitting(false);
                toast.success(res.message);
                getTimetables()
                closeModal()
            }).catch((err) => {
                setIsSubmitting(false);
                toast.error(err?.message);
                setScoreErrors(err?.errors)
            });
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(scores);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setScores(items);
    }

    const renderScores = scores?.map((item, index) => {
        return (
            <Draggable disableInteractiveElementBlocking={false} key={`score_${index}`} draggableId={`score_${index}`} index={index}>
                {(provided) => (
                    <tr key={index} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <td>
                            <img src="/images/app-toggle.png" className="img-fluid" alt="" />
                        </td>

                        <td>
                            <input type="text"
                                readOnly={editing !== index}
                                name="number" value={item?.number}
                                pattern="[0-9]*"
                                onChange={(e) => handleChangeScoring(e, index)}
                                className={`form-control ${scoreErrors['scores.' + index + '.number'] ? 'is-invalid' : ''}`}
                                placeholder="Nr."
                            />
                        </td>

                        <td>
                            <input type="text"
                                readOnly={editing !== index}
                                name="contestant"
                                value={item?.contestant}
                                onChange={(e) => handleChangeScoring(e, index)}
                                className={`form-control ${scoreErrors['scores.' + index + '.contestant'] ? 'is-invalid' : ''}`}
                                placeholder="Enter horse name"
                            />
                        </td>

                        <td>
                            <input type="text"
                                readOnly={editing !== index}
                                name="comment"
                                value={item?.comment}
                                onChange={(e) => handleChangeScoring(e, index)}
                                className="form-control"
                                placeholder="Enter comment"
                            />
                        </td>

                        <td>
                            <input type="text"
                                readOnly={editing !== index}
                                name="score"
                                value={item?.score}
                                pattern="^\d*(\.\d{0,2})?$"
                                onChange={(e) => handleChangeScoring(e, index)}
                                className={`form-control ${scoreErrors['scores.' + index + '.score'] ? 'is-invalid' : ''}`}
                                placeholder="00.00"
                            />
                        </td>

                        <td>
                            <div className='ml-0 d-flex justify-content-between align-items-center'>
                                <span onClick={(e) => handleDeleteScore(index, item?.id)}>
                                    <a><img src="/images/delete-icon.svg" alt="" /></a>
                                </span>
                                &nbsp;
                                <ReadOnlyIcon onClick={() => setEditing(index)} />
                            </div>
                        </td>
                    </tr>
                )}
            </Draggable>
        )
    })

    return (
        <Modal show={show} size="lg" centered onHide={() => closeModal()}>
            <Modal.Body>
                <div className="modal-header">
                    <div className='d-flex'>
                        <img onClick={() => closeModal()} src="/images/Refund_back.svg" className="img-fluid" alt='' />
                        <h5 className='m-0 ms-3'>Add scoring</h5>
                    </div>
                </div>

                <div className="modal-body">
                    <form onSubmit={(e) => HandleSubmitScores(e)}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Nr.</td>
                                    <td>Horse Name</td>
                                    <td>Comment</td>
                                    <td>Score</td>
                                    <td></td>
                                </tr>
                            </thead>

                            <DragDropContext
                                onDragEnd={handleOnDragEnd}
                            >
                                <Droppable droppableId="characters">
                                    {(provided) => (
                                        <tbody className="characters nav-panel" {...provided.droppableProps} ref={provided.innerRef}>
                                            {renderScores}
                                        </tbody>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            <tfoot>
                                <tr>
                                    <td>
                                        <button type="button" onClick={handleClickAddNewScore} className="btn btn-primary">Add</button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className='d-flex justify-content-between align-items-center'>
                            {/**
                                Object.keys(scoreErrors).length > 0 && (
                                    <div className="alert alert-danger">
                                        <ul className="mb-0">
                                            {
                                                Object.entries(scoreErrors).map(([key, value]) => (
                                                    <li key={key}>{value}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                )
                            */}
                            <div className='text-end'>
                                <button type='submit' className="btn main-btn" disabled={isSubmitting}>
                                    Save Changes &nbsp; {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="fa-sharp fa-solid fa-arrow-right"></i>}
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default TimetableScoreModal