import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Profile from '../components/Profile'
import { useDispatch } from 'react-redux';
import { getCategories } from '../../actions/CategoryAction';
import { Roles } from '../../constants/roles';
import toast from 'react-hot-toast';
import { sendPushNotificationMethod } from '../../actions/PushNotificationAction';
import { getUsers } from '../../actions/UsersAction';
import { getAllStories } from '../../actions/StoriesAction';
import { getAllArticles } from '../../actions/ArticlesAction';
import { getAllForum } from '../../actions/ForumAction';
import Select from 'react-select';
import TitleTextArea from '../components/TitleTextArea';

const NewPushNotification = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [linkModule, setLinkModule] = useState("");

    const [selectedOption, setSelectedOption] = useState([]);
    const [inputTimeout, setInputTimeout] = useState(null);
    const [userLoading, setUserLoading] = useState(false);

    const [targetGroups, setTargetGroups] = useState([
        {
            name: "All Users",
            value: "All"
        },
        {
            name: "By Category",
            value: "Category"
        },
        {
            name: "By Device",
            value: "Device"
        },
        {
            name: "By Role",
            value: "Role"
        }
    ])

    const [notification, setNotification] = useState({
        title: "",
        content: "",
        target_group: targetGroups[0].value,
        target_group_ids: [],
        link: null,
    })

    useEffect(() => {
        if (linkModule.length === 0) {
            setNotification((pre) => {
                return {
                    ...pre,
                    link: null
                }
            })
        }
    }, [linkModule])

    useEffect(() => {
        if (notification.title.length !== 0 && notification.content.length !== 0) {
            if (notification.target_group === "All") {
                setButtonDisabled(false)
            } else if (notification.target_group_ids.length === 0) {
                setButtonDisabled(true)
            } else {
                setButtonDisabled(false)
            }
        } else {
            setButtonDisabled(true)
        }
    }, [notification])


    useEffect(() => {
        dispatch(getCategories()).then((res) => {
            setCategories(res.data)
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    const onChangeTargetGroup = (e) => {
        const { value } = e.target;
        setNotification({ ...notification, target_group: value, target_group_ids: [] })
    }

    const onChangeTargetGroupIds = (e) => {
        const { value } = e.target;
        let tmpids = [...notification.target_group_ids];
        if (notification.target_group_ids.includes(value)) {
            tmpids = tmpids.filter((ti) => ti !== value)
        } else {
            tmpids.push(value)
        }

        setNotification({ ...notification, target_group_ids: tmpids })
    }

    const getPlaceholderTitle = (module) => {
        switch (module) {
            case 'user':
                return 'Search User Name';
            case 'story':
                return 'Search Story';
            case 'article':
                return 'Search Article';
            case 'forum':
                return 'Search Forum';
            default:
                return 'Please Search'
        }
    }
    const getPagination = (search) => {
        setUserLoading(true);
        let query = '?search=' + search;

        if (linkModule === "user") {

            dispatch(getUsers(query))
                .then((res) => {
                    setUserLoading(false);
                    if (res.status) {
                        const data = res.data.data;
                        const options = data?.map(user => ({
                            "value": user.id,
                            "label": user.first_name + " " + user.last_name
                        }));
                        setSelectedOption(options);
                    }
                }).catch(err =>
                    setUserLoading(false)
                );
        }
        if (linkModule === "story") {
            dispatch(getAllStories(query))
                .then(res => {
                    const data = res.data;
                    setUserLoading(false);
                    const options = data?.map(story => ({
                        "value": story?.id,
                        "label": story?.title
                    }));
                    setSelectedOption(options);
                }).catch(err =>
                    setUserLoading(false)
                );
        }
        if (linkModule === "article") {
            dispatch(getAllArticles(query))
                .then(res => {
                    if (res.status) {
                        setUserLoading(false);
                        const data = res.data?.data;
                        const options = data?.map(article => ({
                            "value": article?.id,
                            "label": article?.title
                        }));

                        setSelectedOption(options);
                    }
                }).catch(err =>
                    setUserLoading(false)
                );
        }
        if (linkModule === "forum") {
            dispatch(getAllForum(query))
                .then((res) => {
                    setUserLoading(false);
                    const data = res.data.data;
                    const options = data?.map(forum => ({
                        "value": forum.id,
                        "label": forum.title
                    }));

                    setSelectedOption(options);
                }).catch(err => {
                    setUserLoading(false)
                });
        }
    }

    const onSubmitHandle = (e) => {
        e.preventDefault();

        setSubmitting(true)

        dispatch(sendPushNotificationMethod(notification)).then((res) => {
            setSubmitting(false)
            toast.success(res.message);
            navigate('/dashboard/push-notifications');
        }).catch((err) => {
            const message = err?.response?.data?.message ?? err.toString()
            toast.error(message);
            setSubmitting(false)
        })
    }
    const searchModuleValues = (e) => {

        if (inputTimeout) clearTimeout(inputTimeout)
        setInputTimeout(
            setTimeout(() => {
                getPagination(e)
            }, 1000)
        );
    }
    const handleSelectModule = (module) => {
        setNotification({ ...notification, link: linkModule + "/" + module.value })
    }

    const RenderTargetGroups = targetGroups.map((tg) => {
        return (
            <label key={tg.value} className="group-radio">
                <input
                    type="radio"
                    value={tg.value}
                    checked={notification.target_group === tg.value}
                    onChange={onChangeTargetGroup}
                />
                <span>{tg.name}</span>

            </label>
        )
    })

    const RenderTargetGroupsIds = () => {
        return (
            <>
                <div className="form-group">
                    {notification.target_group === "Category" &&
                        <div className="group-radio-ids-wrapper">
                            {categories?.map((category, index) => {
                                return (
                                    <div className="" key={'category_' + index}>
                                        <input className="form-check-input" type="checkbox" id={`category_` + category?.id}
                                            value={category?.id}
                                            onChange={(e) => onChangeTargetGroupIds(e)}
                                            defaultChecked={notification.target_group_ids?.includes(category?.id)}
                                        />
                                        <label className="form-check-label" htmlFor={`category_` + category?.id}>
                                            {category?.name}
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    }

                    {
                        notification.target_group === "Device" &&
                        <div className="group-radio-ids-wrapper">
                            <div className="" key="device_ios">
                                <input className="form-check-input" type="checkbox" id={`device_ios`}
                                    value={`ios`}
                                    onChange={(e) => onChangeTargetGroupIds(e)}
                                    defaultChecked={notification.target_group_ids?.includes("IOS")}
                                />
                                <label className="form-check-label" htmlFor={`device_ios`}>
                                    IOS
                                </label>
                            </div>

                            <div className="" key="device_android">
                                <input className="form-check-input" type="checkbox" id={`device_android`}
                                    value={`android`}
                                    onChange={(e) => onChangeTargetGroupIds(e)}
                                    defaultChecked={notification.target_group_ids?.includes("Android")}
                                />
                                <label className="form-check-label" htmlFor={`device_android`}>
                                    Android
                                </label>
                            </div>
                        </div>
                    }

                    {
                        notification.target_group === "Role" &&
                        <div className="group-radio-ids-wrapper">
                            {Roles?.map((role, index) => {
                                return (
                                    <div className="" key={'role_' + index}>
                                        <input className="form-check-input" type="checkbox" id={`role_` + index}
                                            value={role}
                                            onChange={(e) => onChangeTargetGroupIds(e)}
                                            defaultChecked={notification.target_group_ids?.includes(role)}
                                        />
                                        <label className="form-check-label" htmlFor={`role_` + index}>
                                            {role}
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <select className='form-select' onChange={(e) => { setSelectedOption([]); setLinkModule(e.target.value) }} value={linkModule} >
                            <option value="">Select Link</option>
                            <option value="user">User</option>
                            <option value="forum">Forum</option>
                            <option value="story">Story</option>
                            <option value="article">Article</option>
                        </select>
                    </div>
                </div>
                {linkModule?.length > 0 &&
                    (<div className='row mt-3'>
                        <div className='col-12'>
                            {/* <input
                    type="text"
                    placeholder={"Search"
                    +' '+ linkModule}
                    className='form-control'
                    onChange={(e)=>{searchModuleValues(e)}
                    } value={searchValue}
                    /> */}
                        </div>
                        <Select
                            placeholder={getPlaceholderTitle(linkModule)}
                            onInputChange={(e) => searchModuleValues(e)}
                            onChange={handleSelectModule}
                            isClearable
                            isSearchable
                            options={selectedOption}
                            isLoading={userLoading}

                        />
                    </div>)
                }
            </>

        )
    }

    return (

        <div className="admin-dashboard users-panel">
            <header>
                <Profile heading="New Notification" pathurl="push-notifications" showbtn="no" />
            </header>

            <div className="admin-dashboard-panel">
                <div className="article-action-panel">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="action-box pe-0 py-2">
                                <div className="back-to-user">
                                    <Link to="/dashboard/push-notifications">
                                        <img src="/images/Refund_back.svg" className="img-fluid" alt=' ' />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="message-panel notification-panel over">
                    <form className="edit-article-form new-notification-form" onSubmit={onSubmitHandle}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="inner-flow">
                                    <TitleTextArea
                                        value={notification.title}
                                        maxLength={60}
                                        placeholder='Title*'
                                        onChange={e => setNotification({ ...notification, title: e.target.value })}
                                    />

                                    <div className="form-group">
                                        <div className="message-content-box">
                                            <textarea name="message_content" placeholder="Body Text*" value={notification.content} onChange={e => setNotification({ ...notification, content: e.target.value })}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="article-sidebar">
                                    <div className="form-group">
                                        <label>Select Target:</label>
                                    </div>
                                    <div className="form-group mb-3">
                                        {RenderTargetGroups}
                                    </div>

                                    {RenderTargetGroupsIds()}

                                    <button disabled={buttonDisabled || isSubmitting} className="main-btn w-100 mt-3" name="save_message_data">
                                        Send Notifications Now &nbsp;

                                        {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                            <i className="fa-sharp fa-solid fa-arrow-right"></i>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewPushNotification
