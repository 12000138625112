
import {
    GET_USERS,
    UPDATE_USER,
    RESTORE_USER,
    GET_CURRENT_USER,
    UPDATE_ADMIN_PROFILE,
    GET_CURRENT_USER_EMPTY,
} from '../constants/actiontypes';

const initialState = {
    users: {},
    current_user:{},
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_USERS:
            return {
                ...state,
                users: payload.users,
            };
        // case GET_USER:
        //     return {
        //         ...state,
        //         users: payload.users,
        //     };
        case UPDATE_USER:
            return {
                ...state,
            };
        case RESTORE_USER:
            return {
                ...state,
            };
        case GET_CURRENT_USER:
            return {
                ...state,
                current_user: payload.data,
            };
        case GET_CURRENT_USER_EMPTY:
            return {
                ...state,
                current_user:null,
            };
        case UPDATE_ADMIN_PROFILE:
            return {
                ...state,
                current_user: payload.data,
            };
        default:
            return state;
    }
}

