import {
    GET_ALL_FORUM, 
    GET_EDIT_FORUM, 
    POST_NEW_FORUM, 
    UPDATE_FORUM, 
    DELETE_FORUM, 
    FORUM_POSTS,
    PINNED_FORUM
} from '../constants/actiontypes';
const initialState = {
    forums: {}
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_FORUM:
            return {
                ...state,
                forums: payload.forums,
            };
        case GET_EDIT_FORUM:
            return {
                ...state,
                forums: payload.forums,
            };
        case POST_NEW_FORUM:
            return {
                ...state
            };
        case FORUM_POSTS:
            return {
                ...state,
            };
        case UPDATE_FORUM:
            return {
                ...state,
                forums: payload.forums,
            };
        case DELETE_FORUM:
            return {
                ...state,
                forums: state?.forums?.data?.data?.filter(forum => forum.id !== payload?.id)
            };
        case PINNED_FORUM:
            const pinnedId = payload?.data?.id;
            const pinnedCheck = payload?.data?.pinned;
            let index =  state.forums.data.data.findIndex(item => item?.id === pinnedId);
            let forumsDetail =  state.forums.data.data;
            if (index !== -1){
                forumsDetail[index].pinned = pinnedCheck;
            }
            state.forums.data.data = forumsDetail
            return {
                ...state,
            };
        default:
            return state;
    }
}