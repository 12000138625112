import React, { useState } from 'react';

const ArenaField = ({ index, item, onChange, arenas, removeArena }) => {
    const [readonly, setReadonly] = useState(true);
    return (
        <div className='d-flex align-items-center arena-fields mb-3'>
            <input type="text"
                readOnly={readonly}
                onChange={(evnt) => onChange(index, evnt)}
                className={`action-control m-0 ${readonly ? 'readonly' : ''}`}
                value={item?.name}
                name='name'
                placeholder='Main Arena'
            />
            <button type="button" className='border-0 arena-edit-pen' onClick={(e) => { e.preventDefault(); setReadonly(!readonly) }}>
                <i className="fa-solid fa-pen"></i>
            </button>

            {(arenas?.length !== 1) && !item?.timetables_count ? <div className="delete-event-arena-btn" onClick={() => removeArena(index)}>
                <img src='/images/delete-icon.svg' style={{ width: '26px' }} alt="" />
            </div> : null}

            <img src="/images/app-toggle.png" className="img-fluid" alt="" />
        </div>
    );
}

export default ArenaField;