import PushNotificationService from "../services/PushNotification.service";
import { ErrorHandler } from '../services/ErrorHandler';

export const sendPushNotificationMethod = (payload) => {
    return dispatch => {
        return ErrorHandler(dispatch)(PushNotificationService.sendPushNotification(payload))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                return Promise.reject(error);
            });
    }
}

export const getPushNotificationsMethod = (query) => {
    return dispatch => {
        return ErrorHandler(dispatch)(PushNotificationService.getPushNotifications(query))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                return Promise.reject(error);
            });
    }
}

export const getSinglePushNotificationMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(PushNotificationService.getSinglePushNotification(id))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                return Promise.reject(error);
            });
    }
}