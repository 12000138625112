import React from "react";
import { convertUrlToJpg } from "../../Common/AHImage";

const Story = ({ items}) => {
  const renderStories = items.map((item) => {
    return (
      <div className="story-container text-center" key={`story_${item?.id}`}>
        <div className="story-image">
          <img src={item?.media_type === 'video' ? convertUrlToJpg(item?.content) : item?.content} alt={item?.title} className="img-fluid" />
        </div>
        <p className="username">@{item?.user_details?.username}</p>
      </div>
    );
  });
  return (
    <div className="card-body">
      {renderStories}
    </div>
  );
};

export default Story;
