import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import parse from 'html-react-parser';

import Profile from '../components/Profile';
import { ImageResizer } from "../Common/ImageResizer";
import { MAGAZINE_COVER_IMAGE_RATIO } from "../../constants/imageRatio";
import { ErrorMessage } from '../../constants/ErrorMessage';
import ButtonComponent from '../components/General/ButtonComponent';
import { CloudinaryFolders } from '../../constants/Constants';
import SelectUserComponent from '../components/SelectUserComponent';
import TitleTextArea from '../components/TitleTextArea';
import { deleteMagazineFileMethod, getMagazineFileDetailsMethod, storeMagazineFileMethod, storeMagazineMethod, updateMagazineFileMethod } from '../../actions/MagazineAction';
import MagazineFileCoverImage from '../components/Magazine/MagazineFileCoverImage';
import { UploadFile } from '../../services/upload.service';
import UnAuthorisedAccess from '../components/UnAuthorisedAccess';
import BodyLoading from '../components/BodyLoading';
import { Deleted, ViewIcon } from '../components/Icon';
import { kFormatter } from '../../Utils/helpers';
import swal from 'sweetalert';
import { getIssuuDocument } from '../../services/IssuuService';
import ChangeDateComponent from '../components/ChangeDateComponent';

const EditMagazineFile = () => {

    const { id, file_id } = useParams();
    const navigate = useNavigate();

    const featuredImageResizeRef = useRef();
    const pdfProgrssBar = useRef();
    const issuu_username = useRef();

    /** Redux */
    const dispatch = useDispatch();

    /** State declaration */
    const [validationErrors, setValidationErrors] = useState({});
    const [hasAccess, setHasAccess] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [preview, setPreview] = useState("");
    const [uploadingPdf, setUploadingPdf] = useState(true);
    const [file, setFile] = useState({
        title : "",
        cover_image: "",
        short_description: "",
        category: "",
        user_details: null,
        pdf: null,
        magazine_id: id,
        issuu_username: "",
        issuu_document_id: ""
    });

    const [refContainer, setRefContainer] = useState({
        featureImageRef: useRef(),
        container: {},
        current: null
    })

    const getMagazineDetails = () => {
        dispatch(getMagazineFileDetailsMethod(file_id))
        .then(res => {
            setIsLoading(false);
            setFile(res)
            
            setTimeout(() => {
                issuu_username.current.focus();
                issuu_username.current.blur();
            }, 500)
        }).catch(err => {
            if (err?.response?.status === 403) {
                setHasAccess(false)
            } else {
                setHasAccess(true)
            }
        });
    }

    const deleteHandler = (e) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to remove this magazine?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                dispatch(deleteMagazineFileMethod(file_id))
                    .then(res => {
                        swal("Deleted !", "File has been deleted!", "success");
                        navigate(`/dashboard/magazines/${id}/files`);
                    }).catch(e => {
                        swal("Something went wrong", "", "error");
                        console.log(e);
                    });
            }
        });
    }

    const getIssuuData = () => {
        if(file?.issuu_document_id?.length && file?.issuu_username?.length){
            getIssuuDocument(file?.issuu_username, file?.issuu_document_id).then(res => {
                setPreview(res.data.html)
                if(!file.title.length || !file.short_description.length){
                    setFile(prev => {
                        return {...prev, title: res.data.title, short_description: res.data.description}
                    })
                }
            }).catch(err => {
                setPreview("")
                console.log(err)
            })
        }
    }

    useEffect(() => {
        setIsLoading(true);
        getMagazineDetails();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFile((prev) => {
            return {...prev, [name]: value }
        });
    };

    const HandleSubmit = (e) => {
        setValidationErrors({})
        e.preventDefault();
        setSubmitting(true)

        let toastMessage = null;

        if(toastMessage){
            toast.error(ErrorMessage.Title);
            setSubmitting(false);
            return false;
        }

        let tmpMagazine = {...file}
        tmpMagazine.user_id = tmpMagazine?.user_details?.id

        dispatch(updateMagazineFileMethod(file_id, tmpMagazine))
            .then(response => {
                toast.success(response.message);
                setSubmitting(false);
                navigate(`/dashboard/magazines/${id}/files`);
            })
            .catch(error => {
                if(error?.response?.status === 422){
                    setValidationErrors(error.response.data.errors)
                }
                console.log(error);
                setSubmitting(false);
            });
    };

    const resizeImage = (e) => {
        setRefContainer(prevState => {
            return {
                ...prevState,
                current: { ref: e.target }
            }
        })
        featuredImageResizeRef.current.resizeImage(e)
    }

    const handlePDFChange = (e) => {
        const files = e.target.files;
        if(files.length > 0){
            setUploadingPdf(true)
            const uploadableFile = files[0];
            pdfProgrssBar.current.style.opacity = `1`;
            UploadFile(uploadableFile, ({ loaded, total })  => {
                pdfProgrssBar.current.style.width = `${(loaded/total) * 100}%`;
            }, CloudinaryFolders.MagazinePDF).then(resp => {
                setFile(prev => {
                    return {...prev, pdf: resp.data}
                })
            }).catch(err => {
                console.log(err);                
            }).finally(() => {
                setUploadingPdf(false)
                pdfProgrssBar.current.style.opacity = `0`;
                pdfProgrssBar.current.style.width = `0%`;
            })
        }
    }

    if (!hasAccess) {
        return <UnAuthorisedAccess heading={"Magazines"} />
    }

    if (!file?.id) {
        return <BodyLoading heading={"Magazines"} />
    }

    return (
        <>
            <div className="admin-dashboard users-panel magazine-form">
                <header>
                    <Profile heading="Magazine" pathurl="" showbtn="no" />
                    <div className="article-action-panel">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-7 col-md-7 col-12 pe-4">
                                <div className="action-box pe-0">
                                    <div className="back-to-user">
                                        <Link to={`/dashboard/magazines/${id}/files`}>
                                            <img src="/images/Refund_back.svg" className="img-fluid" alt='' />
                                        </Link>
                                    </div>
                                    <div className="social-info">
                                        <ul className="d-flex align-items-center">
                                            <li title={`${file?.total_views_count}/${file?.unique_views_count}`}>
                                                <ViewIcon/>
                                                <span>{kFormatter(file?.total_views_count ?? 0)}/{kFormatter(file?.unique_views_count ?? 0)}</span>
                                            </li>
                                            <li>
                                                <img src="/images/highhoof.svg" className="img-fluid" alt='' />
                                                <span>{file?.likes_count}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-12 ps-4">
                                <div className="action-box align-items-center">
                                    <Deleted deleteHandler={deleteHandler} />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="admin-dashboard-panel">
                    <div className="edit-article-section">
                        <form className="article-edit" action="#" method="POST">
                            <div className="row">
                                <div className="col-md-7 col-lg-7 col-12 pe-4">
                                    <div className="chat-board">
                                        <div className="inner-chat">
                                            <div className="row mb-20">
                                                <div className="col-6">
                                                    <input type="text"
                                                        ref={issuu_username}
                                                        name='issuu_username'
                                                        className="form-control bg-transparent"
                                                        placeholder="Issuu username"
                                                        value={file?.issuu_username}
                                                        onChange={handleInputChange}
                                                        onBlur={getIssuuData}
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <input type="text"
                                                        name='issuu_document_id'
                                                        className="form-control bg-transparent"
                                                        placeholder="Issuu documentID" 
                                                        value={file?.issuu_document_id}
                                                        onChange={handleInputChange}
                                                        onBlur={getIssuuData}
                                                    />
                                                </div>
                                            </div>

                                            {preview?.length > 0 && <div className="row mb-20">
                                                <div className="col-12">
                                                    {parse(preview)}
                                                </div>
                                            </div>}

                                            <TitleTextArea 
                                                value={file?.title}
                                                maxLength={30}
                                                placeholder='Enter title'
                                                onChange={handleInputChange}
                                                error={validationErrors?.title ? validationErrors?.title[0] : ''}
                                            />
                                            
                                            <textarea onChange={handleInputChange}
                                                className="form-control mb-20 px-3"
                                                value={file.short_description ?? ""}
                                                name="short_description"
                                                placeholder='Enter short description'
                                            ></textarea>
                                            {validationErrors?.short_description && <p className='validation-error-text'>{validationErrors?.short_description[0]}</p>}

                                            <div className="progress" style={{"height": "4px"}}>
                                                <div ref={pdfProgrssBar}
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{"width": "0%"}}
                                                    aria-valuenow="0"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100">
                                                </div>
                                            </div>
                                            {/**<label htmlFor='add-magazine-pdf' className={`add-magazine-file-wrapper ${file?.pdf ? 'bg-white' : ''} `}>
                                               
                                                {!file.pdf ?
                                                    <div className="left-wrapper">
                                                        <i className="fa fa-plus"></i>
                                                        <p className='m-0'>Add File</p>
                                                    </div> 
                                                :
                                                    <div className="left-wrapper">
                                                        <p className='m-0'>
                                                            Uploaded: &nbsp;<a target='_blank' rel="noreferrer" href={file?.pdf?.secure_url} className="magazine-file-name">
                                                                {file?.pdf?.original_filename}.{file?.pdf?.format}
                                                            </a>
                                                        </p>
                                                    </div>
                                                }
                                                <div className="right-wrapper">
                                                    <i className="fa fa-pencil"></i>
                                                </div>
                                                <input type='file' onChange={handlePDFChange} accept='application/pdf' className="d-none input-file" id="add-magazine-pdf"  />
                                                {validationErrors?.pdf && <p className='validation-error-text text-end'>{validationErrors?.pdf[0]}</p>} 
                                            </label>*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-5 col-lg-5 col-12 ps-4">
                                    <div className="right-section user-other-info">
                                        <MagazineFileCoverImage
                                            image_url={file?.cover_image}
                                            onChange={resizeImage}
                                            error={validationErrors?.cover_image ? validationErrors?.cover_image[0] : ''}
                                        />

                                        {/**<SelectUserComponent
                                            user={file.user_details}
                                            onSelect={(user) => setFile(prev => {
                                                return {...prev, user_details: user}
                                            })}
                                            error={validationErrors?.user_id ? validationErrors?.user_id[0] : ''}
                                            canChange
                                            label='Link User'
                                        />*/}

                                        <input type="text" className='form-control bg-transparent'
                                            value={file.category}
                                            name="category"
                                            onChange={handleInputChange}
                                            placeholder='Enter category'
                                        />

                                        <ChangeDateComponent date={file.created_at} onChange={(date) => setFile((prev) => {
                                            return {...prev, created_at: date }
                                        })} />
                                        
                                        <div className="col-12 text-end mt-4">
                                            <ButtonComponent
                                                label='Save Changes'
                                                onClick={HandleSubmit}
                                                isLoading={isSubmitting}
                                                className='w-100'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ImageResizer
                ref={featuredImageResizeRef}
                uploadedImageCallback={(data) => {
                    setFile(prevState => ({
                        ...prevState,
                        cover_image: data.secure_url
                    }));
                }}
                resetState={() => {
                    refContainer.current.value = null
                }}
                IMAGE_RATION={MAGAZINE_COVER_IMAGE_RATIO}
                folder={CloudinaryFolders.MagazineFile}
            />
        </>
    )
}

export default EditMagazineFile
