import React, { useRef } from 'react';
import { nthNumber } from '../../Utils/helpers';

const ScoreItem = ({ scores, active, id }) => {
    const contentEl = useRef();

    return (
        <div ref={contentEl} className={`rc-collapse row justify-content-end ${active === id ? 'show' : ''}`} style={active === id
            ? { height: contentEl?.current?.scrollHeight }
            : { height: "0px" }
        }>
            <div className="rc-accordion-body col-md-10 offset-md-1">
                <table className="table table-sm">
                    <tbody>
                        {scores && scores?.map((score, index) => {
                            return <tr key={index} >
                                <th className={(scores.length === (index + 1)) ? 'border-bottom-0' : ''}>{index + 1}<sup>{nthNumber(index + 1)}</sup></th>
                                <td className={(scores.length === (index + 1)) ? 'border-bottom-0' : ''}>{score?.number}</td>
                                <td className={(scores.length === (index + 1)) ? 'border-bottom-0' : ''}>{score?.contestant}
                                    {score?.comment?.length && <div><i>{score?.comment}</i></div>}
                                </td>
                                <th className={(scores.length === (index + 1)) ? 'border-bottom-0' : ''}>{score?.score}</th>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ScoreItem;