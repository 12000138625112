
import {
    GET_All_STORIES, 
    GET_EDIT_STORIES, 
    POST_NEW_STORIES,
    UPDATE_STORIES,
    PINNED_STORIES
} from '../constants/actiontypes';

const initialState = {
    stories: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_All_STORIES:
            return {
                ...state,
                stories: payload.stories,
            };
        case GET_EDIT_STORIES:
            return {
                ...state,
                stories: payload.stories,
            };
        case POST_NEW_STORIES:
            return {
                ...state,
            };
        case UPDATE_STORIES:
            return {
                ...state,
            };
        case PINNED_STORIES:
            const pinnedId = payload?.data?.id;
            const pinnedCheck = payload?.data?.pinned;
            let index =  state?.stories?.data.findIndex(item => item?.id === pinnedId);
            let storiesDetail =  state.stories.data;
            if (index !== -1){
                storiesDetail[index].pinned = pinnedCheck;
            }
            state.stories.data = storiesDetail
            return {
                ...state
            };
        default:
            return state;
    }
}

