import moment from 'moment';

export function getDateFormat(dateinFormat) {
 return moment(dateinFormat).format('MMMM Do YYYY');
}

export function getStoryDateFormat(dateinFormat) {
  let momentObj = moment(dateinFormat, 'YYYY-MM-DD');
  const dateInNthNumber = nthNumber(momentObj.format('D'));
  const month = moment(momentObj).format('MMMM');
  const year = moment(momentObj).format('YYYY');
  var fulldateFormat = month + ' ' + dateInNthNumber + ' ' + year;
  return fulldateFormat;
}


export function getFullDateTimeFormat(dateinFormat) {
  let momentObj = moment(dateinFormat);
  const dateInNthNumber = nthNumber(momentObj.format('D'));
  const month = moment(momentObj).format('MMMM');
  const year = moment(momentObj).format('YYYY');
  const time = moment(momentObj).format('hh:mm A');
  var fulldateFormat = month + ' ' + dateInNthNumber + ' ' + year + ' ';

  fulldateFormat = fulldateFormat + time;
  return fulldateFormat;
}

const nthNumber = (number) => {
  if (number > 3 && number < 21) return number + "th";
  switch (number % 10) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
};

export const validateYouTubeUrl = (urlToParse) => {
  if (urlToParse) {
    var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (urlToParse.match(regExp)) {
        return true;
    }
  }
  return false;
}

export const validateVimeoUrl = (urlToParse) => {
  if (urlToParse) {
    var regExp = /^(https|http):\/\/?(www\.)?(vimeo\.com\/)+/;

    if (urlToParse.match(regExp)) {
        return true;
    }
  }
  return false;
}