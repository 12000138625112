import React from 'react'
import { get500WidthImage } from '../../Common/AHImage'

const MagazineFileCoverImage = ({image_url, onChange, error=""}) => {
  return (
        <div className={`cover-image-wrapper ${error?.length > 0 ? 'validation-error': ''}`}>

            <div className={`cover-image-box`}>
                {image_url ?
                    <img
                        src={get500WidthImage(image_url)}
                        className="img-fluid"
                        alt='featured'
                    />
                    :
                    <span> Cover <br />Image </span>
                }
                
                {error?.length > 0 && <p className='validation-error-text'>{error}</p>}
            </div>
                
            <input type="file"
                accept="image/*"
                name='image'
                id="magazine-cover-image-file"
                onChange={onChange}
                className="action-control d-none"
                title={image_url ? 'change picture' : 'upload picture'}
            />

            <label className='featured-edit-icon cu-pt h-100' htmlFor="magazine-cover-image-file">
                <img src="/images/icon-edit.svg" className="img-fluid" alt='' />
            </label>

            
        </div>
    )
}

export default MagazineFileCoverImage