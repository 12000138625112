import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteEventTimelineMethod,
  getEventTimelineMethod,
  showEventsMethod,
  toggleFeaturedTimeline,
} from "../../actions/EventAction";
import Profile from "../components/Profile";
import { Modules } from "../../constants/Constants";
import swal from "sweetalert";
import Story from "../components/Event/Story";
import Post from "../components/Event/Post";
import Article from "../components/Event/Article";
import TimeTable from "../components/Event/TimeTable";
import TimetableScore from "../components/Event/TimetableScore";
import Advertisement from "../components/Event/Advertisement";
import Pagination from "react-js-pagination";
import LiveFeedCardHeader from "../components/Event/LiveFeedCardHeader";
import ForumCard from "../components/Forum/ForumCard";

const LiveFeed = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const eventData = useSelector((state) => state.EventReducer.event);

  const [pagination, setPagination] = useState({ data: [] });  
  const [page, setPage] = useState(1);

  const getTimeline = () => {
    let query = "?page=" + page;
    dispatch(getEventTimelineMethod(id, query))
      .then((res) => {
        setPagination(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id && !eventData) {
      dispatch(showEventsMethod(id)).catch((err) => {
        console.log(err);
      });
    }

    getTimeline();
  }, [id]);

  useEffect(() => {
    getTimeline();
  }, [page]);

  const HandleDelete = (timeline_id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this timeline item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(deleteEventTimelineMethod(eventData?.id, timeline_id))
            .then((res) => {
              if (res.status) {
                swal("Deleted!", res.message, "success");
              }
              getTimeline();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HandleOnPressPin = (timeline_id) => {
    swal({
      title: "Are you sure?",
      text: "Pinned/Unpinned this timeline post?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        dispatch(toggleFeaturedTimeline(eventData?.id, timeline_id))
          .then(res => {
            getTimeline();
          }).catch(err => {
            console.log(err);
          });
      }
    });
  }

  const renderItems = pagination?.data?.map((timelineItem) => {
    return (
      <div className="row" key={`timeline_${timelineItem?.id}`}>
        <div className="col-12 mb-2">

          {timelineItem?.module_name === Modules.Story && (
            <div className="card story-card">
              <LiveFeedCardHeader
                timeline={timelineItem}
                headingText={'Stories'}
                onDelete={() => HandleDelete(timelineItem?.id)}
                HandleOnPressPin={HandleOnPressPin}
              />
              <Story items={timelineItem?.payload} />
            </div>
          )}

          {timelineItem?.module_name === Modules.Post && (
            <div className="card">
              <LiveFeedCardHeader
                timeline={timelineItem}
                headingText={'Post'}
                onDelete={() => HandleDelete(timelineItem?.id)}
                post_id={timelineItem?.payload?.id}
                HandleOnPressPin={HandleOnPressPin}
              />
              <Post post={timelineItem?.payload} />
            </div>
          )}

          {timelineItem?.module_name === Modules.Article && (
            <div className="card">
              <LiveFeedCardHeader
                timeline={timelineItem}
                headingText={'Article'}
                onDelete={() => HandleDelete(timelineItem?.id)}
                HandleOnPressPin={HandleOnPressPin}
              />
              <Article article={timelineItem?.payload} />
            </div>
          )}

          {timelineItem?.module_name === Modules.Forum && (
            <div className="card">
              <LiveFeedCardHeader
                timeline={timelineItem}
                headingText={'Forum Entry'}
                onDelete={() => HandleDelete(timelineItem?.id)}
                HandleOnPressPin={HandleOnPressPin}
              />
              <div className="card-body">
                <ForumCard item={timelineItem?.payload} hidePin={true} hideDelete={true} />
              </div>
            </div>
          )}

          {timelineItem?.module_name === Modules.TimeTable && (
            <div className="card">
              <LiveFeedCardHeader
                timeline={timelineItem}
                headingText={'TimeTable'}
                onDelete={() => HandleDelete(timelineItem?.id)}
                HandleOnPressPin={HandleOnPressPin}
              />
              <TimeTable item={timelineItem?.payload} />
            </div>
          )}

          {timelineItem?.module_name === Modules.TimetableScore && (
            <div className="card">
              <LiveFeedCardHeader
                timeline={timelineItem}
                headingText={'TimeTable result'}
                onDelete={() => HandleDelete(timelineItem?.id)}
                HandleOnPressPin={HandleOnPressPin}
              />
              <TimetableScore item={timelineItem?.payload} />
            </div>
          )}

          {timelineItem?.module_name === Modules.Advertisement && (
            <div className="card">
              <LiveFeedCardHeader
                timeline={timelineItem}
                headingText={'Sponsor'}
                onDelete={() => HandleDelete(timelineItem?.id)}
                HandleOnPressPin={HandleOnPressPin}
              />
              <Advertisement item={timelineItem?.payload} />
            </div>
          )}
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="admin-dashboard users-panel timetable-panel">
        <header>
          <Profile
            heading={
              eventData ? `Live Feed : ${eventData?.title}` : "Live Feed"
            }
            pathurl="new-event"
            showbtn="no"
          />
        </header>
        <div className="admin-dashboard-panel timetable-panel-list">
          <div className="app-listing-panel event-live-feed">{renderItems}</div>
          {pagination?.last_page !== 1
            ? pagination?.data && (
              <Pagination
                activePage={pagination?.current_page}
                totalItemsCount={pagination?.total ?? 0}
                itemsCountPerPage={pagination?.per_page}
                onChange={(pageNumber) => setPage(pageNumber)}
                itemClass="page-item"
                linkClass="page-link"
                itemClassFirst="first"
                itemClassLast="last"
                itemClassPrev="prev"
                itemClassNext="next"
                firstPageText="Frist"
                lastPageText="Last"
                prevPageText="Previous"
                nextPageText="Next"
                hideFirstLastPages={true}
              />
            )
            : null}
        </div>
      </div>
    </>
  );
};

export default LiveFeed;
