import React from 'react'

const ListCardWrapper = ({children}) => {
  return (
    <div className='list-item'>
        {children}
    </div>
  )
}

export default ListCardWrapper