import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import moment from 'moment';
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';

import { getUsers } from '../../actions/UsersAction';
import { getCategories } from '../../actions/CategoryAction';
import Profile from '../components/Profile';
import ArenaField from '../components/ArenaField';
import { ArrowDown } from '../components/Icon';
import { ColorPicker } from '../components/Icon';
import { setEventsMethod } from '../../actions/EventAction';
import { NewArenaObject } from '../../constants/Constants';
import ToggleCheckbox from '../components/ToggleCheckbox';

function NewEvent() {
    const navigate = useNavigate();
    const categories = useSelector((state) => state.CategoryReducer.categories);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedOption, setSelectedOption] = useState([]);
    const [userLoading, setUserLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [inputTimeout, setInputTimeout] = useState(null);
    const [eventErrors, setEventErrors] = useState(null);
    const startDate = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const [event, setEvent] = useState({
        title: "",
        live: false,
        live_for_test: false,
        mute_push_notification: false,
        start_date: startDate,
        end_date: endDate,
        category_id: "",
        primary_color: "#00A0FF",
        secondary_color: "#6EE1FF",
        influencers: [],
        arenas: [{ ...NewArenaObject }],
    });

    // Get users List Data from api
    const getAllUsers = (search) => {
        setUserLoading(true);
        const searchParams = new URLSearchParams();
        if (search) {
            searchParams.set('search', search);
        }
        let query = '?page=' + 1 + "&" + searchParams.toString();
        dispatch(getUsers(query))
            .then(res => {
                if (res.status) {
                    setUserLoading(false);
                    const data = res.data.data;
                    const options = data?.map(user => ({
                        "value": user.id,
                        "label": user.first_name + " " + user.last_name
                    }));
                    setSelectedOption(options);
                }
            }).catch(err => {
                setUserLoading(false);
                console.error(err);
            });
    }

    // lifeCycle Methods
    useEffect(() => {
        if (!searchQuery) {
            getAllUsers();
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        dispatch(getCategories()).then(() => {
            setLoading(false);
        })
    }, []);

    // Change input value here for title
    const onchangeHandle = (e) => {
        const { name, value } = e.target
        setEvent(event => ({ ...event, [name]: value }))
        setEventErrors(pre => ({ ...pre, title: "", category_id: "", primary_color: "" }));
    }

    const handleLiveStatus = (e) => {
        const { checked } = e.target;
        setEvent(event => ({
            ...event,
            live: checked,
        }));
    }

    const handleLiveForTestStatus = (e) => {
        const { checked } = e.target;
        setEvent(event => ({
            ...event,
            live_for_test: checked,
        }));
    }

    const handlePushNotificationStatus = (e) => {
        const { checked } = e.target;
        setEvent(event => ({
            ...event,
            mute_push_notification: checked,
        }));
    }

    const handleChangeStartDate = (date) => {
        console.log(date);
        setEvent((event) => ({
            ...event,
            start_date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
        }))
        setEventErrors(eventErrors => ({
            ...eventErrors,
            start_date: null
        }))
    }
    const handleChangeEndDate = (date) => {
        setEvent((event) => ({
            ...event,
            end_date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
        }))
        setEventErrors(eventErrors => ({
            ...eventErrors,
            end_date: null
        }))
    }

    const handleArenaNameChanged = (index, e) => {
        const { name, value } = e.target;
        const tmpArenas = [...event.arenas];
        tmpArenas[index][name] = value;
        setEvent((event) => ({
            ...event,
            arenas: tmpArenas
        }));
    }

    const handleUserSelect = (user) => {
        setEvent((event) => ({
            ...event,
            influencers: Array.from(user, item => item.value)
        }));
        setEventErrors(eventErrors => ({
            ...eventErrors,
            influencers: []
        }))
    }

    const handleInputChange = value => {
        let lowercasedValue = value.toLowerCase();
        setSearchQuery(lowercasedValue);
        if (inputTimeout) clearTimeout(inputTimeout)
        setInputTimeout(
            setTimeout(() => {
                if (lowercasedValue) {
                    getAllUsers(lowercasedValue);
                }
            }, 1000)
        );
    };

    const handleCreateEvent = (e) => {
        e.preventDefault();
        setIsLoading(true);
        dispatch(setEventsMethod(event))
            .then((res) => {
                setIsLoading(false);
                toast.success(res.message);
                navigate('/dashboard/events');
            }).catch((err) => {
                setIsLoading(false);
                setEventErrors(err.errors)
                toast.error(err.message);
            });
    }

    const addMoreArenas = (e) => {
        e.preventDefault();
        const tmpArenas = [...event.arenas];
        tmpArenas.push({ ...NewArenaObject })
        setEvent((event) => ({
            ...event,
            arenas: tmpArenas
        }));
    }

    const HandleRemoveArena = (index) => {
        const tmpArenas = [...event.arenas];
        const remainingArenas = tmpArenas.filter((s, i) => (i !== index))
        setEvent((event) => ({
            ...event,
            arenas: remainingArenas
        }));
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from([...event.arenas]);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setEvent((event) => ({
            ...event,
            arenas: items
        }));
    }

    const renderArenas = event.arenas?.map((item, index) => {
        return (
            <Draggable disableInteractiveElementBlocking={false} key={`arena_${index}`} draggableId={`arena_${index}`} index={index}>
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                <ArenaField                   
                    onChange={handleArenaNameChanged}
                    item={item}
                    index={index}
                    arenas={event.arenas}
                    removeArena={HandleRemoveArena}
                />
                </div>
            )}
            </Draggable>
        )
    });

    return (
        <>
            <div className="admin-dashboard users-panel">
                <header>
                    <Profile heading="New Event" pathurl="new-event" showbtn="no" />
                    <div className="user-action-panel">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="action-box pe-3">
                                    <div className="back-to-user">
                                        <Link to="/dashboard/events">
                                            <img src="/images/Refund_back.svg" className="img-fluid" alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="admin-dashboard-panel">
                    <div className="user-personal-panel">
                        <form className='form'>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="personal-form event-personal-form">
                                        <div className="row">
                                            <div className="col-lg-11 col-md-11 col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        className="action-control"
                                                        value={event?.title}
                                                        placeholder="Event Name"
                                                        onChange={(e) => onchangeHandle(e)}
                                                    />
                                                    {eventErrors?.title ? (<p className='text-danger'>{eventErrors?.title[0]}</p>) : null}
                                                    <DatePicker
                                                        placeholderText="Start Date"
                                                        dateFormat="MMMM d, yyyy hh:mm aa"
                                                        selected={new Date(event?.start_date)}
                                                        onChange={(date) => handleChangeStartDate(date)}
                                                        selectsStart
                                                        showTimeSelect
                                                        startDate={new Date(event?.start_date)}
                                                        endDate={new Date(event?.end_date)}
                                                        minDate={new Date()}
                                                    />
                                                    {eventErrors?.start_date ? (<p className='text-danger'>{eventErrors?.start_date[0]}</p>) : null}
                                                    <DatePicker
                                                        placeholderText="End Date"
                                                        dateFormat="MMMM d, yyyy hh:mm aa"
                                                        selected={new Date(event?.end_date)}
                                                        onChange={(date) => handleChangeEndDate(date)}
                                                        selectsEnd
                                                        showTimeSelect
                                                        startDate={new Date(event?.start_date)}
                                                        endDate={new Date(event?.end_date)}
                                                        minDate={new Date(event?.start_date)}
                                                    />
                                                    {eventErrors?.end_date ? (<p className='text-danger'>{eventErrors?.end_date[0]}</p>) : null}
                                                    <div className="user-types">
                                                        <select name="category_id" className="action-control"
                                                            onChange={(e) => onchangeHandle(e)} >
                                                            <option value="">Choose a Category</option>
                                                            {categories?.map((category, index) => {
                                                                return (
                                                                    <option key={category?.id} value={category?.id}>{category?.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <ArrowDown />
                                                    </div>
                                                    {eventErrors?.category_id ? (<p className='text-danger'>{eventErrors?.category_id[0]}</p>) : null}
                                                    <Select
                                                        placeholder="Coupled profiles"
                                                        onInputChange={handleInputChange}
                                                        onChange={handleUserSelect}
                                                        isClearable
                                                        isMulti
                                                        isSearchable
                                                        options={selectedOption}
                                                        isLoading={isLoading}
                                                    />
                                                    {eventErrors?.influencers ? (<p className='text-danger'>{eventErrors?.influencers[0]}</p>) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="form-group">
                                                    <p>Choose event primary colour</p>
                                                    <div className="event-color-picker user-media user-edit-profile d-flex justify-content-center align-items-center" style={{ backgroundColor: event?.primary_color }}>
                                                        <ColorPicker />
                                                        <input type="color" name='primary_color' onChange={(e) => onchangeHandle(e)} className="action-control h-75" title="Color Picker" />
                                                        <span><img src="/images/icon-edit.svg" className="img-fluid" alt="" /></span>
                                                    </div>
                                                </div>
                                                {eventErrors?.primary_color ? (<p className='text-danger'>{eventErrors?.primary_color[0]}</p>) : null}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="form-group">
                                                    <p>Choose event secondary colour</p>
                                                    <div className="event-color-picker user-media user-edit-profile d-flex justify-content-center align-items-center" style={{ backgroundColor: event?.secondary_color }}>
                                                        <ColorPicker />
                                                        <input type="color" name='secondary_color' onChange={(e) => onchangeHandle(e)} className="action-control h-75" title="Color Picker" />
                                                        <span><img src="/images/icon-edit.svg" className="img-fluid" alt="" /></span>
                                                    </div>
                                                </div>
                                                {eventErrors?.secondary_color ? (<p className='text-danger'>{eventErrors?.secondary_color[0]}</p>) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="user-listing-panel event-live-section">
                                        <div className="user-other-info">
                                            <div className="form-group">
                                                <ul>
                                                    <li>
                                                        <ToggleCheckbox
                                                            isActive={event?.live}
                                                            onChange={handleLiveStatus}
                                                            label={`Live`}
                                                        />
                                                    </li>
                                                    <li>
                                                        <ToggleCheckbox
                                                            isActive={event?.live_for_test}
                                                            onChange={handleLiveForTestStatus}
                                                            label={`Live (Admin + Influencers)`}
                                                        />
                                                    </li>
                                                    <li>
                                                        <ToggleCheckbox
                                                            isActive={event?.mute_push_notification}
                                                            onChange={handlePushNotificationStatus}
                                                            label={`Mute Push Notifications`}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="personal-form">
                                                <div className="form-group">
                                                    <label className="pb-1 arenai-label" htmlFor="arenas">Arena's </label>
                                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                                        <Droppable droppableId="characters">
                                                        {(provided) => (
                                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                            {renderArenas}

                                                            {provided.placeholder}
                                                        </div>
                                                        )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div>
                                            </div>
                                            <div className="btn-arenas-add" onClick={(e) => addMoreArenas(e)}>
                                                <img src="/images/PlusSignIcon.svg" className="img-fluid w-75" alt='' />
                                            </div>
                                        </div>
                                        <div className="col-12 text-end">
                                            <button type='submit' className="main-btn" name="user_save_data" disabled={isLoading} onClick={(e) => handleCreateEvent(e)}>
                                                Create event  {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="fa-sharp fa-solid fa-arrow-right"></i>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewEvent
