import { GET_REQUEST, SET_REQUEST } from '../constants/actiontypes';

const initialState = {
    request_data: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_REQUEST:
            return {
                ...state,
                request_data: payload,
            };
        case SET_REQUEST:
            return {
                ...state,
                request_data: payload,
        };
        default:
            return state;
    }
}