import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ShimmerCategoryList } from "react-shimmer-effects";
import { useDispatch } from 'react-redux';

import Profile from '../components/Profile'
import { getDateFormat } from '../../helpers/CommonFunction';
import { getEventsMethod } from '../../actions/EventAction';
import { useSelector } from 'react-redux';
import { Role } from '../../constants/roles';

const Events = () => {

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.UsersReducer.current_user.data);

    /** States declaration */
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({ data: [] });

    const getNotifications = (page = 1) => {
        let query = "page=" + page;
        setIsLoading(true);
        dispatch(getEventsMethod(query)).then((res) => {
            setPagination(res.data);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getNotifications();
    }, [])

    const renderItems = pagination.data.map((e, index) => {
        return (
            <li className="nav-panel-item" key={index}>
                <div className="message-media">
                    <div className="color-box" style={{ backgroundColor: e.primary_color }}>
                        {e?.live ? <img src="/images/onAir.svg" alt="" /> : null}
                    </div>

                    <div className="message-info">
                        <h5>{e.title}</h5>
                        <p>{getDateFormat(e.start_date)} -  {getDateFormat(e.end_date)}</p>
                    </div>
                </div>
                <div className="messages-action justify-content-end gap-3">
                    <Link to={`/dashboard/events/${e.id}/edit`} className="main-btn-capsule">Edit</Link>
                </div>
            </li>
        )
    })

    return (
        <>
            <div className="admin-dashboard users-panel events-panel">
                <header>
                    <Profile heading="Events" pathurl="new-event" showbtn={currentUser?.user_role === Role.Admin ? 'yes' : 'no'} />
                </header>
                <div className="admin-dashboard-panel">
                    <div className="user-filter-panel">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-12">
                                <div className="filter-form">
                                    <form>

                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                            </div>
                        </div>
                    </div>
                    <div className="messages-listing-panel">
                        {!isLoading ? pagination?.data?.length > 0 ? (<ul className="nav-panel">{renderItems} </ul>) : (<span>No data available.</span>) : (<ShimmerCategoryList items={5} categoryStyle="STYLE_FIVE" />)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Events