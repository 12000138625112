import api from "./api";

const sendPushNotification = async (payload) => {
    const url = "push-notifications";
    return await api.post(url, payload)
        .then((response) => {
            return response.data;
        });
};

const getPushNotifications = async (query = "") => {
    let url = "push-notifications";
    if (query.length) {
        url = url + "?" + query;
    }
    return await api.get(url)
        .then((response) => {
            return response.data;
        });
};

const getSinglePushNotification = async (id) => {
    let url = "push-notifications/" + id;

    return await api.get(url)
        .then((response) => {
            return response.data;
        });
};

export default {
    sendPushNotification,
    getPushNotifications,
    getSinglePushNotification
}