import React from 'react'

const SpinnerLoader = () => {
    return (
        <div className="loader_box">
            <img style={{ height: '76px' }} src="/images/BrandBlue-Logo.svg" className='img-fluid' alt='' />
        </div>
    )
}

export default SpinnerLoader
