import { GET_EVENT } from "../constants/actiontypes";
import { ErrorHandler } from "../services/ErrorHandler";
import EventService from "../services/Event.service";

export const getEventsMethod = (query) => {
  return (dispatch) => {
    return ErrorHandler(dispatch)(EventService.getEvents(query))
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const setEventsMethod = (event) => {
  return (dispatch) => {
    return ErrorHandler(dispatch)(EventService.setEvents(event))
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        const message =
          (error.response.data.errors && error.response.data) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      });
  };
};

export const showEventsMethod = (event_id) => {
  return (dispatch) => {
    return ErrorHandler(dispatch)(EventService.showEvents(event_id))
      .then((data) => {
        dispatch({ type: GET_EVENT, payload: data?.data });
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const getEventTimelineMethod = (event_id, query) => {
  return (dispatch) => {
    return ErrorHandler(dispatch)(
      EventService.getEventTimeline(event_id, query)
    )
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const deleteEventTimelineMethod = (event_id, timeline_id) => {
  return (dispatch) => {
    return ErrorHandler(dispatch)(
      EventService.deleteEventTimeline(event_id, timeline_id)
    )
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        const message =
          (error.response.data.errors && error.response.data) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      });
  };
};

export const toggleFeaturedTimeline = (event_id, timeline_id) => {
  return (dispatch) => {
    return ErrorHandler(dispatch)(
      EventService.toggleFeaturedTimeline(event_id, timeline_id)
    )
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        const message =
          (error.response.data.errors && error.response.data) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      });
  };
};

export const updateTimelineDateMethod = (event_id, timeline_id, payload) => {
  return (dispatch) => {
    return ErrorHandler(dispatch)(
      EventService.updateEventTimelineDate(event_id, timeline_id, payload)
    )
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {        
        return Promise.reject(error);
      });
  };
};

export const updateEventsMethod = (event_id, event) => {
  return (dispatch) => {
    return ErrorHandler(dispatch)(EventService.updateEvents(event_id, event))
      .then((data) => {
        dispatch({ type: GET_EVENT, payload: data });
        return Promise.resolve(data);
      })
      .catch((error) => {
        const message =
          (error.response.data.errors && error.response.data) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      });
  };
};

export const deleteEventsMethod = (event_id) => {
  return (dispatch) => {
    return ErrorHandler(dispatch)(EventService.deleteEvents(event_id))
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        const message =
          (error.response.data.errors && error.response.data) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      });
  };
};
