import React from 'react'
import { get500WidthImage } from '../Common/AHImage'

const FeaturedImageComponent = ({image_url, onChange, error=""}) => {
  return (
        <div className={`featured-image-box ${error?.length > 0 ? 'validation-error': ''}`}>
            {image_url ?
                <img
                    src={get500WidthImage(image_url)}
                    className="img-fluid"
                    alt='featured'
                    onError={(e) => {
                        e.target.src = '/images/article-placeholder.jpg'
                        e.target.onerror = null
                    }}
                />
                :
                <img
                    src={`/images/article-placeholder.jpg`}
                    className="img-fluid"
                    alt='featured'
                />
            }
            <input type="file"
                accept="image/*"
                name='image'
                onChange={onChange}
                className="action-control featured-edit-input"
                title={image_url ? 'change picture' : 'upload picture'}
            />

            <span className='featured-edit-icon'>
                <img src="/images/icon-edit.svg" className="img-fluid" alt='' />
            </span>

            {error?.length > 0 && <p className='validation-error-text'>{error}</p>}
        </div>
    )
}

export default FeaturedImageComponent