import React from 'react'
import ListCardWrapper from '../ListCardWrapper'
import { Deleted } from '../Icon'
import { Link, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import { useDispatch } from 'react-redux'
import { deleteMagazineMethod, restoreMagazineMethod } from '../../../actions/MagazineAction'
import UserImage from "../User/UserImage";

const MagazineCard = ({item, getList, showRestore, hideEdit}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const HandleClickOnTitle = () => {
    if(!showRestore){
      navigate(`/dashboard/magazines/${item?.id}/files`)
    }
  }
 
  const deleteHandler = (e) => {
    e.preventDefault();
    swal({
        title: "Are you sure?",
        text: "Are you sure you want to remove this magazine?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then(willDelete => {
        if (willDelete) {
            dispatch(deleteMagazineMethod(item?.id))
                .then(res => {
                    getList()
                    swal("Deleted!", "Magazine has been deleted!", "success");
                }).catch(e => {
                    swal("Something went wrong", "", "error");
                    console.log(e);
                });
        }
    });
  }

  const restoreHandler = (e) => {
    e.preventDefault();
    swal({
        title: "Are you sure?",
        text: "Are you sure you want to restore this magazine?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then(willDelete => {
        if (willDelete) {
            dispatch(restoreMagazineMethod(item?.id))
                .then(res => {
                    getList()
                    swal("Restored!", "Magazine has been restored!", "success");
                }).catch(e => {
                    swal("Something went wrong", "", "error");
                    console.log(e);
                });
        }
    });
}

  return (
    <ListCardWrapper>
      <div className="left-wrapper">
          <UserImage
            image_url={item?.user_details?.profile_picture}
            role={item?.user_details?.user_role}
          />
        <p onClick={HandleClickOnTitle} className="list-title">{item?.title}</p>
      </div>

      <div className="right-wrapper">

        {!showRestore ?
          <Deleted deleteHandler={deleteHandler} title={`Delete Magazine`} />
        :
          <a href="!#" onClick={restoreHandler} className="main-btn-capsule">Restore</a>                               
        }

        {!hideEdit && <Link to={`/dashboard/magazines/${item?.id}/edit`} className="main-btn-capsule px-5">Edit</Link>}
      </div>
    </ListCardWrapper>
  )
}

export default MagazineCard