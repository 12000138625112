import {
    SET_CATEGORIES,
} from '../constants/actiontypes';
import CategoryService from "../services/category.service";
import { ErrorHandler } from '../services/ErrorHandler';

export const getCategories = (query = "", needToUpdate = true) => {
    return dispatch => {
        return ErrorHandler(dispatch)(CategoryService.getCategories(query))
            .then(data => {
                if(needToUpdate){
                    dispatch({
                        type: SET_CATEGORIES,
                        payload: data.data,
                    });
                }
                return Promise.resolve(data);
            }).catch(error => {
                return Promise.reject(error);
            })
    }
}

export const updateCategoriesMethod = (categories) => {
    return dispatch => {
        return ErrorHandler(dispatch)(CategoryService.setCategories(categories))
            .then((data) => {
                dispatch({
                    type: SET_CATEGORIES,
                    payload: data.data,
                });
                return Promise.resolve(data);
            }).catch((error) => {
                const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
                return Promise.reject(message);
            });
    }
}

export const deleteCategory = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(CategoryService.deleteCategory(id))
            .then((data) => {
                return Promise.resolve(data);
            }).catch((error) => {
                return Promise.reject(error);
            });
    }
}


