import axios from "axios";
import TokenService from "./token.service";
import {REFRESH_TOKEN_URL} from '../constants/ApiConstants'

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "x-dashboard":"xyz"
  },
});

instance.interceptors.request.use(
  (config) => {

    const token = TokenService.getLocalAccessToken();
    if (token?.idToken) {
      config.headers["Authorization"] = 'Bearer ' + token?.idToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
        const originalRequest = err.config;
        const refreshToken = TokenService.getLocalRefreshToken();
        // Access Token was expired
        if (err?.response?.status === 401 && refreshToken && originalRequest._retry !== true) {
            originalRequest._retry = true;
            try {
              const response = await refershToken(refreshToken);
              const token = response.data.data;
              TokenService.updateLocalAccessToken(token);
              return instance(originalRequest);
            } catch (_error) {
                if(_error?.response?.status === 401 && _error?.response?.data?.message === "Token expired"){
                  TokenService.removeUser();
                }
                return Promise.reject(_error);
            } 
         }
        return Promise.reject(err);
      }
);

const refershToken = (refreshToken) => {
  return axios.post(API_URL + REFRESH_TOKEN_URL,  {
      grant_type : "refresh_token",
      token: refreshToken?.refreshToken,
  });
}


export default instance;