import {AHModal} from "../Modal/Modal";
import {aspectCrop, centerAspectCrop, getCroppedImg} from "../../Utils/helpers";
import {ButtonSpinner} from "./ButtonSpinner";
import ReactCrop from "react-image-crop";
import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {UploadFile} from "../../services/upload.service";
import { ErrorMessage } from "../../constants/ErrorMessage";
import { CLOUDINARY_MAX_IMAGE_SIZE } from "../../constants/Constants";
import swal from "sweetalert";

const MODAL_INIT_STATE =  {
    isUploading: false,
    isShow: false,
    image: null
}

export const ImageResizer =  forwardRef(({ uploadedImageCallback, IMAGE_RATION, resetState, folder}, ref) => {
    useImperativeHandle(ref, () => {
        return {
            resizeImage: _resizeImage,}
    })
    const progressBarRef = useRef();
    const [modalState, setModalState] = useState(MODAL_INIT_STATE);
    const [mediaTypes, setMediaTypes] = useState("");
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [error, setError] = useState("");
    const imageRef = useRef()
    const MAX_FILE_SIZE = 104857600;

    const uploadImage = async (folder) => {

        if(mediaTypes === "image"){
            makeClientCrop(completedCrop, mediaTypes); 
        }
        setError("");
        setModalState((prev) => {
            return {
                ...prev,
                isUploading: true,
            }
        });

        const data = await fetch(modalState.image);
        const blob = await data.blob();

        if(mediaTypes === "video" && blob?.size > MAX_FILE_SIZE) {
            setModalState((prev) => {
                return {
                    ...prev,
                    isUploading: false,
                }
            });
            setError(ErrorMessage.VideoMaxFileSize);
            return false; 
        }

        let uploadableFileName = mediaTypes === "image" ? "Content.jpg" : "Content.mp4";
        if(mediaTypes === "gif"){
            uploadableFileName = "Content.gif"
        }
        
        UploadFile(new File([blob], uploadableFileName), ({ loaded, total })  => {
            progressBarRef.current.style.width = `${(loaded/total) * 100}%`;
        }, folder).then(resp => {
            return resp.data
        })
        .then(data => {
            progressBarRef.current.style.width = "0%";
            if (uploadedImageCallback){
                uploadedImageCallback(data)
            }
            setModalState((prev) => { return MODAL_INIT_STATE });
        }).catch(err => {
            setError(err.message);
            setModalState((prev) => {
                return {
                    ...prev,
                    isUploading: false,
                    image:null
                }
            });
        })
    }

    const _resizeImage = (e) => {
        const reader = new FileReader();
        const fileData = e.target.files[0];
        reader.onloadend = () => {
            
        }
        reader.readAsDataURL(fileData);
        if (fileData.type.includes("image")) {
            if(fileData.type === "image/gif"){
                setMediaTypes("gif");
                if(fileData.size > CLOUDINARY_MAX_IMAGE_SIZE){
                    swal({
                            title: "File size",
                            text: "The file size must be less then 20 MB",
                            icon: "error",
                            dangerMode: true,
                        })
                    _resetState();
                    return;
                }
            }else{
                setMediaTypes("image");
            }
        } else if (fileData.type.includes("video")) {
            setMediaTypes("video");
        }
        setError("");
        setModalState((prev) => {
            return {
                ...prev,
                isUploading: false,
                isShow: true,
                image: URL.createObjectURL(fileData)
            }
        })
    }

    const _resetState = () => {
        setModalState( (prev) => MODAL_INIT_STATE)
        resetState();
        setError("");
    }

    const [recenterd, setRecentered] = useState(false)
    const onImageLoad = (e) => {
        if (IMAGE_RATION) {
            const { width, height } = e.currentTarget
            if (!recenterd){
                setCrop(centerAspectCrop(width, height, IMAGE_RATION))
            }else {
                setCrop(aspectCrop(width, height, IMAGE_RATION))
            }
            setRecentered(true)
        }
    }

   const onCropComplete = (crop) => {
        //makeClientCrop(crop);
        setCompletedCrop(crop);
    };

   const makeClientCrop = async (crop, mediaTypes) => {
        if (imageRef.current && mediaTypes === "image") {
          await getCroppedImg(
            imageRef.current,
            crop,
            'TST.jpg'
          ).then(res => {
                setModalState(prevState => {
                    const url =  URL.createObjectURL(res)
                    return {
                        ...prevState,
                        image: url
                    }
                });
            });
        }
    }

    const type = mediaTypes === "image" ? "Resize Image" : "Upload File";

    return (
        <>
            <AHModal
                show={modalState.isShow}
                onHide={_resetState} 
                onClose={_resetState}
                title={type}
                error={error}
                footer={<>
                { mediaTypes === "image" && <button onClick={() => {
                        getCroppedImg(imageRef.current, completedCrop, "TST.jpg").then(res => {
                            setModalState(prevState => {
                                const url =  URL.createObjectURL(res)
                                return {
                                    ...prevState,
                                    image: url
                                }
                            })
                        });
                    }}>
                    Crop <i className="fa-solid fa-crop" />
                    </button>}
                    <button onClick={() => uploadImage(folder)}>
                        Upload <ButtonSpinner isLoading={modalState.isUploading}><i className="fa-solid fa-upload"></i></ButtonSpinner>
                    </button>
                </>}
            >
                {mediaTypes === "image" &&
                    <ReactCrop
                        crop={crop}
                        onChange={c => setCrop(c)}
                        aspect={IMAGE_RATION} 
                        onComplete={(c) => onCropComplete(c)}
                        ruleOfThirds
                    >
                       <img ref={imageRef} src={modalState.image} onLoad={onImageLoad} alt=""/>
                       {modalState.isUploading &&
                            <div className="progress" style={{"height": "4px"}}>
                                <div ref={progressBarRef}
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{"width": "0%"}}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100">
                                </div>
                        </div>
                       }
                    </ReactCrop>
                }

                {mediaTypes === "gif" &&
                    <>
                        <img ref={imageRef} src={modalState.image} alt="" className="img-fluid"/>
                        {modalState.isUploading &&
                            <div className="progress" style={{"height": "4px"}}>
                                <div style={{"width": "0%"}}
                                    ref={progressBarRef}
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100">
                                </div>
                            </div>
                        }
                    </>
                }

                {mediaTypes === "video" &&
                    <>
                        <video className="video-upload" controls loop autoPlay poster={modalState.image} width="100%" height="100%">
                            <source src={modalState.image} type='video/webm' />
                            <source src={modalState.image} type='video/mp4' />
                            <source src={modalState.image} type='video/ogg' />
                            Your browser does not support HTML5 video tags.
                        </video>

                        {modalState.isUploading &&
                            <div className="progress" style={{"height": "4px"}}>
                                <div 
                                    ref={progressBarRef} 
                                    className="progress-bar" 
                                    role="progressbar" 
                                    style={{"width": "0%"}} 
                                    aria-valuenow="25" 
                                    aria-valuemin="0" 
                                    aria-valuemax="100">
                                </div>
                            </div>
                        }
                    </>
                }
            </AHModal>
        </>
    )
})