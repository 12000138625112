import { SET_CATEGORIES } from '../constants/actiontypes';

const initialState = {
    categories: []
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories: payload,
            };
        default:
            return state;
    }
}