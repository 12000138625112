import { 
    GET_All_ARTICLES,
    GET_ARTICLES,
    CREATE_ARTICLES,
    UPDATE_ARTICLES,
    PINNED_ARTICLES
 } from '../constants/actiontypes';

const initialState = {
    articles: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_All_ARTICLES:
            return {
                ...state,
                articles: payload.articles,
            };
        case GET_ARTICLES:
            return {
                ...state,
                articles: payload.articles,
            };
        case CREATE_ARTICLES:
            return {
                ...state,
                articles: payload.articles,
            };
        case UPDATE_ARTICLES:
            return {
                ...state,
                articles: payload.articles,
            };
        case PINNED_ARTICLES:
            const pinnedId = payload?.data?.id;
            const pinnedCheck = payload?.data?.pinned;
            let index =  state.articles.data.data.findIndex(item => item?.id === pinnedId);
            let articlesDetail = state.articles.data.data;
            if (index !== -1){
                articlesDetail[index].pinned = pinnedCheck;
            }
            state.articles.data.data = articlesDetail
            return {
                ...state,
            };
        default:
            return state;
    }
}