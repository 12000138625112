import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { ShimmerCategoryList } from "react-shimmer-effects";
import { useParams } from 'react-router-dom'
import { showEventsMethod } from '../../actions/EventAction';
import {
    getTimeTablesMethod,
    deleteTimeTablesMethod,
    updateTimeTablesPositionMethod
} from '../../actions/TimeTableAction';
import Profile from '../components/Profile';
import TimetableModal from '../Modal/TimetableModal';
import TimetableScoreModal from '../Modal/TimetableScoreModal';
import ScoreItem from './ScoreItem';
import UnAuthorisedAccess from '../components/UnAuthorisedAccess';
import { NewTimetableClassObject } from '../../constants/Constants';
import swal from 'sweetalert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-hot-toast';

const TimeTables = () => {
    const { id } = useParams();
    const contentEl = useRef();
    const dispatch = useDispatch();
    const eventData = useSelector((state) => state.EventReducer.event);
    const [isLoading, setIsLoading] = useState(false);
    const [showTimetableModal, setShowTimetableModal] = useState(false);
    const [showScoringModal, setShowScoringModal] = useState(false);
    const [active, setActive] = useState(null);
    const [dates, setDates] = useState([]);
    const [selectedArena, setSelectedArena] = useState(null);
    const [timetable, setTimetable] = useState({ ...NewTimetableClassObject(selectedArena) })

    const [timetableItems, setTimetableItems] = useState([])

    const prepareByDates = (data, firstArena) => {
        let datesObject = {};
        data.forEach(t => {
            const event_date = moment(t?.start_date).format("Y-MM-DD");
            if (!datesObject?.[event_date]) {
                datesObject = { ...datesObject, [event_date]: [t] }
            } else {
                let tmp = [...datesObject[event_date]];
                tmp.push(t)
                datesObject[event_date] = tmp;
            }
        });

        let result = Object.keys(datesObject).reduce((arr, key) => {
            arr[key] = datesObject[key].filter(item => item?.event_arena_id === firstArena);
            return arr;
        }, {});
        setDates(result)
    }

    const fetchTimeTables = (event_id, firstArena) => {
        setIsLoading(true);
        dispatch(getTimeTablesMethod(event_id))
            .then((res) => {
                setIsLoading(false);
                setTimetableItems(res.data)
                prepareByDates(res.data, firstArena);
            }).catch((err) => {
                setIsLoading(false);
                console.log(err)
            })
    }

    useEffect(() => {
        if (id && eventData) {
            if (eventData?.arenas) {
                let firstArena = eventData?.arenas[0];
                setSelectedArena(firstArena?.id);
            }
        }

        if (id && !eventData) {
            dispatch(showEventsMethod(id))
                .catch((err) => {
                    console.log(err);
                });
        }

    }, [id, eventData])

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }

    const HandleEditScores = (timetable_id) => {
        const editableTimetable = timetableItems.find((t) => t?.id === timetable_id);
        if (editableTimetable) {
            setTimetable({ ...editableTimetable })

            setTimeout(() => {
                setShowScoringModal(true);
            }, 100)
        }
    }

    useEffect(() => {
        fetchTimeTables(id, selectedArena);
        setTimetable(timetable => ({ ...timetable, event_arena_id: selectedArena }))
    }, [selectedArena])

    const selectNewItems = (date) => {
        setShowTimetableModal(true);
        setTimetable(timetable => ({
            ...NewTimetableClassObject(selectedArena),
            start_date: moment(date).format('YYYY-MM-DD'),
            end_date: moment(date).format('YYYY-MM-DD'),
        }));
    }

    const HandleClickOnAddNewClass = (e) => {
        e.preventDefault();
        setTimetable({ ...NewTimetableClassObject(selectedArena) })
        setShowTimetableModal(true)
    }

    const HandleEditTimetable = (timetable_id) => {
        const editableTimetable = timetableItems.find((t) => t?.id === timetable_id);
        if (editableTimetable) {
            setTimetable({ ...editableTimetable })

            setTimeout(() => {
                setShowTimetableModal(true)
            }, 100)
        }
    }

    const HandleRemoveTimetable = (timetable_id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this timetable class?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((res) => {
                dispatch(deleteTimeTablesMethod(eventData?.id, timetable_id))
                    .then((res) => {
                        if (res.status) {
                            swal("Deleted!", res.message, "success");
                            fetchTimeTables(id, selectedArena);
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    if (eventData && !eventData?.arenas?.length) {
        return <UnAuthorisedAccess heading={'Event ' + eventData?.title} message="Please add atleast one arena for adding in timetable" />
    }

    function handleOnDragEnd(result, date) {
        if (!result.destination) return;
        const tmpdates = { ...dates };
        const items = Array.from(tmpdates[date]);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const payloadForServer = items.map((it) => it.id)

        dispatch(updateTimeTablesPositionMethod(eventData?.id, payloadForServer))
            .then((res) => {
                toast.success(res.message);
            })
            .catch((err) => {
                console.log(err)
            })

        tmpdates[date] = items;
        setDates(tmpdates);
    }

    return (
        <>
            <div className="admin-dashboard users-panel timetable-panel">
                <header>
                    <Profile heading={eventData ? `Event ${eventData?.title}` : "Event"} pathurl="new-event" showbtn="no" />
                    <div className="user-action-panel">
                        <div className="row aic">
                            <div className="col-lg-9 col-md-9 col-12">
                                <div className="action-box pe-3">
                                    <div className="back-to-user">
                                        {eventData && eventData?.arenas?.map((arena, index) => {
                                            return (
                                                <a href="!#" key={`arena_${index}`} onClick={(e) => { e.preventDefault(); setSelectedArena(arena?.id) }
                                                } className={`requests-arena-btn ${selectedArena === arena?.id && 'active'}`}>
                                                    <span className='arena-name'>{arena?.name}</span>
                                                </a>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-12 text-end">
                                <button type="button"
                                    className="main-btn"
                                    onClick={HandleClickOnAddNewClass}
                                >
                                    Add New Class
                                </button>
                            </div>
                        </div>
                    </div>
                </header >
                <div className="admin-dashboard-panel timetable-panel-list">
                    <div className="app-listing-panel">
                        <div className="col-lg-12 col-md-12 col-12 mb-5" >
                            {!isLoading ? dates && Object.keys(dates).map(date => {
                                return (
                                    <div key={`date_${date}`}>
                                        {dates[date]?.length > 0 && (
                                            <div className="timetable-list-date">
                                                <h4 className="date_heading">{moment(`${date}`).format("dddd MMMM Do YYYY")}</h4>
                                                <button onClick={() => selectNewItems(date)} className="main-btn-capsule">
                                                    <span>  New Item </span>
                                                </button>
                                            </div>
                                        )}
                                        <DragDropContext onDragEnd={(r) => handleOnDragEnd(r, date)}>
                                            <Droppable droppableId="characters">
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        {
                                                            dates[date]?.length > 0 ? dates[date].map((timetable, index) => {
                                                                return (
                                                                    <Draggable disableInteractiveElementBlocking={false} key={`t_${timetable?.id}`} draggableId={`t_${timetable?.id}`} index={index}>
                                                                        {(provided) => (
                                                                            <div key={`timetables_${timetable?.id}`} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                                <ul className="nav-panel">
                                                                                    <li className="nav-panel-item">
                                                                                        <div className="app-box">
                                                                                            <div className="app-toggle">
                                                                                                <img src="/images/app-toggle.png" className="img-fluid" alt="" />
                                                                                            </div>
                                                                                            <div className="app-info">
                                                                                                <span>{timetable?.start_time ? moment(`${moment().format('YYYY-MM-DD')} ${timetable?.start_time}`).format("hh:mm A") : ''}</span>
                                                                                            </div>
                                                                                            <div className="app-info">
                                                                                                <span>{timetable?.name}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="social-info">
                                                                                            {timetable?.sponsor_by && <ul className="d-flex align-items-center">
                                                                                                <li>Sponsored by : {timetable?.sponsor_by}</li>
                                                                                            </ul>}
                                                                                        </div>
                                                                                        <div className="apps-action timetable-action">
                                                                                            {timetable?.scores?.length > 0 && (
                                                                                                <button className="p-0 border-0 background" onClick={() => handleToggle(timetable?.id)}>
                                                                                                    <i className={`fa-sharp fa-solid fa-chevron-${active === timetable?.id ? 'up' : 'down'}`}></i>
                                                                                                </button>
                                                                                            )}
                                                                                            <button type="button" className="p-0 border-0 background" onClick={(e) => { HandleEditScores(timetable?.id) }}>
                                                                                                <img alt="" src={timetable?.scores?.length > 0 ? "/images/trophy2.svg" : "/images/trophy1.svg"} className="img-fluid" />
                                                                                            </button>
                                                                                            <button type="button" className="p-0 border-0 background" onClick={() => HandleEditTimetable(timetable?.id)}>
                                                                                                <i className="fa-solid fa-pen"></i>
                                                                                            </button>

                                                                                            <button type="button" className="p-0 border-0 background" onClick={() => HandleRemoveTimetable(timetable?.id)}>
                                                                                                <i className="fa-solid fa-trash"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                                {timetable?.scores?.length > 0 ? <ScoreItem scores={timetable?.scores} contentEl={contentEl} active={active} id={timetable?.id} /> : null}
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            }) : null
                                                        }
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>

                                    </div >)
                            }) : <ShimmerCategoryList items={4} categoryStyle="STYLE_ONE" />
                            }
                        </div >
                    </div >
                </div >
                <TimetableModal
                    arena_id={selectedArena}
                    show={showTimetableModal}
                    payload={timetable}
                    closeModal={() => setShowTimetableModal(false)}
                    getTimetables={() => fetchTimeTables(id, selectedArena)}
                />
                <TimetableScoreModal
                    show={showScoringModal}
                    timetable={timetable}
                    closeModal={() => setShowScoringModal(false)}
                    getTimetables={() => fetchTimeTables(id, selectedArena)}
                />
            </div >
        </>
    );
};

export default TimeTables;
