import React from "react";
import { convertUrlToJpg } from "../../Common/AHImage";
import Linkify from 'react-linkify';

const Post = ({ post, onDelete, published_at }) => {

  const renderItems = post?.slider?.map((item) => (
    <div className="col-lg-3 col-md-3 mt-3" key={`post_${post?.id}_${item?.id}`}>
      {item.media_type === "video" ? (
        <video
          src={item?.url}
          alt={item?.title}
          className="img-fluid"
          type="video/mp4"
          loop
          controls
        />
      ) : (
        <img src={convertUrlToJpg(item?.url)} alt={item?.title} className="img-fluid" />
      )}
    </div>
  ));
  return (    
    <div className="card-body">
      <p className="m-0"><Linkify>{post?.title}</Linkify></p>
      <div className="row">{renderItems}</div>
    </div>
  );
};

export default Post;
