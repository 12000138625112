import { deleteAssets } from "../services/Cloudinary.service";
import { ErrorHandler } from "../services/ErrorHandler";

export const deleteAssetsMethod = (payload) => {
    return dispatch => {
        return ErrorHandler(dispatch)(deleteAssets(payload))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}