import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import SpinnerLoader from './layout/SpinnerLoader'
import { SizeUtils } from './Utils/SizeUtils'
import { Role } from './constants/roles';
import PushNotifications from './views/PushNotification/PushNotifications';
import NewPushNotification from './views/PushNotification/NewPushNotification';
import ShowPushNotification from './views/PushNotification/ShowPushNotification';
import Events from './views/Event/Events';
import NewEvent from './views/Event/NewEvent';
import EditEvent from './views/Event/EditEvent';
import LiveFeed from './views/Event/LiveFeed';
import TimeTables from './views/Event/TimeTables';
import EditPost from './views/Post/EditPost';
import Galleries from './views/Gallery/Galleries';
import CreateGallery from './views/Gallery/CreateGallery';
import EditGallery from './views/Gallery/EditGallery';
import Magazines from './views/Magazine/Magazines';
import CreateMagazine from './views/Magazine/CreateMagazine';
import EditMagazine from './views/Magazine/EditMagazine';
import MagazineFiles from './views/Magazine/MagazineFiles';
import CreateMagazineFile from './views/Magazine/CreateMagazineFile';
import EditMagazineFile from './views/Magazine/EditMagazineFile';

// Pages
// const Login = React.lazy(() => import('./views/AuthAdmin/Login'), 50)
const Login = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./views/AuthAdmin/Login')), 2000);
  });
});

const ForgetPassword = React.lazy(() => import('./views/AuthAdmin/ForgetPassword'))
const RequireAuth = React.lazy(() => import('./layout/RequireAuth'))

//const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const DefaultLayout = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./layout/DefaultLayout')), 2000);
  });
});

const Admin = React.lazy(() => import('./views/Admin/Admin'))
const Users = React.lazy(() => import('./views/Users/Users'))
const NewUsers = React.lazy(() => import('./views/Users/NewUser'))
const EditUser = React.lazy(() => import('./views/Users/EditUser'))
const Stories = React.lazy(() => import('./views/Stories/AdminStories'))
const NewStory = React.lazy(() => import('./views/Stories/NewStory'))
const AdminStoryEdit = React.lazy(() => import('./views/Stories/AdminStoryEdit'))
const Articles = React.lazy(() => import('./views/Articles/Articles'))
const CreateArticle = React.lazy(() => import('./views/Articles/CreateArticle'))
const EditArticels = React.lazy(() => import('./views/Articles/EditArticels'))
const ForumEntries = React.lazy(() => import('./views/ForumEntries/ForumEntries'))
const NewForumEntries = React.lazy(() => import('./views/ForumEntries/NewForumEntries'))
const EditForumEntries = React.lazy(() => import('./views/ForumEntries/EditForumEntries'))
const Messages = React.lazy(() => import('./views/Messages/Messages'))
const NewMessages = React.lazy(() => import('./views/Messages/NewMessages'))
const EditMessage = React.lazy(() => import('./views/Messages/EditMessage'))
const Requests = React.lazy(() => import('./views/Requests/Requests'))
const AppConfiguration = React.lazy(() => import('./views/AppConfiguration/AppConfiguration'))
const AdminProfile = React.lazy(() => import('./views/Admin/AdminProfile'))
const Support = React.lazy(() => import('./views/Support/Support'))
const CreateTicket = React.lazy(() => import('./views/Support/CreateTicket'))
const ViewTicket = React.lazy(() => import('./views/Support/ViewTicket'))
const EditApp = React.lazy(() => import('./views/AppConfiguration/EditApp'))
const MobileScreen = React.lazy(() => import('./views/components/MobileScreen'))
// Advertiser
const Ads = React.lazy(() => import('./views/Ads/Ads'));
const CreateAds = React.lazy(() => import('./views/Ads/CreateAds'))
const EditAds = React.lazy(() => import('./views/Ads/EditAds'))

// Error pages
const PageNotFound = React.lazy(() => import('./views/Error/PageNotFound'))

function App() {
  const { isMobile } = SizeUtils();

  return (
    <Suspense fallback={<SpinnerLoader />}>
      <Routes>
        {!isMobile ? (<>
          {/* public routes */}
          <Route path="/" element={<Login />} />
          <Route path="forget-password" element={<ForgetPassword />} />

          {/* protect routes */}
          <Route path="/dashboard" element={<DefaultLayout />} >
            <Route index element={<Admin />} />
            <Route path="admin-profile" element={<AdminProfile />} />

            <Route element={<RequireAuth allowedRoles={[Role.Admin]} />}>
              <Route path="users" element={<Users />} />
              <Route path="new-user" element={<NewUsers />} />
              <Route path="edit-user/:id" element={<EditUser />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.StandardUser, Role.Editor, Role.Expert, Role.Advertiser]} />}>
              <Route path="stories" element={<Stories />} />
              <Route path="new-stories" element={<NewStory />} />
              <Route path="edit-stories/:id" element={<AdminStoryEdit />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.StandardUser, Role.Expert, Role.Editor]} />}>
              <Route path="articles" element={<Articles />} />
              <Route path="create-article" element={<CreateArticle />} />
              <Route path="edit-articles/:id" element={<EditArticels />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.StandardUser, Role.Editor, Role.Expert, Role.Advertiser]} />}>
              <Route path="forum-entries" element={<ForumEntries />} />
              <Route path="new-forum-entries" element={<NewForumEntries />} />
              <Route path="edit-forum-entries/:id" element={<EditForumEntries />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.Editor, Role.Advertiser]} />}>
              <Route path="messages" element={<Messages />} />
              <Route path="new-messages" element={<NewMessages />} />
              <Route path="messages-edit" element={<EditMessage />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.Editor, Role.Advertiser]} />}>
              <Route path="push-notifications" element={<PushNotifications />} />
              <Route path="push-notifications/new" element={<NewPushNotification />} />
              <Route path="push-notifications/:id" element={<ShowPushNotification />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.Editor, Role.Advertiser]} />}>
              <Route path="galleries" element={<Galleries />} />
              <Route path="galleries/create" element={<CreateGallery />}  />
              <Route path="galleries/:id/edit" element={<EditGallery />}  />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.Editor, Role.Advertiser]} />}>
              <Route path="magazines" element={<Magazines />} />
              <Route path="magazines/create" element={<CreateMagazine />} />
              <Route path="magazines/:id/edit" element={<EditMagazine />} />
              <Route path="magazines/:id/files" element={<MagazineFiles />}  />
              <Route path="magazines/:id/files/create" element={<CreateMagazineFile />}  />
              <Route path="magazines/:id/files/:file_id/edit" element={<EditMagazineFile />}  />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin]} />}>
              <Route path="requests" element={<Requests />} />
              <Route path="app-configuration" element={< AppConfiguration />} />
              <Route path="new-event" element={<NewEvent />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.Influencer, Role.Editor]} />}>
              <Route path="events" element={<Events />} />
              <Route path="events/:id/edit" element={<EditEvent />} />
              <Route path="events/:id/live-feed" element={<LiveFeed />} />
              <Route path="events/:id/timetable" element={<TimeTables />} />
              <Route path="edit-posts/:id" element={<EditPost />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.StandardUser, Role.Editor]} />}>
              <Route path="support" element={<Support />} />
              <Route path="create-ticket" element={<CreateTicket />} />
              <Route path="view-ticket" element={<ViewTicket />} />
              <Route path="editApp" element={<EditApp />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.Advertiser]} />}>
              <Route path="ads" element={<Ads />} />
              <Route path="create-ads" element={<CreateAds />} />
              <Route path="edit-ads/:id" element={<EditAds />} />
            </Route>
          </Route>

          {/* Catch all */}
          <Route path="*" element={<PageNotFound />} />
        </>) : (<Route path="*" element={<MobileScreen />} />)}
        {isMobile && <Route path="/" element={<MobileScreen />} />}
      </Routes>
    </Suspense>
  );
}

export default App;
