import { ErrorHandler } from "../services/ErrorHandler";
import { deleteMagazine, getMagazines, getMagazineDetails, restoreMagazine, storeMagazine, updateMagazine, storeMagazineFile, getMagazineFiles, deleteMagazineFile, restoreMagazineFile, getMagazineFileDetails, updateMagazineFile } from "../services/magazine.service";

export const getMagazinesMethod = (query) => {
    return dispatch => {
        return ErrorHandler(dispatch)(getMagazines(query))
        .then(data => {
            return Promise.resolve(data.data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const storeMagazineMethod = (payload) => {
    return dispatch => {
        return ErrorHandler(dispatch)(storeMagazine(payload))
        .then(data => {
            return Promise.resolve(data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const updateMagazineMethod = (id, payload) => {
    return dispatch => {
        return ErrorHandler(dispatch)(updateMagazine(id, payload))
        .then(data => {
            return Promise.resolve(data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const getMagazineDetailsMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(getMagazineDetails(id))
        .then(data => {
            return Promise.resolve(data.data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const deleteMagazineMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(deleteMagazine(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const restoreMagazineMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(restoreMagazine(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

/*** Magazine files methods */

export const getMagazineFilesMethod = (id, query) => {
    return dispatch => {
        return ErrorHandler(dispatch)(getMagazineFiles(id, query))
        .then(data => {
            return Promise.resolve(data.data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const storeMagazineFileMethod = (payload) => {
    return dispatch => {
        return ErrorHandler(dispatch)(storeMagazineFile(payload))
        .then(data => {
            return Promise.resolve(data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const getMagazineFileDetailsMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(getMagazineFileDetails(id))
        .then(data => {
            return Promise.resolve(data.data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const updateMagazineFileMethod = (id, payload) => {
    return dispatch => {
        return ErrorHandler(dispatch)(updateMagazineFile(id, payload))
        .then(data => {
            return Promise.resolve(data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const deleteMagazineFileMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(deleteMagazineFile(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const restoreMagazineFileMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(restoreMagazineFile(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}