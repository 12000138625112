import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { getUsers } from '../../actions/UsersAction';
import UserCard from '../components/User/UserCard';
import ButtonComponent from '../components/General/ButtonComponent';
import SearchComponent from '../components/General/SearchComponent';

const SelectUserModal = ({ show, closeModal, multiple, onSelect }) => {

    const dispatch = useDispatch();

    const [pagination, setPagination] = useState({data: []});
    const [keyword, setKeyword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const getUserList = () => {
        setLoading(true);

        let query = '?search='+keyword;
        dispatch(getUsers(query)).then((res) => {
            setPagination(res.data)
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
    }
    useEffect(() => {
        getUserList()
    }, [])

    useEffect(() => {
        getUserList()
    }, [keyword])

    const HandlePressKeyword = (value) => {
        setKeyword(value)
    };

    const HandleSelection = (item) => {
        let tmpSelected = [...selected];
        let isAlreadySelected = tmpSelected.find(s => s.id === item.id);
        if(!isAlreadySelected){
            if(!multiple){
                onSelect(item);
            }else{
                tmpSelected.push(item);
                setSelected([...tmpSelected]);
            }
        }
    }

    const HandleOnSelect = () => {
        onSelect([...selected])
    }

    return (
        <Modal show={show} size="lg" className="select-user-modal" scrollable centered onHide={() => closeModal()}>
            <Modal.Header>
                <SearchComponent onChange={HandlePressKeyword} />
            </Modal.Header>
            <Modal.Body>
                <div className='user-listing-panel'>
                    <ul className='nav-panel'>
                        {pagination.data.map((item) => {
                            return <UserCard
                                key={`user_list_${item?.id}`}
                                user={item}
                                selectedFilter=''
                                onSelect={() => HandleSelection(item)}
                            />
                        })}
                    </ul>
                </div>
            </Modal.Body>
            {multiple &&
                <Modal.Footer>
                    <ButtonComponent label='Select' onClick={HandleOnSelect} />
                </Modal.Footer>
            }
        </Modal>
    )
}

export default SelectUserModal