import axios from "axios";

const instance = axios.create({
    baseURL: 'https://api.cloudinary.com',
    headers: {
        "Content-Type": "application/json",
    },
});

export const UploadFile = async (file, onUploadProgress, folder) => {
    const data = new FormData()
    data.append("file", file);
    data.append("upload_preset","arabian");
    data.append("cloud_name","dnrof9env");
    data.append("folder", folder);
    const config = {
        onUploadProgress: onUploadProgress
    }
    return  instance.postForm("v1_1/dnrof9env/upload", data, config)
};