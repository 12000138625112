import React from "react";

const TimeTable = ({ item }) => {
  return (
      <div className="card-body">
        <h4>{item.name}</h4>
        <div className="listing-box">
          <div className="d-flex w-60">
            <div className="image-box forum_user_img"></div>
          </div>
        </div>
      </div>
  );
};

export default TimeTable;
