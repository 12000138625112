import {
    GET_All_ARTICLES,
    CREATE_ARTICLES,
    UPDATE_ARTICLES,
    PINNED_ARTICLES,
} from '../constants/actiontypes';
import ArticlesService from "../services/articles.service";
import { ErrorHandler } from '../services/ErrorHandler';


export const getAllArticles = (query) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ArticlesService.getAllArticles(query))
        .then(data => {
            dispatch({
                type: GET_All_ARTICLES,
                payload: { articles: data },
            });
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const getArticle = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ArticlesService.getArticle(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        }); 
    }
}

export const createArticles = (articles) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ArticlesService.createArticles(articles))
        .then(data => {
            dispatch({
                type: CREATE_ARTICLES,
                payload: { articles : data },
            });
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
            return Promise.reject(message);
        });
    }
}

export const updateArticles = (id, articles) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ArticlesService.updateArticles(id, articles))
        .then(data => {
            dispatch({
                type: UPDATE_ARTICLES,
                payload: { articles },
            });
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
            return Promise.reject(message);
        });
    }
}


export const deleteArticles = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ArticlesService.deleteArticles(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const restoreArticle = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ArticlesService.restoreArticle(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const pinnedArticles = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ArticlesService.pinnedArticles(id))
        .then(data => {
            dispatch({
                type: PINNED_ARTICLES,
                payload: { data },
            });
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
            return Promise.reject(message);
        });
    }
}

export const ApproveArticle = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(ArticlesService.approveArticle(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}