import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ShimmerCategoryList } from "react-shimmer-effects";
import Profile from '../components/Profile'
import { getSinglePushNotificationMethod } from '../../actions/PushNotificationAction';
import { useDispatch } from 'react-redux';

const ShowPushNotification = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState({ users: [] });

    const getNotification = () => {
        setIsLoading(true);
        dispatch(getSinglePushNotificationMethod(id)).then((res) => {
            setNotification(res.data);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getNotification();
    }, [])

    const renderUsers = notification.users.map((u, index) => {
        return (
            <li className="nav-panel-item" key={`user_${index}`}>
                <div className="message-media">
                    <div className={`check-mark ${u?.is_sent ? 'green-background' : 'red-background'}`}>
                        <i className={`fa fa-2x text-white ${u?.is_sent ? "fa-check" : "fa-xmark"}`}></i>
                    </div>
                    <div className="message-info">
                        <h5 className="m-0">{u?.user?.name}</h5>
                        <p><a>@{u?.user?.username}</a></p>
                    </div>
                </div>
                <div className="view-messages massage_box">
                    <i className={`fab fa-2x ${u?.device === "ios" ? "fa-apple" : "fa-android"}`} />
                </div>
                <div className="messages-action justify-content-end gap-3">
                    {!u?.is_sent && <p>{u?.failed_reason}</p>}
                </div>

            </li>
        )
    })

    return (
        <>
            <div className="admin-dashboard users-panel">
                <header>
                    <Profile heading="Push Notifications" pathurl="push-notifications/new" showbtn="yes" />
                </header>
                <div className="admin-dashboard-panel">
                <div className="article-action-panel show-push-notifications">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="action-box pe-0">
                                    <div className="back-to-user">
                                        <Link to="/dashboard/push-notifications">
                                            <img src="/images/Refund_back.svg" className="img-fluid" alt=' ' />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="user-filter-panel py-2">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-6">
                                <label>Title:</label>
                            </div>
                            <div className="col-lg-10 col-md-9 col-6">
                                {notification?.title}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-6">
                                <label>Content:</label>
                            </div>
                            <div className="col-lg-10 col-md-9 col-6">
                                {notification?.content}
                            </div>
                        </div>
                    </div>
                    <div className="messages-listing-panel">
                        {!isLoading ? notification?.users?.length > 0 ? (<ul className="nav-panel">{renderUsers}</ul>) : (<span>No data available.</span>) : (<ShimmerCategoryList items={5} categoryStyle="STYLE_FIVE" />)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowPushNotification