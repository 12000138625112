const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.token;
};
  
const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.token;
};
  
const updateLocalAccessToken = (token) => {
    const user = JSON.parse(localStorage.getItem("user"));
    user.token.idToken = token?.access_token;
    user.token.refreshToken = token?.refresh_token;
    localStorage.setItem("user", JSON.stringify(user));
};
  
const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
  
const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
};
  
const removeUser = () => {
    localStorage.removeItem("user");
};
  
const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
};
  
export default TokenService;