import React from 'react'
import { Deleted } from '../Icon'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import { useDispatch } from 'react-redux'
import { deleteMagazineFileMethod, restoreMagazineFileMethod } from '../../../actions/MagazineAction'
import { get500WidthImage } from '../../Common/AHImage'
import UserImage from '../User/UserImage'

const MagazineFileCard = ({item, getList, showRestore, hideEdit}) => {

  const dispatch = useDispatch();
 
  const deleteHandler = (e) => {
    e.preventDefault();
    swal({
        title: "Are you sure?",
        text: "Are you sure you want to remove this magazine?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then(willDelete => {
        if (willDelete) {
            dispatch(deleteMagazineFileMethod(item?.id))
                .then(res => {
                    getList()
                    swal("Deleted !", "File has been deleted!", "success");
                }).catch(e => {
                    swal("Something went wrong", "", "error");
                    console.log(e);
                });
        }
    });
  }

  const restoreHandler = (e) => {
    e.preventDefault();
    swal({
        title: "Are you sure?",
        text: "Are you sure you want to restore this magazine?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then(willDelete => {
        if (willDelete) {
            dispatch(restoreMagazineFileMethod(item?.id))
                .then(res => {
                    getList()
                    swal("Restored!", "Magazine has been restored!", "success");
                }).catch(e => {
                    swal("Something went wrong", "", "error");
                    console.log(e);
                });
        }
    });
}

  return (
    <div className="col-6 mb-3">
      <div className="row magazine-file-card gx-0">
        <div className='col-4'>
          <img src={get500WidthImage(item?.cover_image)} className="img-fluid cover-img" alt="" />
        </div>
        <div className='col-8 right-wrapper'>
          <div className='top-wrapper'>
            <div className='file-title-wrapper'>
              <h2 className='file-title'>{item?.title}</h2>
            </div>
            <UserImage
              image_url={item?.user_details?.profile_picture}
              role={item?.user_details?.user_role}
            />
          </div>
          <div className='bottom-wrapper'>
            {!showRestore ?
              <Deleted deleteHandler={deleteHandler} title={`Delete Magazine`} />
            :
              <a href="!#" onClick={restoreHandler} className="main-btn-capsule">Restore</a>                               
            }
            {!hideEdit && <Link to={`/dashboard/magazines/${item?.magazine_id}/files/${item?.id}/edit`} className="main-btn-capsule px-5">Edit</Link>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MagazineFileCard