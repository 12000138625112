import { GET_EVENT, SET_EVENT } from '../constants/actiontypes';

const initialState = {
    event: null
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_EVENT:
            return {
                ...state,
                event: payload,
            };
        case SET_EVENT:
            return {
                ...state,
                event: payload,
            };
        default:
            return state;
    }
}