import { centerCrop, makeAspectCrop } from "react-image-crop";
import { Role } from "../constants/roles";

export const getCroppedImg = async (image, crop, fileName) => {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const canvas = document.createElement("canvas");

    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob((file) => {
            file.name = fileName;
            resolve(file);
        }, "image/jpeg");
    });
};

export const centerAspectCrop = (
    mediaWidth,
    mediaHeight,
    aspect,
) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                x: aspect === 0 ? 130 : 0,
                y: aspect === 0 ? 50 : 0,
                width: 200,
                height: 200,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

export const aspectCrop = (
    mediaWidth,
    mediaHeight,
    aspect,
) => {
    return makeAspectCrop(
        {
            unit: '%',
            width: 100,
            height: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight,
    )
}

export const OnBehalfArticle = (author, user) => {
    let ArticleBy = "";
    let ArticleByUsername = `@` + user?.username;
    if (user?.user_role === Role.Editor) {
        ArticleBy += "Editor's";;
    } else if (user?.user_role === Role.Expert) {
        ArticleBy += "Expert's";
    } else if (user?.user_role === Role.Sponsor) {
        ArticleBy += "Sponsor's";
    }

    if (user?.id === author?.id) {
        ArticleBy += " article by ";
    } else {
        ArticleBy += " article by ";
        ArticleByUsername = `@` + author?.username + " for @" + user?.username;
    }

    const data = { name: ArticleBy.trim().firtCapitalize(), username: ArticleByUsername };
    return data;
}

export const nthNumber = (number) => {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
};

export const kFormatter = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}