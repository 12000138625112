import React from 'react';
import { Roles } from '../../constants/roles';
import { ArrowDown } from './Icon';

export const UsersTypeFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='role' onChange={(e) => onChange(e)}>
                    <option value="">All Users Types</option>
                    {Roles.map((r) => {
                        return (
                            <option key={r} value={r}>{r}</option>
                        )
                    })}
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const NewestOldestFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name="sort" onChange={(e) => onChange(e)}>
                    <option value="id">Newest first</option>
                    <option value="recent_activity">Most recent activity</option>
                    <option value="most_content">Most content (articles/stories/forum)</option>
                    <option value="followers">Most connections (friends)</option>
                    <option value="recent_online">Recent online</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const NewestOldestStoriesFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name="sort" onChange={(e) => onChange(e)}>
                    <option value="id">Newest first</option>
                    <option value="likes_count">Most Liked</option>
                    <option value="shares_count">Most Shared</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const NewestMostArticleFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name="sort" onChange={(e) => onChange(e)}>
                    <option value="id">Newest first</option>
                    <option value="likes_count">Most liked</option>
                    <option value="shares_count">Most shared</option>
                    <option value="saves_count">Most Saved</option>
                    <option value="views_count">Most viewed</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const GalleryFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name="sort" onChange={(e) => onChange(e)}>
                    <option value="id">Newest first</option>
                    <option value="likes_count">Most liked</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const GalleryActiveOrTrashedFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='status' onChange={(e) => onChange(e)}>
                    <option value="">All Galleries</option>
                    <option value="Trashed">Trashed Galleries</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const MagazineFilters = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='status' onChange={(e) => onChange(e)}>
                    <option value="">All Magazines</option>
                    <option value="Active">Active Magazines</option>
                    <option value="Featured">Featured Magazines</option>
                    <option value="Trashed">Trashed Magazines</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const MagazineFileFilters = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='status' onChange={(e) => onChange(e)}>
                    <option value="">All Magazines</option>
                    <option value="Trashed">Trashed Magazines</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const MagazineFileSorts = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name="sort" onChange={(e) => onChange(e)}>
                    <option value="id" data-sort_order="desc">Newest first</option>
                    <option value="id" data-sort_order="asc">Oldest first</option>
                    <option value="likes_count">Most liked</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const NewestMostForumFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name="sort" onChange={(e) => onChange(e)}>
                    <option value="id">Newest first</option>
                    <option value="likes_count">Most liked</option>
                    <option value="shares_count">Most shared</option>
                    <option value="comments_count">Most commented</option>
                    <option value="views_count">Most viewed</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const PublishedArticlesFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='status' onChange={(e) => onChange(e)}>
                    <option value="">All articles</option>
                    <option value="Active">Active articles</option>
                    <option value="Inactive">Inactive articles</option>
                    <option value="Pinned">Pinned articles</option>
                    <option value="Featured">Featured articles</option>
                    <option value="Trashed">Trashed articles</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const PublishedForumFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='status' onChange={(e) => onChange(e)}>
                    <option value="">All forum entries</option>
                    <option value="active">Active forum entries</option>
                    <option value="inactive">Inactive entries</option>
                    <option value="pinned">Pinned forum entries</option>
                    <option value="trashed">Trashed forum entries</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const UsersFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='users' onChange={(e) => onChange(e)}>
                    <option value="users">Select Users</option>
                    <option value="trashed">Trashed Users</option>
                    <option value="unverified">Unverified Users</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const StoriesFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='status' onChange={(e) => onChange(e)} >
                    <option value="">All Stories</option>
                    <option value="active">Active Stories</option>
                    <option value="inactive">Inactive Stories</option>
                    <option value="trashed">Trashed Stories</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const AdsActiveFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='status' onChange={(e) => onChange(e)} >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const AdsUsersFilter = ({ onChange, advertisement }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='users' onChange={(e) => onChange(e)}>
                    <option value="">All Advertisements</option>
                    {advertisement && advertisement?.map((user, index) => {
                        return (
                            <option key={index} value={user.id}>{user.name}</option>
                        )
                    })}
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const AdsNewestFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='sort' onChange={(e) => onChange(e)}>
                    <option value="desc">Newest first</option>
                    <option value="asc">Most Clicked</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    );
};

export const MessagesFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='status' onChange={(e) => onChange(e)}>
                    <option>All Messages</option>
                    <option>Sent</option>
                    <option>Scheduled</option>
                    <option>Drafts</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    )
};

export const RequestsFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name='is_reviewed' onChange={(e) => onChange(e)}>
                    <option value="">All Requests</option>
                    <option value="1">Reviewed</option>
                    <option value="0">New</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    )
};

export const RequestsNewest = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name="sort" onChange={(e) => onChange(e)}>
                    <option value={"desc"}>Newest First</option>
                    <option value={"asc"}>Order by user type</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    )
};

export const AllTicketsFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name="sort" onChange={(e) => onChange(e)}>
                    <option>All tickets</option>
                    <option>Order by user type</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    )
};

export const TicketsNewestFilter = ({ onChange }) => {
    return (
        <div className="form-group">
            <div className="user-types">
                <select name="sort" onChange={(e) => onChange(e)}>
                    <option>Newest First</option>
                </select>
                <ArrowDown />
            </div>
        </div>
    )
};








