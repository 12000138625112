import Modal from 'react-bootstrap/Modal';

export const AHModal = ({show, title, error, onClose, onHide, children, footer}) => {
    return (
        <Modal show={show} onHide={onHide} backdrop="static" scrollable centered>
                <Modal.Header closeButton>
                    <Modal.Title>{ title }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                <Modal.Footer>
                    {error ? (<span className='upload-error'>{error}: Please upload to another file</span>) : null}
                    {footer}
                </Modal.Footer>
        </Modal>
    )
}