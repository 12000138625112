import { ErrorHandler } from '../services/ErrorHandler';
import TimeTableService from '../services/timetable.service';

export const getTimeTablesMethod = (event) => {
    return dispatch => {
        return ErrorHandler(dispatch)(TimeTableService.getTimeTables(event))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                return Promise.reject(error);
            });
    }
}

export const createTimeTablesMethod = (event, timetables) => {
    return dispatch => {
        return ErrorHandler(dispatch)(TimeTableService.createTimeTable(event, timetables))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
                return Promise.reject(message);
            });
    }
}

export const showTimeTablesMethod = (id, event) => {
    return dispatch => {
        return ErrorHandler(dispatch)(TimeTableService.showTimeTable(id, event))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                return Promise.reject(error);
            });
    }
}

export const updateTimeTablesMethod = (id, event, timetables) => {
    return dispatch => {
        return ErrorHandler(dispatch)(TimeTableService.updateTimeTable(id, event, timetables))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
                return Promise.reject(message);
            });
    }
}

export const deleteTimeTablesMethod = (event_id, timetable_id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(TimeTableService.deleteTimeTable(event_id, timetable_id))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                return Promise.reject(error);
            });
    }
}

export const setTimeTableScoreMethod = (event_id, timetable_id, payload) => {
    return dispatch => {
        return ErrorHandler(dispatch)(TimeTableService.setTimeTableScore(event_id, timetable_id, payload))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
                return Promise.reject(message);
            });
    }
}

export const updateTimeTablesPositionMethod = (event_id, timetable_ids) => {
    return dispatch => {
        return ErrorHandler(dispatch)(TimeTableService.updateTimeTablesPosition(event_id, timetable_ids))
            .then(data => {
                return Promise.resolve(data);
            }).catch(error => {
                const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
                return Promise.reject(message);
            });
    }
}

