import React from 'react'
import { getStoriesArticlesImage } from '../../Common/AHImage'
import { Deleted, ViewIcon } from '../Icon'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import { useDispatch } from 'react-redux'
import { deleteGalleryMethod, restoreGalleryMethod } from '../../../actions/GalleryAction'
import UserImage from '../User/UserImage'

const GalleryCard = ({ item, getList, hideDelete, hideEdit, showRestore }) => {

    const dispatch = useDispatch();

    const deleteHandler = (e) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to remove this gallery?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                dispatch(deleteGalleryMethod(item?.id))
                    .then(res => {
                        getList()
                        swal("Deleted!", "Gallery has been deleted!", "success");
                    }).catch(e => {
                        swal("Something went wrong", "", "error");
                        console.log(e);
                    });
            }
        });
    }

    const restoreHandler = (e) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to restore this gallery?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                dispatch(restoreGalleryMethod(item?.id))
                    .then(res => {
                        getList()
                        swal("Restored!", "Gallery has been restored!", "success");
                    }).catch(e => {
                        swal("Something went wrong", "", "error");
                        console.log(e);
                    });
            }
        });
    }

    return (
        <div className="articles">
            <div className="articles-box" style={{ backgroundImage: `url(${getStoriesArticlesImage(item?.featured_image)})` }}>
                <div className="articles-header">
                    <div className="articles-author">
                        <UserImage 
                            image_url={item?.user_details?.profile_picture}
                            role={item?.user_details?.user_role}
                        />
                        <h4>{item?.user_details?.name}
                            <span>@{item?.user_details?.username}</span>
                        </h4>
                    </div>
                </div>
                <div className="articles-footer">
                    <h5>{item?.title}</h5>
                </div>
            </div>
            <div className="articles-content">                
                <ul className="articles-social mb-1">
                    <li>
                        <a href="!#">
                            <span>{`${item?.total_views_count}/${item?.unique_views_count}`}</span>
                            <ViewIcon />
                        </a>
                    </li>
                    <li>
                        <a href="!#">
                            <span>{item?.likes_count}</span>
                            <img src="/images/highhoof.svg" className="img-fluid" alt='' />
                        </a>
                    </li>
                </ul>
                <p>{item?.gallery_files_count} files</p>
                <ul className="articles-action">
                    
                    {!hideDelete ?
                        <li>
                            {!showRestore ?
                                <Deleted deleteHandler={deleteHandler} title={`Delete Gallery`} />
                            :
                                <a href="!#" onClick={restoreHandler} className="cu-pt main-btn-capsule">Restore</a>                               
                            }
                        </li> : null
                    }
                    
                    {!hideEdit ?
                        <li>
                            <Link to={`/dashboard/galleries/${item?.id}/edit`} className="main-btn-capsule">Edit</Link>
                        </li> : null
                    }
                </ul>
            </div>
        </div>
    )
}

export default GalleryCard