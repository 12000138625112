import React from "react";
import { getAdsPreviewImage } from "../../Common/AHImage";

const Advertisement = ({ item }) => {
    return (
        <div className="card-body">
            <div className="row">
                <div className="col-6">
                    <div className="card preview-card">
                        <img className="img-fluid card-img-top" src={getAdsPreviewImage(item?.featured_image)} alt="Card cap" />
                        <div className="card-body">
                            <p>Sponsored content</p>
                            <button type='button' className='main-btn w-50 text-capitalize'>
                                {item?.button_text ? item?.button_text : "Button text"} <i className="fa-sharp fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Advertisement;
