import {
    GET_All_STORIES, 
    GET_EDIT_STORIES, 
    POST_NEW_STORIES,
    UPDATE_STORIES,
    PINNED_STORIES
} from '../constants/actiontypes';
import StoriesService from "../services/stories.service";
import { ErrorHandler } from '../services/ErrorHandler';

export const getAllStories = (query) => {
    return dispatch => {
        return ErrorHandler(dispatch)(StoriesService.getAllStories(query))
        .then(data => {
            dispatch({
                type: GET_All_STORIES, 
                payload: { stories: data }
            });
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const getEditStories = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(StoriesService.getEditStories(id))
        .then(data => {
            dispatch({
                type: GET_EDIT_STORIES,
                payload: { stories: data },
            });
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const postNewStories = (stories) => {
    return dispatch => {
        return ErrorHandler(dispatch)(StoriesService.postNewStories(stories))
        .then(data => {
            dispatch({
                type: POST_NEW_STORIES,
                payload: { stories: data }
            });
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
            return Promise.reject(message);
        });
    }
}

export const updateStories = (id, stories) => {
    return dispatch => {
        return ErrorHandler(dispatch)(StoriesService.updateStories(id, stories))
        .then(data => {
            dispatch({
                type: UPDATE_STORIES,
                payload: { stories: data }
            });
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
            return Promise.reject(message);
        });
    }
}

export const deleteStory = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(StoriesService.deleteStory(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const pinnedStory = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(StoriesService.pinnedStory(id))
        .then(data => {
            dispatch({
                type: PINNED_STORIES,
                payload: { data }
            });
            return Promise.resolve(data);
        }).catch(error => {
            const message = (error.response.data.errors && error.response.data) || error.message || error.toString();
            return Promise.reject(message);
        });
    }
}
export const restoreStory = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(StoriesService.restoreStory(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}