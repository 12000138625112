import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ShimmerCircularImage, ShimmerCategoryItem, ShimmerTitle} from "react-shimmer-effects";
import UserImage from './User/UserImage';

const Profile = ({heading, pathurl, showbtn, page=""}) => {

   const currentUser = useSelector((state) => state.UsersReducer.current_user.data);

   let txt = heading !== "" ? heading : "Hello, "+currentUser?.first_name;

    return (
        <>
            <div className="admin-short-info">
                {currentUser ? 
                    <div className='page-title-wrapper'>
                        <span className='page-title'>
                            {txt}
                        </span>
                        {showbtn === "yes" &&
                            <Link to={`/dashboard/${pathurl}`} className="main-btn-capsule">New</Link>
                        } 
                    </div>
                    :
                    <ShimmerTitle line={1} gap={10} variant="primary" />
                }
            </div>
            <div className="admin-full-info">
                {currentUser ? (<div className="admin-text">
                    <h5>{currentUser?.first_name.capitalize()} {currentUser?.last_name.capitalize()}</h5>
                    <p>@{currentUser?.username}</p>
                    <Link to="/dashboard/admin-profile">{currentUser?.user_role}</Link>
                </div>) : (<ShimmerCategoryItem />)}
                
                <div className="admin-media">
                    <Link to="/dashboard/admin-profile">                                             
                    {currentUser ? 
                        <UserImage 
                            image_url={currentUser?.profile_picture}
                            role={currentUser?.user_role}
                        />
                    : <ShimmerCircularImage size={68} /> }
                    </Link>
                </div>
                {page !== "support" && (<div className="admin-support">
                    <Link to="/dashboard/support">
                        <button className="main-btn w-100" name="admin_support">Support <i className="fa-sharp fa-solid fa-arrow-right"></i></button>
                    </Link>
                </div>)}
            </div>
        </>
    );
};

export default Profile;