import React from 'react'

const TitleTextArea = ({value, onChange, maxLength, placeholder="Type your title here. Keep it short & sweet", error=""}) => {
  return (
    <div className={`title-textarea-wrapper ${error?.length > 0 ? 'validation-error': ''}`}>
        <textarea
            className="title-textarea"
            placeholder={placeholder}
            id="title"
            maxLength={maxLength}
            required
            value={value}
            onChange={onChange}
            name="title"
        ></textarea>

        <div className={`the-count ${value?.length > maxLength ? "text-danger" : ""}`}>
          <span className="current">{value?.length}</span>
          <span className="maximum">/ {maxLength}</span>
        </div>

        {error?.length > 0 && <p className='validation-error-text'>{error}</p>}
    </div>
  )
}

export default TitleTextArea