export const ErrorMessage = {
    Content: 'This content field is required',
    Title:'the title field is required',
    Max:'please enter max 60 characters',
    Invalid:'The given data is invalid',
    Phone:'Please enter a valid phone number',
    Category:'Please select at least one category',
    Published: 'User published successfully!',
    ExpiredDate: 'This ads has expired and is no longer valid. please change date',
    VideoMaxFileSize: 'File size is too be large upload max 100MB'
}