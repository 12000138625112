import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";

import { getFullDateTimeFormat } from '../../../helpers/CommonFunction'
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateTimelineDateMethod } from '../../../actions/EventAction';
import { useSelector } from 'react-redux';

const LiveFeedCardHeader = ({timeline, headingText, post_id, onDelete, HandleOnPressPin}) => {

    const dispatch = useDispatch();
    const currentEvent = useSelector((state) => state.EventReducer.event);

    const [isEditingDate, setEditingDate] = useState(false);
    const [tmpDate, setTmpDate] = useState(timeline?.created_at);
    const [isUpdating, setUpdating] = useState(false);

    const handleChangeCreatedDate = (changedValue) => {
        setTmpDate(moment(changedValue).format());
    }

    const HandleUpdateDate = () => {
        if(timeline?.created_at !== tmpDate){
            setUpdating(true)
            const payload = {created_at: tmpDate};
            dispatch(updateTimelineDateMethod(currentEvent?.id, timeline?.id, payload)).then(() => {
                setEditingDate(false)
                setUpdating(false)
            }).catch((err) => {
                console.error(err);
                setUpdating(false)
            })
        }
    }

    const HandleCancel = () => {
        setTmpDate(timeline?.created_at);
        setEditingDate(false)
    }

    return (
        <div className="card-header">
            <div className="left-wrapper">
                {!isEditingDate ?
                <div className="d-flex align-items-center">
                    <p className="m-0">{headingText} posted at {getFullDateTimeFormat(tmpDate)}</p>
                    <i className="fa fa-edit ms-3 cu-pt" onClick={() => setEditingDate(true)}></i>
                </div>
                :
                <div className="d-flex align-items-center">
                    <DatePicker
                        className="form-control"
                        placeholderText="Start Date"
                        dateFormat="MMMM d, yyyy hh:mm aa"
                        selected={new Date(tmpDate)}
                        onChange={(date) => handleChangeCreatedDate(date)}
                        selectsStart
                        showTimeSelect
                    />
                    {timeline?.created_at !== tmpDate && 
                        <button type='button' disabled={isUpdating} className='btn btn-circle btn-success ms-2' onClick={HandleUpdateDate}>                        
                            <i className="fa fa-check"></i>
                        </button>
                    }
                    
                    <button type='button' className='btn btn-circle bg-danger ms-2' onClick={HandleCancel}>
                        <i className="fa fa-times"></i>
                    </button>
                </div>
                }
            </div>
            <div className="right-wrapper d-flex align-items-center">
                <span>{timeline?.user?.name}</span>                
                <button className="btn p-0 ms-3" onClick={onDelete}>
                    <i className="fa fa-trash"></i>
                </button>

                <button className="btn p-0 ms-3" type="button" onClick={() => HandleOnPressPin(timeline?.id)}>
                    <img
                        src={timeline?.featured ? "/images/pinIcon-active.svg" : "/images/UnpinIcon.svg"}
                        style={{ width: '18px' }}
                        alt=''
                    />
                </button> 
                {post_id ? 
                <Link className="btn p-0 ms-3" to={`/dashboard/edit-posts/${post_id}`}>
                    <i className="fa fa-edit"></i>
                </Link>
                :null
                }               
            </div>
        </div>
    )
}

export default LiveFeedCardHeader