import React from 'react'

const AddGalleryEmptyBox = ({onChange}) => {
  return (
    <div className="col-lg-3 col-md-3">
      <div className="gallery-file empty-file">
        <label htmlFor="add-gallery-file">
          <i className="fa fa-3x fa-plus" style={{color: '#342922'}}></i>
        </label>
        <input type='file' accept='image/*, video/*' className="input-file" id="add-gallery-file" multiple onChange={onChange} />
      </div>
    </div>
  )
}

export default AddGalleryEmptyBox