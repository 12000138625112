import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import toast from 'react-hot-toast';
import moment from 'moment';

import { getUsers } from '../../actions/UsersAction';
import { getCategories } from '../../actions/CategoryAction';
import Profile from '../components/Profile';
import { ImageResizer } from "../Common/ImageResizer";
import ContentType from '../../constants/contentType';
import UnAuthorisedAccess from '../components/UnAuthorisedAccess';
import { getDateFormat } from '../../helpers/CommonFunction';
import BodyLoading from '../components/BodyLoading';
import { getPostMethod, updatePostMethod } from '../../actions/PostAction';
import { convertUrlToJpg } from '../Common/AHImage';
import { UploadFile } from '../../services/upload.service';
import TitleTextArea from '../components/TitleTextArea';

const EditPost = () => {
    let navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();    
    const categories = useSelector((state) => state.CategoryReducer.categories);

    const formRef = useRef(null);
	const sliderItemsRef = useRef([]);
    const sliderEmptyItemRef = useRef();

    const featuredImageResizeRef = useRef();

    const [post, setPost] = useState({users: []});
    const [editingCreatedDate, setEditingCreatedDate] = useState(false);
	const [taggedUsers, setTaggedUsers] = useState([]);

    const [hasAccess, setHasAccess] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [deletable, setDeletable] = useState([]);
    const [inputTimeout, setInputTimeout] = useState(null);
    const [refContainer, setRefContainer] = useState({
        featureImageRef: useRef(),
        container: {},
        current: null
    })

    const EditPostHandle = (e) => {
		const { name, value } = e.target;
		setPost(post => ({
			...post,
			[name]: value
		}))
    }

    const PostSubmitHandle = (e) => {
        e.preventDefault();
        setLoading(true);
        post.deletable = deletable;
		post.users = taggedUsers?.map((u) => u?.value);
        dispatch(updatePostMethod(id, post))
            .then((res) => {
				toast.success(res.message);
				setLoading(false);
				setEditingCreatedDate(false)                
            }).catch(err => {
				console.log(err);
                setLoading(false);
            })
    }

    useEffect(() => {
        setIsLoading(true);
        dispatch(getPostMethod(id))
            .then(response => {
                setIsLoading(false);
                setPost(response.data);

				if(response.data?.users?.length){
					const tmpUsers = response.data?.users?.map((u) => {
						return {label: u?.name + ` (@${u?.username})`, value: u?.id}
					})
					setTaggedUsers(tmpUsers)
				}
				if(response.data?.slider){
					sliderItemsRef.current = response.data?.slider.map((element, i) => sliderItemsRef.current[i] ?? React.createRef());
				}
            }).catch(err => {
                setIsLoading(false);
                if (err?.response?.status === 403) {
                    setHasAccess(false)
                } else {
                    setHasAccess(true)
                }
            });

        if (!categories?.length) {
            dispatch(getCategories());
        }
    }, []);

    useEffect(() => {
        fetchUsers()
    }, []);

    const handleChangeCreatedDate = (changedValue) => {
        setPost(prev => {
            return { ...prev, created_at: moment(changedValue).format() }
        });
    }

    const fetchUsers = (search) => {
        const searchParams = new URLSearchParams();
        if (search) {
            searchParams.set('search', search);
        }
        
        let query = '?page=' + 1;
        if(searchParams?.length){
            query += "&" + searchParams.toString();
        }
        
        dispatch(getUsers(query))
            .then(res => {
                if (res.status) {
                    const data = res.data.data;
                    const options = data?.map(user => ({
                        "value": user.id,
                        "label": `${user.name} (@${user.username})`
                    }));
                    setSelectedOption(options);
                }
            }).catch(err => {
                console.error(err);
            });
    }

    const handleSearchTaggedUsers = value => {
        let lowercasedValue = value?.toLowerCase();
        if (inputTimeout) clearTimeout(inputTimeout)
        setInputTimeout(
            setTimeout(() => {
                if (lowercasedValue !== '') {
                    fetchUsers(lowercasedValue);
                } else {
                    fetchUsers(lowercasedValue);
                }
            }, 1000)
        );
    };

	

	const HandleChangeFile = async (e, index, media_type) => {
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {           
            
        }
		reader.readAsDataURL(file);
		const data = await fetch(URL.createObjectURL(file));
		const blob = await data.blob();

		const file_name = media_type === "image" ? media_type+".jpg" : media_type+".mp4";

		UploadFile(new File([blob], file_name), ({ loaded, total })  => {
			console.log();
            sliderItemsRef.current[index].current.style.width = `${(loaded/total) * 100}%`;
        }, "forums").then(resp => {
            return resp.data
        }).then((res) => {
			let tmpPost = {...post}
			tmpPost.slider[index].url = res?.secure_url;
			tmpPost.slider[index].media_type = res?.resource_type;
			setPost(tmpPost)

			setTimeout(() => {
				sliderItemsRef.current[index].current.style.width = `0%`;
			}, 500);
		})
	}

    const HandleRemoveFile = (index, id) => {        
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to remove this media?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                let tmpPost = {...post};
                tmpPost.slider.splice(index, 1)
                setPost(tmpPost)
                if(id){
                    setDeletable([...deletable, id])
                }
            }
        });
    }

    const HandleAddMoreFile = async (e) => {
        const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {           
            
        }
		reader.readAsDataURL(file);
		const data = await fetch(URL.createObjectURL(file));
		const blob = await data.blob();

        console.log(blob);

		const file_name = blob.type.includes("image") ? "image.jpg" : "video.mp4";

		UploadFile(new File([blob], file_name), ({ loaded, total })  => {
			console.log();
            sliderEmptyItemRef.current.style.width = `${(loaded/total) * 100}%`;
        }, "forums").then(resp => {
            return resp.data
        }).then((res) => {
			let tmpPost = {...post}

            const newItem = {
                url : res?.secure_url,
                media_type : res?.resource_type,
                id : 0,
                caption : ""
            }

            tmpPost.slider.push(newItem);

			setPost(tmpPost)

			setTimeout(() => {
				sliderEmptyItemRef.current.style.width = `0%`;
			}, 500);
		})
    }

    const handleSelectTaggedUsers = (items) => {
      	setTaggedUsers(items)
  	}

	const renderSliderItems = post?.slider?.map((item, index) => (
		<div className="col-lg-3 col-md-3 post-slider-item" key={`post_${post?.id}_${item?.id}`}>
			<div className="progress" style={{"height": "4px"}}>
				<div ref={sliderItemsRef.current[index]}
					className="progress-bar"
					role="progressbar"
					style={{"width": "0%"}}
					aria-valuenow="25"
					aria-valuemin="0"
					aria-valuemax="100">
				</div>
			</div>
			{item.media_type === ContentType.VIDEO ? 
				<video
					src={item?.url}
					alt={item?.title}
					className="img-fluid"
					type="video/mp4"
					loop
					controls
				/>
			:
			<img src={convertUrlToJpg(item?.url)} alt={item?.title} className="post-image img-fluid" />
			}

			<div className="icon-edit">
				<input type="file" onChange={(e) => HandleChangeFile(e, index, item.media_type)} className="edit-post-input-file" accept={item.media_type+'/*'} />
				<img src="/images/icon-edit.svg" className="img-fluid action-icon" alt='' />
			</div>

            <div className="icon-delete" onClick={() => HandleRemoveFile(index, item?.id)}>
				<img src="/images/delete-icon.svg" className="img-fluid action-icon" alt='' />
			</div>
			
		</div>
	));


    if (!hasAccess) {
        return <UnAuthorisedAccess heading={"Post"} />
    }

    if (!post?.id) {
        return <BodyLoading heading={"Post"} />
    }

    return (
        <>
            <div className="admin-dashboard users-panel">
                <header>
                    <Profile heading="Edit Post"  />
                    <div className="article-action-panel">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="action-box pe-0">
                                    <div className="back-to-user">
                                        <button type="button" className="btn" onClick={() => navigate(-1)}>
                                            <img src="/images/Refund_back.svg" className="img-fluid" alt=' ' />
                                        </button>
                                    </div>
                                    <div className="social-info">
                                        <ul className="d-flex align-items-center">
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 text-end">
								<button type='button' onClick={PostSubmitHandle} className="main-btn" name="user_save_data" disabled={loading}>
									Save Changes {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="fa-sharp fa-solid fa-arrow-right"></i>}
								</button>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="admin-dashboard-panel">
                    <div className="edit-forum-section">
                        <div className="inner-edit-forum inner-chat">
                            <form className="edit-forum" method={`post`} onSubmit={PostSubmitHandle} ref={formRef}>
                                <div className="row g-0">
                                    <div className="col-lg-6 col-md-6 col-12 outer-form-left">  
                                        <TitleTextArea
                                            onChange={EditPostHandle}
                                            value={post?.title}
                                            placeholder='Post text'
                                        />
                                    </div>
                                    <div className="col-lg-5 col-md-5 offset-md-1 col-12">
                                        <div className="right-edit-forum">
											<div className="form-group">
												<label>Tag users</label>
												<Select
													value={taggedUsers}
													placeholder="Tag profiles"
													onInputChange={handleSearchTaggedUsers}
													onChange={handleSelectTaggedUsers}
													isMulti
													isSearchable
													options={selectedOption}
													isLoading={isLoading}
												/>
											</div>
											<div className='forum-group d-flex align-items-center'>
												<label>Posted at: </label>
												<div className="ms-3">
													{!editingCreatedDate ?
														<>
															<span>{getDateFormat(post?.created_at)}</span>
															<i onClick={() => setEditingCreatedDate(true)} className="cu-pt ms-2 fa fa-edit"></i>
														</>
													:
														<div className="d-flex align-items-center">
															<DatePicker
																className="form-control border-0"
																placeholderText="Start Date"
																dateFormat="MMMM d, yyyy hh:mm aa"
																selected={new Date(post?.created_at)}
																onChange={(date) => handleChangeCreatedDate(date)}
																selectsStart
																showTimeSelect
															/>
															<i onClick={() => setEditingCreatedDate(false)} className="cu-pt ms-2 fa fa-times"></i>
														</div>
													}
												</div>
											</div>
                                        </div>
                                    </div>
                                </div>

								{post?.slider?.length > 0 && 
									<div className="row">
										{renderSliderItems}

                                        {post?.slider?.length < 10 &&                                        
                                            <div className="col-lg-3 col-md-3 post-slider-empty-item">
                                                <div className="progress" style={{"height": "4px"}}>
                                                    <div ref={sliderEmptyItemRef}
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{"width": "0%"}}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100">
                                                    </div>
                                                </div>
                                                <div className="block">
                                                    <button type="button" className="main-btn btn-circle add-button">
                                                        +
                                                    </button>
                                                    <div className="icon-edit cu-pt">
                                                        <input type="file" onChange={HandleAddMoreFile} className="edit-post-input-file" accept="image/*, video/*" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
									</div>
								}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ImageResizer
                ref={featuredImageResizeRef}
                uploadedImageCallback={(data) => {
                    setPost(prev => {
                        let contents = [...prev.content]
                        if (contents.length > 0) {
                            contents[refContainer.current.index] = {
                                ...contents[refContainer.current.index],
                                content: data?.url
                            }
                        } else {
                            contents[refContainer.current.index] = {
                                media_type: data.resource_type,
                                content: data?.url,
                                tag: data.resource_type,
                                content_index: refContainer.current.index
                            }
                        }
                        return { ...prev, content: contents }
                    });
                }}
                resetState={() => {
                    refContainer.current.ref.value = null
                }}
                IMAGE_RATION={0}
                folder="forums"
            />
        </>
    )
}

export default EditPost;