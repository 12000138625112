import React from "react";
import ArticleCard from "../Article/ArticleCard";

const Article = ({ article}) => {
  return (
      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-12 articles-panel">
            <ArticleCard article={article} hidePin={true} hideDelete={true} />
          </div>
        </div>
      </div>
  );
};

export default Article;
