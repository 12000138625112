import api from "./api";

export const getMagazines = async (query) => {
    const url = "magazines" + query
    return await api.get(url);
};

export const getMagazineDetails = async (id) => {
    const url = "magazines/" + id
    return await api.get(url);
};

export const storeMagazine = async (payload) => {
    const url = "magazines"
    return await api.post(url, payload);
};

export const updateMagazine = async (id, payload) => {
    const url = "magazines/" + id
    return await api.put(url, payload);
};

export const deleteMagazine = async (id) => {
    const url = "magazines/" + id
    return await api.delete(url);
};

export const restoreMagazine = async (id) => {
    const url = "magazines/" + id + "/restore"
    return await api.get(url);
};

/************* Magazine files *************/

export const getMagazineFiles = async (id, query) => {
    const url = "magazines/" + id + "/files" + query;
    return await api.get(url);
};

export const storeMagazineFile = async (payload) => {
    const url = "magazine-files"
    return await api.post(url, payload);
};

export const getMagazineFileDetails = async (id) => {
    const url = "magazine-files/" + id
    return await api.get(url);
};

export const updateMagazineFile = async (id, payload) => {
    const url = "magazine-files/" + id
    return await api.put(url, payload);
};

export const deleteMagazineFile = async (id) => {
    const url = "magazine-files/" + id
    return await api.delete(url);
}

export const restoreMagazineFile = async (id) => {
    const url = "magazine-files/" + id + "/restore"
    return await api.get(url);
};