import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import DatePicker from "react-datepicker";
import moment from 'moment';
import Select from 'react-select';
import { getUsers } from '../../actions/UsersAction';
import { getCategories } from '../../actions/CategoryAction';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import Profile from '../components/Profile';
import ArenaField from '../components/ArenaField';
import { ArrowDown } from '../components/Icon';
import { ColorPicker } from '../components/Icon';
import { updateEventsMethod, showEventsMethod } from '../../actions/EventAction';
import { NewArenaObject } from '../../constants/Constants';
import ToggleCheckbox from '../components/ToggleCheckbox';
import { Role } from '../../constants/roles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function EditEvent() {
    const { id } = useParams();

    const dispatch = useDispatch();
    const categories = useSelector((state) => state.CategoryReducer.categories);
    const currentUser = useSelector((state) => state.UsersReducer.current_user.data);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const [userLoading, setUserLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [inputTimeout, setInputTimeout] = useState(null);
    const [eventErrors, setEventErrors] = useState(null);
    const [event, setEvent] = useState({arenas : []});
    const [selectedInfluencers, setSelectedInfluencers] = useState([]);
    const [showInfluencers, setShowInfluencers] = useState([]);
    const [deletable_arenas, setDeletableArenas] = useState([]);

    // Get users List Data from api
    const getAllUsers = (search) => {
        setUserLoading(true);
        const searchParams = new URLSearchParams();
        if (search) {
            searchParams.set('search', search);
        }
        let query = '?page=' + 1 + "&" + searchParams.toString();
        dispatch(getUsers(query))
            .then(res => {
                if (res.status) {
                    setUserLoading(false);
                    const data = res.data.data;
                    const options = data?.map(user => ({
                        "value": user.id,
                        "label": user.first_name + " " + user.last_name
                    }));
                    setSelectedOption(options);
                }
            }).catch(err => {
                setUserLoading(false);
                console.error(err);
            });
    }

    // lifeCycle Methods
    useEffect(() => {
        if (!searchQuery) {
            getAllUsers();
        }
    }, []);

    useEffect(() => {
        if (!categories?.length) {
            dispatch(getCategories()).catch((err) => {
                console.log(err)
            })
        }
    }, []);

    useEffect(() => {
        dispatch(showEventsMethod(id))
            .then((res) => {
                setShowInfluencers(res?.data?.influencers);
                const data = res?.data;
                const influencersIds = data?.influencers?.map(influencer => influencer.id);
                data.influencers = influencersIds;
                setEvent(data);
            }).catch((err) => {
                console.log(err);
            });
    }, [id])

    // Change input value here for title
    const onchangeHandle = (e) => {
        const { name, value } = e.target
        setEvent(event => ({ ...event, [name]: value }))
        setEventErrors(pre => ({ ...pre, title: "", category_id: "", primary_color: "" }));
    }

    const handleLiveStatus = (e) => {
        const { checked } = e.target;
        setEvent(event => ({
            ...event,
            live: checked,
        }));
    }

    const handleLiveForTestStatus = (e) => {
        const { checked } = e.target;
        setEvent(event => ({
            ...event,
            live_for_test: checked,
        }));
    }

    const handlePushNotificationStatus = (e) => {
        const { checked } = e.target;
        setEvent(event => ({
            ...event,
            mute_push_notification: checked,
        }));
    }

    const handleChangeStartDate = (date) => {
        setEvent((event) => ({
            ...event,
            start_date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
        }))
        setEventErrors(eventErrors => ({
            ...eventErrors,
            start_date: null
        }))
    }
    const handleChangeEndDate = (date) => {
        setEvent((event) => ({
            ...event,
            end_date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
        }))
        setEventErrors(eventErrors => ({
            ...eventErrors,
            end_date: null
        }))
    }

    // Select Category from dropdown
    const handleArenaNameChanged = (index, e) => {
        const { name, value } = e.target;
        const tmpArenas = [...event.arenas];
        tmpArenas[index][name] = value;
        setEvent((event) => ({
            ...event,
            arenas: tmpArenas
        }));
    }

    const handleUserSelect = (user) => {
        const Influencers = user?.map(item => item.value);
        setSelectedInfluencers(Influencers);
        setEventErrors(eventErrors => ({
            ...eventErrors,
            influencers: []
        }))
    }

    const handleInputChange = value => {
        let lowercasedValue = value.toLowerCase();
        setSearchQuery(lowercasedValue);
        if (inputTimeout) clearTimeout(inputTimeout)
        setInputTimeout(
            setTimeout(() => {
                if (lowercasedValue) {
                    getAllUsers(lowercasedValue);
                }
            }, 1000)
        );
    };

    const handleUpdateEvent = (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (selectedInfluencers?.length > 0) {
            event.influencers = [...new Set([...selectedInfluencers, ...event.influencers])];
        }

        if (deletable_arenas?.length > 0) {
            event.deletable_arenas = deletable_arenas;
        }

        dispatch(updateEventsMethod(id, event))
            .then((res) => {
                setIsLoading(false);
                toast.success(res.message);
            }).catch((err) => {
                setIsLoading(false);
                setEventErrors(err.errors)
                toast.error(err.message);
            });
    }

    const addMoreArenas = (e) => {
        e.preventDefault();
        const tmpArenas = [...event.arenas];
        tmpArenas.push({ ...NewArenaObject })
        setEvent((event) => ({
            ...event,
            arenas: tmpArenas
        }));
    }

    const HandleRemoveArena = (index) => {
        const tmpArenas = [...event.arenas];

        if (tmpArenas[index]?.id !== 0) {
            const tmpDeletableArenas = [...deletable_arenas];
            tmpDeletableArenas.push(tmpArenas[index]?.id);
            setDeletableArenas(tmpDeletableArenas)
        }

        const remainingArenas = tmpArenas.filter((s, i) => (i !== index))
        setEvent((event) => ({
            ...event,
            arenas: remainingArenas
        }));
    }

    const removeInfluencers = (influencers) => {
        setShowInfluencers(showInfluencers?.filter(item => item?.id !== influencers?.id));
        setEvent((event) => ({
            ...event,
            influencers: event?.influencers?.filter(item => item !== influencers?.id)
        }));
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from([...event.arenas]);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setEvent((event) => ({
            ...event,
            arenas: items
        }));
    }

    const renderArenas = event.arenas?.map((item, index) => {
        return (
            <Draggable disableInteractiveElementBlocking={false} key={`arena_${index}`} draggableId={`arena_${index}`} index={index}>
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                <ArenaField                   
                    key={'arena_' + index}
                    onChange={handleArenaNameChanged}
                    item={item}
                    index={index}
                    arenas={event?.arenas}
                    removeArena={HandleRemoveArena}
                />
                </div>
            )}
            </Draggable>
        )
    });

    return (
        <>
            <div className="admin-dashboard users-panel edit-event-panel">
                <header>
                    <Profile heading={`Event: ${event?.title ?? ''}`} pathurl="new-event" showbtn="no" />
                    <div className="user-action-panel">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="action-box pe-3">
                                    <div className="back-to-user">
                                        <Link to="/dashboard/events">
                                            <img src="/images/Refund_back.svg" className="img-fluid" alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="admin-dashboard-panel">
                    <div className="user-personal-panel">
                        <form>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="personal-form event-personal-form">
                                        <div className="row">
                                            <div className="col-lg-11 col-md-11 col-12 mt-3">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        className="action-control"
                                                        value={event?.title || ''}
                                                        placeholder="Event Name"
                                                        onChange={(e) => onchangeHandle(e)}
                                                    />
                                                    {eventErrors?.title ? (<p className='text-danger'>{eventErrors?.title[0]}</p>) : null}
                                                    <DatePicker
                                                        placeholderText="Start Date"
                                                        dateFormat="dd-MM-yyyy hh:mm aa"
                                                        selected={new Date(moment(event?.start_date).format("YYYY-MM-DD HH:mm:ss"))}
                                                        onChange={(date) => handleChangeStartDate(date)}
                                                        selectsStart
                                                        showTimeSelect
                                                        startDate={new Date(moment(event?.start_date).format("YYYY-MM-DD hh:mm:ss"))}
                                                        endDate={new Date(moment(event?.end_date).format("YYYY-MM-DD hh:mm:ss"))}
                                                    />
                                                    {eventErrors?.start_date ? (<p className='text-danger'>{eventErrors?.start_date[0]}</p>) : null}
                                                    <DatePicker
                                                        placeholderText="End Date"
                                                        dateFormat="dd-MM-yyyy hh:mm aa"
                                                        selected={new Date(moment(event?.end_date).format("YYYY-MM-DD HH:mm:ss"))}
                                                        onChange={(date) => handleChangeEndDate(date)}
                                                        selectsEnd
                                                        showTimeSelect
                                                        startDate={new Date(moment(event?.start_date).format("YYYY-MM-DD hh:mm:ss"))}
                                                        endDate={new Date(moment(event?.end_date).format("YYYY-MM-DD hh:mm:ss"))}
                                                        minDate={new Date(moment(event?.start_date).format("YYYY-MM-DD hh:mm:ss"))}
                                                    />
                                                    {eventErrors?.end_date ? (<p className='text-danger'>{eventErrors?.end_date[0]}</p>) : null}
                                                    <div className="user-types">
                                                        <select name="category_id" value={event?.category_id} className="action-control" onChange={(e) => onchangeHandle(e)} >
                                                            <option value="">Choose a Category</option>
                                                            {categories?.map((category, index) => {
                                                                return (
                                                                    <option key={index} value={category?.id}>{category?.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <ArrowDown />
                                                    </div>
                                                    {eventErrors?.category_id ? (<p className='text-danger'>{eventErrors?.category_id[0]}</p>) : null}

                                                    {currentUser?.user_role === Role.Admin ? 
                                                        <>
                                                            <Select
                                                                placeholder="Coupled profiles"
                                                                onInputChange={handleInputChange}
                                                                onChange={handleUserSelect}
                                                                isMulti
                                                                isSearchable
                                                                options={selectedOption}
                                                                isLoading={isLoading}
                                                            />
                                                            <ul className='influencers-list'>
                                                                {showInfluencers?.length > 0 && showInfluencers?.map((item, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            <span className="badge badge-secondary"> {item?.first_name} {item?.last_name}
                                                                                <button type="button" onClick={() => removeInfluencers(item)} className='btn remove-influencers'>
                                                                                    <i className="fa-solid fa-xmark"></i>
                                                                                </button>
                                                                            </span>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </>
                                                        : null
                                                    }

                                                    {eventErrors?.influencers ? (<p className='text-danger'>{eventErrors?.influencers[0]}</p>) : null}
                                                </div>
                                            </div>
                                            <div className="pe-0 col-lg-6 col-md-6 col-6">
                                                <div className="form-group">
                                                    <p>Choose event primary colour</p>
                                                    <div className="event-color-picker user-media user-edit-profile d-flex justify-content-center align-items-center" style={{ backgroundColor: event?.primary_color }}>
                                                        <ColorPicker />
                                                        <input type="color" name='primary_color' onChange={(e) => onchangeHandle(e)} className="action-control h-75" title="Color Picker" />
                                                        <span><img src="/images/icon-edit.svg" className="img-fluid" alt="" /></span>
                                                    </div>
                                                    {eventErrors?.arenas ? (<p className='text-danger'>{eventErrors?.arenas[0]}</p>) : null}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="form-group">
                                                    <p>Choose event secondary colour</p>
                                                    <div className="event-color-picker user-media user-edit-profile d-flex justify-content-center align-items-center" style={{ backgroundColor: event?.secondary_color }}>
                                                        <ColorPicker />
                                                        <input type="color" name='secondary_color' onChange={(e) => onchangeHandle(e)} className="action-control h-75" title="Color Picker" />
                                                        <span><img src="/images/icon-edit.svg" className="img-fluid" alt="" /></span>
                                                    </div>
                                                    {eventErrors?.arenas ? (<p className='text-danger'>{eventErrors?.arenas[0]}</p>) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="user-listing-panel event-live-section">
                                        <div className="user-other-info">
                                            <div className="form-group">
                                                <ul>
                                                    <li>
                                                        <ToggleCheckbox
                                                            isActive={event?.live}
                                                            onChange={handleLiveStatus}
                                                            label={`Live`}
                                                        />
                                                    </li>
                                                    <li>
                                                        <ToggleCheckbox
                                                            isActive={event?.live_for_test}
                                                            onChange={handleLiveForTestStatus}
                                                            label={`Live (Admin + Influencers)`}
                                                        />
                                                    </li>
                                                    <li>
                                                        <ToggleCheckbox
                                                            isActive={event?.mute_push_notification}
                                                            onChange={handlePushNotificationStatus}
                                                            label={`Mute Push Notifications`}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="personal-form">
                                                <div className="form-group">
                                                    <label className="pb-1 arenai-label" htmlFor="arenas">Arena's</label>

                                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                                        <Droppable droppableId="characters">
                                                        {(provided) => (
                                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                            {renderArenas}

                                                            {provided.placeholder}
                                                        </div>
                                                        )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div>
                                            </div>
                                            <div className="btn-arenas-add" onClick={(e) => addMoreArenas(e)}>
                                                <img src="/images/PlusSignIcon.svg" className="img-fluid w-75" alt='' />
                                            </div>
                                        </div>
                                        <div className="col-12 text-end">
                                            <button type='submit' className="main-btn" name="user_save_data" disabled={isLoading} onClick={(e) => handleUpdateEvent(e)}>
                                                Save event  {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="fa-sharp fa-solid fa-arrow-right"></i>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditEvent
