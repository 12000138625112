import api from "./api";

const getCategories = async (query = "") => {
    let url = "categories";
    if(query.length){
        url += query
    }
    return await api.get(url)
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    });
};

const setCategories = async (categories) => {
    return await api.post("categories", categories)
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    });
};

const deleteCategory = async (id) => {
    return await api.delete("categories/"+id)
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    });
};

export default {
    getCategories,
    setCategories,
    deleteCategory
};
