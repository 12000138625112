import api from "./api";

const getAllStories = async (query) => {
    const url = "stories"+query;
    return await api.get(url)
        .then((response) => {
            if (response.status === 200) {
                return response.data.data;
            }
        });
};

const getEditStories = async (id) => {
    return await api.get(`stories/${id}`)
    .then((response) => {
        if (response.status === 200) {
            return response.data.data;
        }
    });
};

const postNewStories = async (stories) => {
    return await api.post("stories", stories)
    .then((response) => {
        if (response.data.status) {
            return response.data
        }
    })
}

const updateStories = async (id, stories) => {
    return await api.post(`stories/${id}`, stories)
    .then((response) => {
        if (response.status === 200) {
            return response.data
        }
    })
}

const deleteStory = async (id) => {
    return await api.delete("stories/"+id)
    .then((response) => {
        if (response.status === 200) {
            return response.data.data
        }
    })
}

const pinnedStory = async (id) => {
    const url = `stories/${id}/toggle-pinned`;
    return await api.get(url)
    .then((response) => {
        if (response.status === 200) {
            return response.data
        }
    });
}

const restoreStory = async (id) => {
    return await api.get(`stories/${id}/restore`)
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    });
};

export default {
    getAllStories,
    getEditStories,
    postNewStories,
    updateStories,
    deleteStory,
    pinnedStory,
    restoreStory
};


