import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { ShimmerCategoryList } from "react-shimmer-effects";

import AHPagination from '../components/AHPagination';
import Profile from '../components/Profile';
import SearchComponent from '../components/General/SearchComponent';
import { Link } from 'react-router-dom';
import MagazineCard from '../components/Magazine/MagazineCard';
import { MagazineFilters } from '../components/Filters';
import { getMagazinesMethod } from '../../actions/MagazineAction';

const Magazines = () => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [queryString, setQueryString] = useState("");
    const [pagination, setPagination] = useState({data: [], title: ''});

    const getMagazines = (search) => {
        setIsLoading(true);
        const searchParams = new URLSearchParams();
        const { status, role, sort } = queryString
        if (status) {
            let filterColumn = ""
            let filterValue = ""
            if (status === "Active" || status === "Inactive") {
                filterColumn = "active";
                filterValue = status === "Active" ? 1 : 0;
            }
            if (status === "Pinned") {
                filterColumn = "pinned";
                filterValue = 1;
            }
            if (status === "Featured") {
                filterColumn = "featured";
                filterValue = 1;
            }
            if (status === "Trashed") {
                filterColumn = "status";
                filterValue = 'trashed';
            }
            searchParams.set('filter_column', filterColumn);
            searchParams.set('filter_value', filterValue);
        }
        if (role) {
            searchParams.set('role', role);
        }
        if (sort) {
            searchParams.set('sort_by', sort);
            searchParams.set('sort_order', "desc");
        }
        if (search) {
            searchParams.set('search', search.trim());
        }

        let query = '?page=' + page + "&" + searchParams.toString();
        dispatch(getMagazinesMethod(query))
            .then(res => {
                setPagination(res)
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
            });
    }

    useEffect(() => {
        getMagazines()
    }, [page, queryString]);

    const handleFilters = (e) => {
        const { name, value } = e.target;
        setPage(1)
        setQueryString((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    return (
        <div className="admin-dashboard users-panel magazines-page">
            <header>
                <Profile heading="Magazines" pathurl="magazines/create" showbtn="no" />
                <div className="user-filter-panel">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="filter-form">
                                <form>
                                    <div className='form-group'>
                                        <Link className='main-btn' to={`/dashboard/magazines/create`}>
                                            <i className="fa fa-plus"></i> Add New
                                        </Link>
                                    </div>
                                    <MagazineFilters onChange={handleFilters} />
                                </form>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-4 col-12 ps-0">
                            <SearchComponent isLoading={isLoading} onChange={getMagazines} />
                        </div>
                    </div>
                </div>
            </header>

            <div className="admin-dashboard-panel">
                <div className="articles-panel">
                    {!isLoading ? pagination?.data?.map((item, index) => {
                        return (
                            <MagazineCard
                                item={item}
                                key={`magazine_${item.id}`}
                                getList={getMagazines}
                                showRestore={queryString?.status === 'Trashed'}
                                hideEdit={queryString?.status === 'Trashed'}
                            />
                        )
                    }) : (<ShimmerCategoryList items={5} categoryStyle="STYLE_FIVE" />)}
                    {pagination.data?.length > 0 ? null : (<span>No data available.</span>)}
                    
                    <div className="row">
                        <div className="col-lg-10 col-md-10 col-12">
                            { pagination?.last_page !== 1 && <AHPagination obj={pagination} setPage={setPage} /> }
                        </div>
                        <div className="col-2 d-flex justify-content-end align-items-center">
                            <p className="font-weight-bold">
                                Total : {pagination?.total}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Magazines