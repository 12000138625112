
import { placeholders } from "../../../constants/placeholders";
import { Role } from "../../../constants/roles";
import {getThumbnail} from "../../../views/Common/AHImage";

const UserImage = ({image_url, role, title}) => {
    let className = "";

    if(role === Role.Admin){
        className = "orange-border";
    }else if(role === Role.Editor){
        className = "brown-border";
    }else if(role === Role.Expert){
        className = "blue-border";
    }else if(role === Role.Advertiser){
        className = "purple-border";
    }else if(role === Role.Sponsor){
        className = "purple-border";
    }

    return (
        <div className={`user-image-wrapper ${className}`}>
            {image_url ? <img 
                src={getThumbnail(image_url)} 
                onError={(e) => { e.target.onerror = null; e.target.src = placeholders.One }}
                className={`user-image`}
                alt={title}
            /> 
           : <img 
                src={placeholders.One}
                className={`user-image`}
                alt={title}
            />}
        </div>
    )
}

export default UserImage