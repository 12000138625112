import React from 'react'
import { VerifiedIcon } from '../Icon'
import { Link } from 'react-router-dom'
import { getFullDateTimeFormat } from '../../../helpers/CommonFunction'
import UserImage from './UserImage'

function UserCard({user, deleteUser, restoreUser, selectedFilter = "users", onSelect}) {
    return (
        <li className="nav-panel-item align-items-center" key={`user_${user?.id}`}>
            <div className="user-media">
                <UserImage
                    image_url={user?.profile_picture}
                    role={user?.user_role}
                />
                <div className="user-info">
                    <h5>
                        {user?.first_name} {user?.last_name}{" "}
                        {user?.email_verified ? <VerifiedIcon /> : null}
                    </h5>
                    <p>@{user?.username}</p>
                    <a>{user?.user_role}</a>
                </div>
            </div>
            <div className="social-info">
                <ul className="d-flex align-items-center">
                    <li className="mx-2 align-items-center d-flex">
                        <img
                            src="/images/icon-play.svg"
                            className="img-fluid mx-2"
                            alt=""
                        />
                        <span>{user.stories_count}</span>
                    </li>
                    <li className="mx-2 align-items-center d-flex">
                        <img
                            src="/images/icon-comment.svg"
                            className="img-fluid mx-2"
                            alt=""
                        />
                        <span>{user.forums_count}</span>
                    </li>
                    <li className="mx-2 align-items-center d-flex">
                        <img
                            src="/images/icon-list.svg"
                            className="img-fluid mx-2"
                            alt=""
                        />
                        <span>{user.articles_count}</span>
                    </li>
                </ul>
            </div>
            <div>
                <div className="user-action">
                
                    {selectedFilter === 'users' && 
                        <div onClick={(e) => deleteUser(user?.id)}>
                            <img
                                src="/images/delete-icon.svg"
                                className="img-fluid"
                                alt=""
                            />
                        </div>
                    }

                    {selectedFilter === 'users' &&
                        <Link to={`/dashboard/edit-user/${user?.id}`} className="main-btn-capsule">
                            Edit
                        </Link>
                    }

                    {selectedFilter === 'trashed' &&
                        <div
                            onClick={() => restoreUser(user?.id)}
                            className="main-btn-capsule"
                        >
                            Restore
                        </div>
                    }

                    {onSelect ?
                        <button onClick={onSelect}type="button" className="main-btn-capsule">
                            Select
                        </button>
                        :
                        null
                    }

                </div>
                {user?.last_seen &&
                    <p className="last-seen-text">Last Seen: {getFullDateTimeFormat(user?.last_seen)}</p>
                }
            </div>
        </li>
    )
}

export default UserCard