import {LOGOUT} from "../constants/actiontypes";

export const ErrorHandler = (dispatch) => (promise) => {
    return promise.catch(async (error) => {
        if (error?.response?.status === 401) {
            dispatch({type:LOGOUT})
            return;
        }
        return Promise.reject(error)
    })
}