import React from 'react'
import Pagination from "react-js-pagination";

function AHPagination({obj, setPage}) {
  return (
    <Pagination
        activePage={obj?.current_page}
        totalItemsCount={obj?.total ?? 0}
        itemsCountPerPage={obj?.per_page}
        onChange={setPage}
        itemClass="page-item"
        linkClass="page-link"
        itemClassFirst="first"
        itemClassLast="last"
        itemClassPrev="prev"
        itemClassNext="next"
        firstPageText="Frist"
        lastPageText="Last"
        prevPageText="Previous"
        nextPageText="Next"
        hideFirstLastPages={true}
    />
  )
}

export default AHPagination