import api from "./api";

export const getGalleries = async (query) => {
    const url = "galleries" + query
    return await api.get(url);
};

export const getGalleryDetails = async (id) => {
    const url = "galleries/" + id
    return await api.get(url);
};

export const storeGallery = async (payload) => {
    const url = "galleries"
    return await api.post(url, payload);
};

export const updateGallery = async (id, payload) => {
    const url = "galleries/" + id
    return await api.put(url, payload);
};

export const deleteGallery = async (id) => {
    const url = "galleries/" + id
    return await api.delete(url);
};

export const restoreGallery = async (id) => {
    const url = "galleries/" + id + "/restore"
    return await api.get(url);
};