
import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UsersReducer from "./UsersReducer";
import StoriesReducer from "./StoriesReducer";
import ArticlesReducer from "./ArticlesReducer";
import CategoryReducer from "./CategoryReducer";
import ForumsReducer from "./ForumReducer";
import AdsReducer from "./AdsReducer";
import RequestReducer from "./RequestReducer";
import EventReducer from "./EventReducer";
import DashboardReducer from "./DashboardReducer";

export default combineReducers({
    LoginReducer,
    UsersReducer,
    StoriesReducer,
    ArticlesReducer,
    CategoryReducer,
    ForumsReducer,
    AdsReducer,
    RequestReducer,
    EventReducer,
    DashboardReducer
});