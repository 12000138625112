import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

import Profile from '../components/Profile';
import { ImageResizer } from "../Common/ImageResizer";
import { MAGAZINE_IMAGE_RATIO } from "../../constants/imageRatio";
import { ErrorMessage } from '../../constants/ErrorMessage';
import ToggleCheckbox from '../components/ToggleCheckbox';
import ButtonComponent from '../components/General/ButtonComponent';
import { CloudinaryFolders } from '../../constants/Constants';
import SelectUserComponent from '../components/SelectUserComponent';
import TitleTextArea from '../components/TitleTextArea';
import FeaturedImageComponent from '../components/FeaturedImageComponent';
import { getMagazineDetailsMethod, storeMagazineMethod, updateMagazineMethod } from '../../actions/MagazineAction';
import { updateRequestReviewed } from '../../actions/RequestAction';
import UnAuthorisedAccess from '../components/UnAuthorisedAccess';
import BodyLoading from '../components/BodyLoading';

const EditMagazine = () => {

    const { id } = useParams();
    const [searchParams] = useSearchParams()
    const navigate = useNavigate(); 

    const featuredImageResizeRef = useRef();

    /** Redux */
    const dispatch = useDispatch();

    /** State declaration */
    const [validationErrors, setValidationErrors] = useState({});
    const [hasAccess, setHasAccess] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [magazine, setMagazine] = useState({
        title : "",
        featured_image: "",
        category_id: null,
        user_id: null,
        featured: false,
        active: false,
        user_details: null
    });

    const [refContainer, setRefContainer] = useState({
        featureImageRef: useRef(),
        container: {},
        current: null
    })

    useEffect(() => {
        if (searchParams.get("request_id")) {
            const request_id = searchParams.get("request_id");
            dispatch(updateRequestReviewed(request_id))
                .catch(error => {
                    console.log(error)
                })
        }
    }, [])

    const getMagazineDetails = () => {
        dispatch(getMagazineDetailsMethod(id))
        .then(res => {
            setIsLoading(false);
            setMagazine(res)
        }).catch(err => {
            if (err?.response?.status === 403) {
                setHasAccess(false)
            } else {
                setHasAccess(true)
            }
        });
    }

    useEffect(() => {
        setIsLoading(true);
        getMagazineDetails();
    }, []);

    const ToggleFeatured = (e) => {
        const { checked } = e.target;
        setMagazine(prev => ({
            ...prev,
            featured: checked,
        }));
    }

    const ToggleActive = (e) => {
        const { checked } = e.target;
        setMagazine(prev => ({
            ...prev,
            active: checked,
        }));
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setMagazine((prev) => {
            return {...prev, [name]: value }
        });
    };

    const HandleSubmit = (e) => {
        setValidationErrors({})
        e.preventDefault();
        setSubmitting(true)

        let toastMessage = null;

        if(toastMessage){
            toast.error(ErrorMessage.Title);
            setSubmitting(false);
            return false;
        }

        let tmpMagazine = {...magazine}
        tmpMagazine.user_id = tmpMagazine?.user_details?.id

        dispatch(updateMagazineMethod(id, tmpMagazine))
            .then(response => {
                toast.success(response.message);
                setSubmitting(false);
                navigate('/dashboard/magazines');
            })
            .catch(error => {
                if(error?.response?.status === 422){
                    setValidationErrors(error.response.data.errors)
                }
                console.log(error);
                setSubmitting(false);
            });
    };

    const resizeImage = (e) => {
        setRefContainer(prevState => {
            return {
                ...prevState,
                current: { ref: e.target }
            }
        })
        featuredImageResizeRef.current.resizeImage(e)
    }

    if (!hasAccess) {
        return <UnAuthorisedAccess heading={"Magazines"} />
    }

    if (!magazine?.id) {
        return <BodyLoading heading={"Magazines"} />
    }

    return (
        <>
            <div className="admin-dashboard users-panel magazine-form">
                <header>
                    <Profile heading={`Magazine: ${magazine?.title}`} pathurl="" showbtn="no" />
                    <div className="article-action-panel">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="action-box pe-0">
                                    <div className="back-to-user">
                                        <Link to="/dashboard/magazines">
                                            <img src="/images/Refund_back.svg" className="img-fluid" alt='' />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                
                            </div>
                        </div>
                    </div>
                </header>

                <div className="admin-dashboard-panel">
                    <div className="edit-article-section">
                        <form className="article-edit" action="#" method="POST">
                            <div className="row">
                                <div className="col-md-6 col-lg-6 col-12 pe-5">
                                    <div className="chat-board">
                                        <div className="inner-chat">
                                            <TitleTextArea 
                                                value={magazine?.title}
                                                maxLength={30}
                                                onChange={(e) => handleInputChange(e)}
                                                error={validationErrors?.title ? validationErrors?.title[0] : ''}
                                            />
                                            <SelectUserComponent
                                                user={magazine.user_details}
                                                onSelect={(user) => setMagazine(prev => {
                                                    return {...prev, user_details: user}
                                                })}
                                                error={validationErrors?.user_id ? validationErrors?.user_id[0] : ''}
                                                canChange
                                            />
                                            <FeaturedImageComponent
                                                onChange={resizeImage}
                                                image_url={magazine?.featured_image}
                                                error={validationErrors?.featured_image ? validationErrors?.featured_image[0] : ''}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 col-12 ps-5">
                                    <div className="right-section user-other-info">

                                        <ul>
                                            <li key={`active_toggle_wrapper`}>
                                                <ToggleCheckbox
                                                    isActive={magazine.active}
                                                    label={`Active`}
                                                    onChange={ToggleActive}
                                                />
                                            </li>

                                            <li key={`feature_toggle_wrapper`}>
                                                <ToggleCheckbox
                                                    isActive={magazine.featured}
                                                    label={`Featured`}
                                                    onChange={ToggleFeatured}
                                                />
                                            </li>
                                        </ul>

                                        <div className="col-12 text-end mt-4">
                                            <ButtonComponent
                                                label='Save changes'
                                                onClick={HandleSubmit}
                                                isLoading={isSubmitting}
                                                className='w-100'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ImageResizer
                ref={featuredImageResizeRef}
                uploadedImageCallback={(data) => {
                    setMagazine(prevState => ({
                        ...prevState,
                        featured_image: data.secure_url
                    }));
                }}
                resetState={() => {
                    refContainer.current.value = null
                }}
                IMAGE_RATION={MAGAZINE_IMAGE_RATIO}
                folder={CloudinaryFolders.Magazine}
            />
        </>
    )
}

export default EditMagazine
