import React from 'react'
import Profile from './Profile'

const BodyLoading = ({ message = "Loading..", heading }) => {
    return (
        <div className="admin-dashboard users-panel">
            <header>
                <Profile heading={heading} />
            </header>
            <div className="admin-dashboard-panel unauth-panel">
                <div className="row">
                    <div className="col-12">
                        <h2>{message}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BodyLoading