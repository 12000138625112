import moment from "moment";

export const TimetableClassStartDateFormat = "YYYY-MM-DD hh:mm:ss";

/** Cloudinary File size constants (In Bytes) */
export const CLOUDINARY_MAX_IMAGE_SIZE = 20971520;
export const CLOUDINARY_MAX_VIDEO_SIZE = 104857600;

export const CloudinaryFolders = {
    Articles: 'articles',
    Stories: 'stories',
    Forums: 'forums',
    Users: 'users',
    Gallery: 'gallery',
    GalleryFile: 'gallery_file',
    Magazine: 'magazine',
    MagazineFile: 'magazine_file',
    MagazinePDF: 'magazine_pdf',
}

export const Modules = {
  Article: "article",
  Forum: "forum",
  Story: "story",
  Profile: "profile",
  ForumPost: "forum_post",
  Advertisement: "advertisement",
  User: "user",
  Post: "post",
  TimeTable: "timetable",
  TimetableScore: "timetable_score"
};

export const NewArenaObject = {
  name: "",
  id: 0,
  timetables_count: 0,
};

export const NewTimetableClassObject = (arena_id) => {
  return {
    id: 0,
    number: "",
    name: "",
    start_date: moment().startOf("day").format(TimetableClassStartDateFormat),
    start_time: null,
    event_arena_id: arena_id,
    sponsor_by: "",
  };
};

export const NewTimetableScoreObject = {
  id: 0,
  place: 0,
  number: 0,
  contestant: "",
  comment: "",
  score: null,
};

export const MediaTypes = {
  TEXT : 'text',
  IMAGE : 'image',
  VIDEO : 'video',
  YOUTUBE : 'youtube',
  VIMEO : 'vimeo',
  SUBTITLE: 'subtitle',
  BLOCKQUOTE: 'blockquote',
  SLIDER: 'slider',
  POLL: 'poll',
  POLL_OPTION: 'poll_option',
}

export const ContentTags = {
  NORMAL: 'normal',
  ITALIC: 'italic',
  LOCAL: 'local',
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo'
}

export const ArticleStatuses = {
  SUBMITTED : "submitted",
  PUBLISHED : "published",
  REJECTED : "rejected",
}

export const Colors = {
  PRIMARY : "#00A0FF",
  OFFBLACK : "#3C3C3C",
  OFFGRAY : "#C8C8C8"
}