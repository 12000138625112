import api from "./api";

export const getPost = async (id) => {
    return await api.get(`posts/${id}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data
            }
            else {
                return response.data;
            }
        })
}

export const updatePost = async (id, post) => {
    return await api.put(`posts/${id}/update`, post)
        .then((response) => {
            if (response.status === 200) {
                return response.data
            } else {
                return response.data
            }
        });
}