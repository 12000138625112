import api from "./api";

const getAllArticles = async (query) => {
    const url = "articles"+query
    return await api.get(url)
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    });
};

const getArticle = async (id) => {
    return await api.get(`articles/${id}`)
    .then((response) => {
        if (response.status === 200) {
            return response.data.data;
        }
    });
};

const createArticles = async (articles) => {
    return await api.post("articles", articles)
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    });
};

const updateArticles = async (id, articles) => {
    return await api.put(`articles/${id}`, articles)
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    });
};

const deleteArticles = async (id) => {
    return await api.delete(`articles/${id}`)
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    });
};

const restoreArticle = async (id) => {
    return await api.get(`articles/${id}/restore`)
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    });
};

const pinnedArticles = async (id) => {
    const url = `articles/${id}/toggle-pinned`;
    return await api.get(url)
    .then((response) => {
        if (response.status === 200) {
            return response.data.data
        }
    });
}

const approveArticle = async (id) => {
    const url = `articles/${id}/approve`;
    return await api.post(url, {})
    .then((response) => {
        return Promise.resolve(response.data);
    }).catch((err) => {
        return Promise.reject(err);
    });
}

export default {
    getAllArticles,
    getArticle,
    createArticles,
    updateArticles,
    deleteArticles,
    pinnedArticles,
    restoreArticle,
    approveArticle,
};
