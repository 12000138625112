import React from 'react'
import { Link } from 'react-router-dom'
import { getStoryDateFormat } from '../../../helpers/CommonFunction'
import { Deleted, Pinned } from '../Icon'
import { useDispatch } from 'react-redux'
import swal from 'sweetalert'
import { deleteForum, pinnedForums } from '../../../actions/ForumAction'
import { toast } from 'react-hot-toast'
import UserImage from '../User/UserImage'

const ForumCard = ({ item, getForums, hideDelete, hidePin }) => {

    const dispatch = useDispatch();

    const deleteHandler = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to remove this forum entry?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                dispatch(deleteForum(id))
                    .then((res) => {
                        swal("Deleted!", "Forum entry has been deleted!", "success");
                        getForums()
                    }).catch((err) => {
                        console.log('Error!');
                    });
            }
        });
    }

    const pinnedHandler = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Pinned/Unpinned this Forum Entry?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                dispatch(pinnedForums(id))
                    .then((res) => {
                        if (res.status) {
                            toast.success("Successfully added!");
                            getForums();
                        }
                    }).catch((err) => {
                        toast.success(err.message);
                    });
            }
        });
    }

    return (
        <div className="listing-box">
            <div className='d-flex w-60'>
                <div className="image-box forum_user_img">
                    <Link to={`/dashboard/edit-user/${item?.user_details?.id}`}>
                        <UserImage 
                            image_url={item?.user_details?.profile_picture}
                            role={item?.user_details?.user_role}
                        />
                    </Link>
                    <p className='text-center forum-role'>{item?.user_details?.user_role}</p>
                </div>
                <div className="forum-question forum_all_entries">
                    <div className="question">
                        <h5>{item?.title || 'How can I find my answe to this question I have'}</h5>
                    </div>
                    <div className="author forum-author-name">
                        <p><span>{item?.user_details?.first_name.capitalize()} {item?.user_details?.last_name.capitalize()}</span>@{item?.user_details?.username}</p>
                    </div>
                </div>
            </div>
            <div className='d-flex w-40 justify-content-between'>
                <div className="social-info forum_social">
                    <ul className="stories-social">
                        <li>
                            <a href={void (0)} className="stories-icon-link">
                                <span>{item?.shares_count}</span>
                                <img src="/images/icon-share.svg" className="img-fluid" alt='shares iocn' />
                            </a>
                        </li>
                        <li>
                            <a href={void (0)} className="stories-icon-link">
                                <span>{item?.comments_count}</span>
                                <img src="/images/comments-icon.svg" className="img-fluid" alt='comments icon' />
                            </a>
                        </li>
                        <li>
                            <a href={void (0)} className="stories-icon-link">
                                <span>{item?.likes_count}</span>
                                <img src="/images/icon-likes.svg" className="img-fluid" alt='likes icon' />
                            </a>
                        </li>
                    </ul>
                    <div className="date-info">
                        <p className="category">{item?.category?.name}</p>
                        <p className="date">| {getStoryDateFormat(item?.created_at)}</p>
                    </div>
                </div>
                {!hidePin && !hideDelete && <div className="action">
                    <ul className="stories-action">
                        {!hidePin ? <li>
                            <Pinned pinnedHandler={pinnedHandler} id={item?.id} src={item?.pinned ? "/images/pinIcon-active.svg" : "/images/UnpinIcon.svg"} title={item?.pinned ? "pinned" : "unpinned"} />
                        </li> : null}
                        {!hideDelete ? <li>
                            <Deleted deleteHandler={deleteHandler} id={item?.id} />
                        </li> : null}
                    </ul>
                    <Link className="main-btn-capsule" to={`/dashboard/edit-forum-entries/${item?.id}`}>Edit</Link>
                </div>}
            </div>
        </div>
    )
}

export default ForumCard