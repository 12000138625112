
export const Deleted = ({ deleteHandler, title, id }) => {
    return (
        <div onClick={(e) => deleteHandler(e , id)} title={title} className="card-delete-icon">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M30 5.99988H0V10.9999C1.24615 10.9999 1.86923 10.9999 2.33333 11.2678C2.63737 11.4434 2.88985 11.6958 3.06538 11.9999C3.33333 12.464 3.33333 13.0871 3.33333 14.3332V24.9999C3.33333 27.8283 3.33333 29.2425 4.21201 30.1212C5.09069 30.9999 6.50491 30.9999 9.33334 30.9999H20.6667C23.4951 30.9999 24.9093 30.9999 25.788 30.1212C26.6667 29.2425 26.6667 27.8283 26.6667 24.9999V14.3332C26.6667 13.0871 26.6667 12.464 26.9346 11.9999C27.1102 11.6958 27.3626 11.4434 27.6667 11.2678C28.1308 10.9999 28.7538 10.9999 30 10.9999V5.99988ZM11.8334 14.3337C11.8334 13.7815 11.3857 13.3337 10.8334 13.3337C10.2811 13.3337 9.83337 13.7815 9.83337 14.3337V22.6671C9.83337 23.2194 10.2811 23.6671 10.8334 23.6671C11.3857 23.6671 11.8334 23.2194 11.8334 22.6671V14.3337ZM20.1667 14.3337C20.1667 13.7815 19.719 13.3337 19.1667 13.3337C18.6144 13.3337 18.1667 13.7815 18.1667 14.3337V22.6671C18.1667 23.2194 18.6144 23.6671 19.1667 23.6671C19.719 23.6671 20.1667 23.2194 20.1667 22.6671V14.3337Z" fill="#3C3C3C" />
                <path d="M11.7803 1.61765C11.9702 1.44046 12.3887 1.28388 12.9708 1.17221C13.553 1.06053 14.2663 1 15 1C15.7338 1 16.4471 1.06053 17.0292 1.17221C17.6114 1.28388 18.0299 1.44046 18.2198 1.61765" stroke="#3C3C3C" strokeWidth="2" strokeLinecap="round" />
            </svg>
        </div>
    )
}

export const Pinned = ({ pinnedHandler, src, title, id }) => {
    return (
        <div onClick={(e) => pinnedHandler(e, id)} title={title}>
            <img src={src} style={{ width: '30px' }} alt="" />
        </div>
    )
}

export const OpendEye = ({color}) => {
    return (
        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 5C8.24261 5 5.43602 7.4404 3.76737 9.43934C2.51521 10.9394 2.51521 13.0606 3.76737 14.5607C5.43602 16.5596 8.24261 19 12 19C15.7574 19 18.564 16.5596 20.2326 14.5607C21.4848 13.0606 21.4848 10.9394 20.2326 9.43934C18.564 7.4404 15.7574 5 12 5Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ClosedEye = ({color}) => {
    return (
        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.76404 5.29519C10.4664 5.10724 11.2123 5 12 5C15.7574 5 18.564 7.4404 20.2326 9.43934C21.4848 10.9394 21.4846 13.0609 20.2324 14.5609C20.0406 14.7907 19.8337 15.0264 19.612 15.2635M12.5 9.04148C13.7563 9.25224 14.7478 10.2437 14.9585 11.5M3 3L21 21M11.5 14.9585C10.4158 14.7766 9.52884 14.0132 9.17072 13M4.34914 8.77822C4.14213 9.00124 3.94821 9.22274 3.76762 9.43907C2.51542 10.9391 2.51523 13.0606 3.76739 14.5607C5.43604 16.5596 8.24263 19 12 19C12.8021 19 13.5608 18.8888 14.2744 18.6944" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}


export const DoneIcon = ({ onClick, index  }) => {
    return (
        <div href="#" className="check-icon" onClick={(e) => onClick(e, index)} title="Save">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px"><path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"/></svg>
        </div>
    )
}

export const ReplyIcon = ({ onClick, index  }) => {
    return (
        <div href="#" className="reply" onClick={(e) => onClick(e, index)} title="Reply">
           <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 8L3.64645 8.35355L3.29289 8L3.64645 7.64645L4 8ZM9 19.5C8.72386 19.5 8.5 19.2761 8.5 19C8.5 18.7239 8.72386 18.5 9 18.5L9 19.5ZM8.64645 13.3536L3.64645 8.35355L4.35355 7.64645L9.35355 12.6464L8.64645 13.3536ZM3.64645 7.64645L8.64645 2.64645L9.35355 3.35355L4.35355 8.35355L3.64645 7.64645ZM4 7.5L14.5 7.5L14.5 8.5L4 8.5L4 7.5ZM14.5 19.5L9 19.5L9 18.5L14.5 18.5L14.5 19.5ZM20.5 13.5C20.5 16.8137 17.8137 19.5 14.5 19.5L14.5 18.5C17.2614 18.5 19.5 16.2614 19.5 13.5L20.5 13.5ZM14.5 7.5C17.8137 7.5 20.5 10.1863 20.5 13.5L19.5 13.5C19.5 10.7386 17.2614 8.5 14.5 8.5L14.5 7.5Z" fill="#342922"/>
           </svg>
        </div>
    )
}


export const MessageIcon = () => {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M28.7591 8.64168C28.3353 8.71706 27.7562 8.90869 26.9122 9.19004L13.5911 13.6304C11.2228 14.4199 9.49669 14.9964 8.35702 15.5508C7.17534 16.1256 6.87338 16.5603 6.87338 16.9508C6.87338 17.3412 7.17534 17.7759 8.35702 18.3508C9.49669 18.9052 11.2228 19.4817 13.5911 20.2712L14.5596 20.594C14.5964 20.6063 14.6328 20.6184 14.6687 20.6303C15.4932 20.9047 16.0668 21.0955 16.4855 21.5142C16.9041 21.9329 17.095 22.5064 17.3693 23.3309C17.3812 23.3668 17.3933 23.4032 17.4056 23.44L17.7284 24.4085C18.5179 26.7768 19.0944 28.5029 19.6488 29.6426C20.2237 30.8243 20.6584 31.1262 21.0488 31.1262C21.4393 31.1262 21.874 30.8243 22.4488 29.6426C23.0033 28.5029 23.5798 26.7768 24.3692 24.4085L28.8096 11.0874C29.0909 10.2434 29.2826 9.6643 29.3579 9.24056C29.434 8.81319 29.359 8.71611 29.3212 8.67837C29.2835 8.64064 29.1864 8.56565 28.7591 8.64168ZM28.5839 7.65713C29.0937 7.56645 29.6291 7.57198 30.0283 7.97127C30.4276 8.37055 30.4332 8.90592 30.3425 9.4157C30.2527 9.92048 30.0363 10.5696 29.7719 11.3628L29.7583 11.4036L25.3179 24.7247L25.3037 24.7672C24.5313 27.0846 23.9349 28.8739 23.3481 30.08C22.7782 31.2516 22.0999 32.1262 21.0488 32.1262C19.9978 32.1262 19.3195 31.2516 18.7496 30.08C18.1628 28.8739 17.5664 27.0846 16.7939 24.7672L16.7798 24.7247L16.4569 23.7563C16.1307 22.7776 16.0091 22.452 15.7783 22.2213C15.5476 21.9905 15.222 21.8689 14.2433 21.5427L13.2749 21.2199L13.2324 21.2057C10.915 20.4332 9.12575 19.8368 7.91957 19.25C6.74803 18.6801 5.87338 18.0019 5.87338 16.9508C5.87338 15.8997 6.74803 15.2215 7.91956 14.6515C9.12575 14.0648 10.915 13.4684 13.2324 12.6959L13.2749 12.6817L26.596 8.24136L26.6368 8.22774C27.43 7.96333 28.0791 7.74693 28.5839 7.65713Z" fill="#342922"/>
        </svg>
    )
}

export const ActiveScreenIcon = ({color}) => {
    return (
        <a href={void(0)}>
            <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M27.2908 54.5815C42.363 54.5815 54.5815 42.363 54.5815 27.2908C54.5815 12.2185 42.363 0 27.2908 0C12.2185 0 0 12.2185 0 27.2908C0 42.363 12.2185 54.5815 27.2908 54.5815ZM25.0266 37.0284L40.1882 18.8346L38.6517 17.5542L24.1912 34.9068L15.8686 26.5842L14.4544 27.9984L23.5513 37.0954L24.3256 37.8697L25.0266 37.0284Z" fill={color}/>
            </svg>
        </a>
    )
}

export const InActiveScreenIcon = ({color}) => {
    return (
    <a href={void(0)}>
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M27.2908 54.5815C42.363 54.5815 54.5815 42.363 54.5815 27.2908C54.5815 12.2185 42.363 0 27.2908 0C12.2185 0 0 12.2185 0 27.2908C0 42.363 12.2185 54.5815 27.2908 54.5815ZM25.0266 37.0284L40.1882 18.8346L38.6517 17.5542L24.1912 34.9068L15.8686 26.5842L14.4544 27.9984L23.5513 37.0954L24.3256 37.8697L25.0266 37.0284Z" fill={color}/>
        </svg>
    </a>
    )
}

export const ArrowIcon = () => {
    return (
        <span style={{marginLeft:"36px"}}>
            <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.213 1.6066L12.6064 12.2132L1.99984 1.6066" stroke="#6E6E6E" strokeWidth="3"/>
            </svg>
        </span>
    )
}

export const PreviewIcon = () => {
    return (
      <span className="preview-icon">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9C1 5.22876 1 3.34315 2.17157 2.17157C3.34315 1 5.22876 1 9 1H21C24.7712 1 26.6569 1 27.8284 2.17157C29 3.34315 29 5.22876 29 9V21C29 24.7712 29 26.6569 27.8284 27.8284C26.6569 29 24.7712 29 21 29H9C5.22876 29 3.34315 29 2.17157 27.8284C1 26.6569 1 24.7712 1 21V9Z" stroke="#3C3C3C" strokeWidth="1.6"/>
            <path d="M4 18.6429L6.47195 15.5908C8.47489 13.1177 9.47636 11.8812 10.6351 12.0841C11.7938 12.287 12.3156 13.7902 13.3592 16.7966L13.7004 17.7797C14.1651 19.1185 14.3974 19.7878 14.8922 19.9327C14.9635 19.9535 15.037 19.9664 15.1112 19.971C15.6257 20.0029 16.0717 19.4523 16.9636 18.3511V18.3511C18.2443 16.7698 18.8847 15.9791 19.7228 15.9007C19.8467 15.8891 19.9715 15.8891 20.0954 15.9007C20.9335 15.9791 21.5739 16.7698 22.8546 18.3511L25 21" stroke="#3C3C3C" strokeWidth="1.6"/>
            <circle cx="22.0003" cy="7.99984" r="2.33333" fill="white" stroke="#3C3C3C" strokeWidth="1.6" strokeLinecap="round"/>
        </svg>
      </span>
    )
}

export const EditIcon = () => {
    return (
      <span className="preview-icon-edit">
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15.5" cy="15.5" r="15.5" fill="url(#paint0_linear_50_2111)"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M19.8315 13.8351L21.4167 12.2499C21.8063 11.8603 22.0011 11.6655 22.1239 11.4665C22.5208 10.8228 22.5208 10.0104 22.1239 9.36674C22.0011 9.16773 21.8063 8.97291 21.4167 8.58328C21.027 8.19364 20.8322 7.99882 20.6332 7.87608C19.9896 7.47913 19.1771 7.47913 18.5335 7.87608C18.3345 7.99882 18.1397 8.19364 17.75 8.58327L16.1439 10.1894C17.0243 11.7048 18.2957 12.9665 19.8315 13.8351ZM14.6893 11.644L8.52306 17.8102L8.52305 17.8102C8.098 18.2353 7.88547 18.4478 7.74574 18.7089C7.606 18.97 7.54706 19.2647 7.42917 19.8542L6.8971 22.5145C6.83058 22.8471 6.79732 23.0134 6.89193 23.108C6.98654 23.2026 7.15284 23.1694 7.48545 23.1029L10.1458 22.5708C10.7352 22.4529 11.0299 22.394 11.291 22.2542C11.5521 22.1145 11.7647 21.902 12.1897 21.4769L18.3732 15.2934C16.8885 14.3602 15.6315 13.1118 14.6893 11.644Z" fill="white"/>
            <defs>
                <linearGradient id="paint0_linear_50_2111" x1="15.5" y1="-3.84989" x2="15.5" y2="23.2667" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E9E9E9"/>
                    <stop offset="0.5" stopColor="#6EE1FF"/>
                    <stop offset="1" stopColor="#00A0FF"/>
                </linearGradient>
            </defs>
        </svg>
      </span>
    )
}

export const ArrowDown = () => {
    return (
        <span className="arrow-down-icon">
            <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.2133 1.6066L12.6067 12.2132L2.00009 1.6066" stroke="#6E6E6E" strokeWidth="3"/>
            </svg>
        </span>
    )
}

export const SerachIcons = () => {
    return(
        <span className="search-icon">
           <svg fill="#6E6E6E" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"/></svg>
        </span>
    )
}


export const NotReadOnlyIcon = ({onClick}) => {
    return(
        <span className="readonly" onClick={onClick}>
            <svg width="25" height="25" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.8315 6.83521L15.4167 5.25C15.8063 4.86037 16.0011 4.66555 16.1239 4.46653C16.5208 3.82291 16.5208 3.01043 16.1239 2.36681C16.0011 2.16779 15.8063 1.97297 15.4167 1.58334C15.027 1.1937 14.8322 0.998881 14.6332 0.876142C13.9896 0.479193 13.1771 0.479193 12.5335 0.876142C12.3345 0.998881 12.1397 1.1937 11.75 1.58334L10.1439 3.18949C11.0243 4.70489 12.2957 5.96655 13.8315 6.83521ZM8.68934 4.64401L2.52306 10.8103L2.52305 10.8103C2.098 11.2354 1.88547 11.4479 1.74574 11.709C1.606 11.9701 1.54706 12.2648 1.42917 12.8543L0.897104 15.5146C0.830582 15.8472 0.797321 16.0135 0.891929 16.1081C0.986537 16.2027 1.15284 16.1694 1.48545 16.1029L4.14577 15.5709C4.73522 15.453 5.02994 15.394 5.29104 15.2543C5.55214 15.1145 5.76467 14.902 6.18973 14.477L12.3732 8.29349C10.8885 7.36027 9.63154 6.11191 8.68934 4.64401Z" fill="#047dc5"/>
            </svg>
        </span>
    )
}

export const ReadOnlyIcon = ({onClick}) => {
    return(
        <span className="readonly" onClick={onClick}>
            <svg width="25" height="25" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.8315 6.83521L15.4167 5.25C15.8063 4.86037 16.0011 4.66555 16.1239 4.46653C16.5208 3.82291 16.5208 3.01043 16.1239 2.36681C16.0011 2.16779 15.8063 1.97297 15.4167 1.58334C15.027 1.1937 14.8322 0.998881 14.6332 0.876142C13.9896 0.479193 13.1771 0.479193 12.5335 0.876142C12.3345 0.998881 12.1397 1.1937 11.75 1.58334L10.1439 3.18949C11.0243 4.70489 12.2957 5.96655 13.8315 6.83521ZM8.68934 4.64401L2.52306 10.8103L2.52305 10.8103C2.098 11.2354 1.88547 11.4479 1.74574 11.709C1.606 11.9701 1.54706 12.2648 1.42917 12.8543L0.897104 15.5146C0.830582 15.8472 0.797321 16.0135 0.891929 16.1081C0.986537 16.2027 1.15284 16.1694 1.48545 16.1029L4.14577 15.5709C4.73522 15.453 5.02994 15.394 5.29104 15.2543C5.55214 15.1145 5.76467 14.902 6.18973 14.477L12.3732 8.29349C10.8885 7.36027 9.63154 6.11191 8.68934 4.64401Z" fill="#3C3C3C"/>
            </svg>
        </span>
    )
}

export const ResetPasseord = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32" width="25" height="25">
            <path  d="M 16 4 L 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 12.734375 7.585938 9.851563 10 8.03125 L 10 13 L 12 13 L 12 5 L 4 5 L 4 7 L 8.09375 7 C 5.59375 9.199219 4 12.417969 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z" fill="#ffffff"/>
        </svg>
    )
}

export const ViewIcon = () => {
    return(
        <span className="view-icon">
            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 1C5.55576 1 2.53109 5.23425 1.45554 7.11639C1.23488 7.50252 1.12456 7.69558 1.1367 7.9836C1.14885 8.27161 1.27857 8.4598 1.53799 8.83619C2.8182 10.6935 6.29389 15 11 15C15.7061 15 19.1818 10.6935 20.462 8.83619C20.7214 8.4598 20.8511 8.27161 20.8633 7.9836C20.8754 7.69558 20.7651 7.50252 20.5445 7.11639C19.4689 5.23425 16.4442 1 11 1Z" stroke="#3C3C3C"/>
                <circle cx="11" cy="8" r="3" stroke="#3C3C3C"/>
            </svg>
        </span>
    )
}

export const ColorPicker = () => {
    return(
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.575 7.34627L19.7477 10.519C20.3319 11.103 20.3319 12.05 19.7477 12.6341L8.32602 24.0559C8.1864 24.1955 8.0205 24.3061 7.83792 24.3814L4.10388 25.9189C2.25989 26.6781 0.41593 24.8341 1.1752 22.9901L2.71276 19.2561C2.78794 19.0735 2.89853 18.9077 3.03816 18.7681L14.4599 7.34627C15.044 6.76218 15.991 6.76218 16.575 7.34627Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.5747 1L19.7475 4.17272M19.7475 4.17272L21.8627 2.05758C22.4467 1.4735 23.3937 1.4735 23.9778 2.05758L25.0354 3.11515C25.6194 3.69923 25.6194 4.64622 25.0354 5.23029L22.9202 7.34543M19.7475 4.17272L22.9202 7.34543M26.0929 10.5181L22.9202 7.34543" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const VerifiedIcon = () => {
    return(
        <span className="view-icon">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="30px" height="30px">
                <polygon fill="#42a5f5" points="29.62,3 33.053,8.308 39.367,8.624 39.686,14.937 44.997,18.367 42.116,23.995 45,29.62 39.692,33.053 39.376,39.367 33.063,39.686 29.633,44.997 24.005,42.116 18.38,45 14.947,39.692 8.633,39.376 8.314,33.063 3.003,29.633 5.884,24.005 3,18.38 8.308,14.947 8.624,8.633 14.937,8.314 18.367,3.003 23.995,5.884"/>
                <polygon fill="#fff" points="21.396,31.255 14.899,24.76 17.021,22.639 21.428,27.046 30.996,17.772 33.084,19.926"/>
            </svg>
        </span>
    )
}

export const VideoSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 25 21" fill="none">
            <path d="M18.2376 21.0001H5.89638C2.67394 21.0001 0 18.3947 0 15.1037V6.19057C0 2.96813 2.60538 0.294189 5.89638 0.294189H18.2376C21.4601 0.294189 24.134 2.89957 24.134 6.19057V15.1037C24.134 18.3261 21.4601 21.0001 18.2376 21.0001ZM5.89638 1.66544C3.42813 1.66544 1.37125 3.65375 1.37125 6.19057V15.1037C1.37125 17.5719 3.35956 19.6288 5.89638 19.6288H18.2376C20.7059 19.6288 22.7628 17.6405 22.7628 15.1037V6.19057C22.7628 3.72232 20.7745 1.66544 18.2376 1.66544H5.89638Z" fill="white"/>
            <path d="M10.0778 14.4867C9.80355 14.4867 9.5293 14.4181 9.32362 14.281C8.84368 14.0067 8.56943 13.5268 8.56943 12.9783V8.3846C8.56943 7.8361 8.84368 7.35616 9.32362 7.08191C9.80355 6.80766 10.3521 6.80766 10.832 7.08191L14.8086 9.41304C15.2886 9.68729 15.5628 10.1672 15.5628 10.7157C15.5628 11.2642 15.2886 11.7442 14.8086 12.0184L10.832 14.3495C10.6263 14.4181 10.3521 14.4867 10.0778 14.4867ZM10.0778 8.17891H10.0092C9.94068 8.17891 9.94068 8.24747 9.94068 8.31604V12.9097C9.94068 12.9783 10.0092 13.0469 10.0092 13.0469C10.0092 13.0469 10.0778 13.1154 10.1464 13.0469L14.123 10.7157C14.1916 10.6472 14.1916 10.6472 14.1916 10.5786C14.1916 10.51 14.1916 10.4415 14.123 10.4415L10.1464 8.11035C10.1464 8.17891 10.0778 8.17891 10.0778 8.17891Z" fill="white"/>
        </svg>
    )
}

export const CameraSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 25 23" fill="none">
            <path d="M1 8.5759C1 7.25073 2.07426 6.17647 3.39943 6.17647V6.17647C4.30826 6.17647 5.13909 5.66299 5.54554 4.8501L6.60784 2.72549C6.90778 2.12562 7.05774 1.82569 7.27648 1.60193C7.50426 1.36893 7.78551 1.1951 8.09578 1.09558C8.39374 1 8.72908 1 9.39975 1H15.8944C16.565 1 16.9004 1 17.1983 1.09558C17.5086 1.1951 17.7899 1.36893 18.0176 1.60193C18.2364 1.82569 18.3863 2.12562 18.6863 2.72549L19.7486 4.8501C20.155 5.66299 20.9859 6.17647 21.8947 6.17647V6.17647C23.2199 6.17647 24.2941 7.25073 24.2941 8.5759V15.7059C24.2941 18.5343 24.2941 19.9485 23.4154 20.8272C22.5368 21.7059 21.1225 21.7059 18.2941 21.7059H7C4.17157 21.7059 2.75736 21.7059 1.87868 20.8272C1 19.9485 1 18.5343 1 15.7059V8.5759Z" stroke="white"/>
            <circle cx="12.6471" cy="12.6467" r="4.67647" stroke="white"/>
        </svg>
    )
}

export const TrashSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
            <circle cx="15.033" cy="15.9418" r="15" fill="#00A0FF"/>
            <path d="M12.8489 18.5581L12.8489 15.9418" stroke="white" strokeLinecap="round"/>
            <path d="M17.217 18.5581L17.217 15.9418" stroke="white" strokeLinecap="round"/>
            <path d="M7.17033 11.5814H22.8956V11.5814C21.9095 11.5814 21.4165 11.5814 21.0309 11.7515C20.5742 11.9529 20.2094 12.3177 20.008 12.7744C19.8379 13.16 19.8379 13.653 19.8379 14.6391V18.9186C19.8379 20.8042 19.8379 21.7471 19.2521 22.3328C18.6663 22.9186 17.7235 22.9186 15.8379 22.9186H14.228C12.3424 22.9186 11.3996 22.9186 10.8138 22.3328C10.228 21.7471 10.228 20.8042 10.228 18.9186V14.6391C10.228 13.653 10.228 13.16 10.058 12.7744C9.85658 12.3177 9.49172 11.9529 9.03506 11.7515C8.64946 11.5814 8.15642 11.5814 7.17033 11.5814V11.5814Z" stroke="white" strokeLinecap="round"/>
            <path d="M12.8489 8.96532C12.8489 8.96532 13.2857 8.09302 15.033 8.09302C16.7802 8.09302 17.217 8.96511 17.217 8.96511" stroke="white" strokeLinecap="round"/>
        </svg>
    )
}

