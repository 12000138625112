import { SET_DASHBOARD_PREFERENCES } from '../constants/actiontypes';

const initialState = {
    dashboard : {
        preferences : {}
    }
}

const DashboardReducer = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case SET_DASHBOARD_PREFERENCES:
            return {
                ...state,
                dashboard : { ...state.dashboard, preferences : payload }
            };
        default:
            return {...state};
    }
}

export default DashboardReducer;