import React, { useRef, useState } from "react";
import moment from "moment";
import ScoreItem from "../../Event/ScoreItem";

const TimetableScore = ({ item }) => {
  const [active, setActive] = useState(null);
  const contentEl = useRef();
  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  
  return (
    <div className="card-body">
      <h4>{item.name}</h4>
      <ul className="nav-panel">
        <li className="nav-panel-item">
          <div className="app-box">
            <div className="app-info">
              <span>
                {item?.start_time
                  ? moment(
                      `${moment().format("YYYY-MM-DD")} ${item?.start_time}`
                    ).format("hh:mm A")
                  : ""}
              </span>
            </div>
            <div className="app-info">
              <span>{item?.name}</span>
            </div>
          </div>
          <div className="social-info">
            {item?.sponsor_by && (
              <ul className="d-flex align-items-center">
                <li>Sponsored by : {item?.sponsor_by}</li>
              </ul>
            )}
          </div>
          <div className="apps-action timetable-action">
            <button
              type="button"
              className="p-0 border-0"
              onClick={(e) => {
                // HandleEditScores(item?.id);
              }}
            >
              <img
                alt=""
                src={
                  item?.scores?.length > 0
                    ? "/images/trophy2.svg"
                    : "/images/trophy1.svg"
                }
                className="img-fluid"
              />
            </button>
            {item?.scores?.length > 0 && (
              <button
                className="p-0 border-0"
                onClick={() => handleToggle(item?.id)}
              >
                <i
                  className={`fa-sharp fa-solid fa-chevron-${
                    active === item?.id ? "up" : "down"
                  }`}
                ></i>
              </button>
            )}
          </div>
        </li>
      </ul>
      {item?.scores?.length > 0 ? (
        <ScoreItem
          scores={item?.scores}
          contentEl={contentEl}
          active={active}
          id={item?.id}
        />
      ) : null}
    </div>
  );
};

export default TimetableScore;
