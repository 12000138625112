import React from 'react'
import { getStoriesArticlesImage } from '../../Common/AHImage'
import { OnBehalfArticle } from '../../../Utils/helpers'
import { getDateFormat } from '../../../helpers/CommonFunction'
import { Deleted, Pinned, ViewIcon } from '../Icon'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import { useDispatch } from 'react-redux'
import { deleteArticles, pinnedArticles, restoreArticle } from '../../../actions/ArticlesAction'
import { toast } from 'react-hot-toast'
import UserImage from '../User/UserImage'

const ArticleCard = ({ article, getArticles, hidePin, hideDelete, hideEdit, showRestore }) => {

    const dispatch = useDispatch();

    const deleteHandler = (e) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to remove this Article?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                dispatch(deleteArticles(article?.id))
                    .then(res => {
                        getArticles()
                        swal("Deleted!", "Articles has been deleted!", "success");
                    }).catch(e => {
                        console.log(e);
                    });
            }
        });
    }

    const restoreHandler = (e) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to restore this Article?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                dispatch(restoreArticle(article?.id))
                    .then(res => {
                        getArticles()
                        swal("Restored!", "Articles has been restored!", "success");
                    }).catch(e => {
                        console.log(e);
                    });
            }
        });
    }    

    const pinnedHandler = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Pinned/Unpinned this Article?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                dispatch(pinnedArticles(id))
                    .then(res => {
                        if (res.status) {
                            toast.success("Successfully added!");
                            getArticles();
                        }
                    }).catch(err => {
                        toast.success(err.message);
                    });
            }
        });
    }

    return (

        <div className="articles">
            <div className="articles-box" style={{ backgroundImage: `url(${getStoriesArticlesImage(article?.image)})` }}>
                <div className="articles-header">
                    <div className="articles-author">
                        <UserImage 
                            image_url={article?.user_details?.profile_picture}
                            role={article?.user_details?.user_role}
                        />                        
                        <h4>{OnBehalfArticle(article?.author, article?.user_details).name}
                            <span>{OnBehalfArticle(article?.author, article?.user_details).username}</span>
                        </h4>
                    </div>
                    <a href="!#">{article?.user_details?.user_role}</a>
                </div>
                <div className="articles-footer">
                    <h5>{article?.title}</h5>
                </div>
            </div>
            <div className="articles-content">
                <ul className="articles-meta">
                    <li>
                        <p className="category">{article?.category?.name.capitalize()}</p>
                    </li>
                    <li>
                        <a className="meta-date" href="!#">{getDateFormat(article?.created_at)}</a>
                    </li>
                </ul>
                <ul className="articles-social">
                    <li>
                        <a href="!#">
                            <span>{`${article?.views_count}/${article?.unique_views_count}`}</span>
                            <ViewIcon />
                        </a>
                    </li>
                    <li>
                        <a href="!#">
                            <span>{article?.shares_count}</span>
                            <img src="/images/icon-share.svg" className="img-fluid" alt='' style={{width: '17px'}} />
                        </a>
                    </li>
                    <li>
                        <a href="!#">
                            <span>{article?.saves_count}</span>
                            <img src="/images/icon-comments.svg" className="img-fluid" alt='' />
                        </a>
                    </li>
                    <li>
                        <a href="!#">
                            <span>{article?.likes_count}</span>
                            <img src="/images/icon-likes.svg" className="img-fluid" alt='' />
                        </a>
                    </li>
                </ul>
                <ul className="articles-action">
                    
                    {!hideDelete ?
                        <li>
                            {!showRestore ?
                                <Deleted deleteHandler={deleteHandler} id={article?.id} title={`Delete Article`} />
                            :
                                <a href="!#" onClick={restoreHandler} className="cu-pt main-btn-capsule">Restore</a>                               
                            }
                        </li> : null
                    }
                    {!hidePin ?
                        <li className="ms-2">
                            <Pinned pinnedHandler={pinnedHandler} id={article?.id} src={article?.pinned ? "/images/pinIcon-active.svg" : "/images/UnpinIcon.svg"} title={article?.pinned ? "pinned" : "unpinned"} />
                        </li> : null
                    }
                    {!hideEdit ? 
                        <li>
                            <Link to={`/dashboard/edit-articles/${article?.id}`} className="main-btn-capsule">Edit</Link>
                        </li> : null
                    }
                </ul>
            </div>
        </div>
    )
}

export default ArticleCard