import api from "./api";

const getTimeTables = async (event) => {
    const url = "events/" + event + "/timetables"
    return await api.get(url)
        .then((response) => {
            return response.data;
        });
};

const createTimeTable = async (event, timetable) => {
    const url = "events/" + event + "/timetables"
    return await api.post(url, timetable)
        .then((response) => {
            return response.data;
        });
};

const showTimeTable = async (id, event) => {
    const url = "events/" + event + "/timetables/" + id;
    return await api.get(url)
        .then((response) => {
            return response.data;
        });
};

const updateTimeTable = async (id, event, timetable) => {
    const url = "events/" + event + "/timetables/" + id;
    return await api.put(url, timetable)
        .then((response) => {
            return response.data;
        });
};

const deleteTimeTable = async (event_id, timetable_id) => {
    const url = "events/" + event_id + "/timetables/" + timetable_id;
    return await api.delete(url)
        .then((response) => {
            return response.data;
        });
};

// Time table Score method
const setTimeTableScore = async (event_id, timetable_id, payload) => {
    const url = "events/" + event_id + "/timetables/" + timetable_id + '/scores';
    return await api.post(url, payload)
        .then((response) => {
            return response.data;
        });
};

// Update timetable position method
const updateTimeTablesPosition = async (event_id, timetable_ids) => {
    const url = "events/" + event_id + "/timetables/change-position";
    return await api.post(url, { ids: timetable_ids })
        .then((response) => {
            return response.data;
        });
};

export default {
    getTimeTables,
    createTimeTable,
    showTimeTable,
    updateTimeTable,
    deleteTimeTable,
    setTimeTableScore,
    updateTimeTablesPosition
}