export const Roles = ["Admin", "Editor", "Advertiser", "Expert", "Standard User", "Sponsor"];
export const Role = {
    Admin: "Admin",
    Editor: "Editor",
    Advertiser: "Advertiser", 
    Expert :"Expert",
    StandardUser: "Standard User",
    Sponsor: "Sponsor",
    Influencer: "Influencer",
};
