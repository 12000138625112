
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD
} from '../constants/actiontypes';
const user = JSON.parse(localStorage.getItem("user"));

const initialState = user ? {
    isLoggedIn: true,
    user,
    user_role:user?.user?.user_role,
    forgetPasswordData: {},
    resetPasswordData: {},
  } : {
     isLoggedIn: false, 
     user: null,
     forgetPasswordData: {},
     resetPasswordData: {},
  };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user:payload.user
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
      case FORGOT_PASSWORD:
        return {
          ...state,
          forgetPasswordData:payload
        };
      case RESET_PASSWORD:
        return {
          ...state,
          resetPasswordData:payload
        };
    default:
      return state;
  }
}

