import { ErrorHandler } from "../services/ErrorHandler";
import { deleteGallery, getGalleries, getGalleryDetails, restoreGallery, storeGallery, updateGallery } from "../services/gallery.service";

export const getGalleriesMethod = (query) => {
    return dispatch => {
        return ErrorHandler(dispatch)(getGalleries(query))
        .then(data => {
            return Promise.resolve(data.data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const storeGalleryMethod = (payload) => {
    return dispatch => {
        return ErrorHandler(dispatch)(storeGallery(payload))
        .then(data => {
            return Promise.resolve(data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const updateGalleryMethod = (id, payload) => {
    return dispatch => {
        return ErrorHandler(dispatch)(updateGallery(id, payload))
        .then(data => {
            return Promise.resolve(data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const getGalleryDetailsMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(getGalleryDetails(id))
        .then(data => {
            return Promise.resolve(data.data.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const deleteGalleryMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(deleteGallery(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export const restoreGalleryMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(restoreGallery(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}