import React from 'react'

const ButtonComponent = ({label = "", onClick, isLoading = false, disabled = false, showArrow = true, className= ""}) => {
  return (
    <button type="button" className={`main-btn ${className}`} onClick={onClick} name="save_message_data" disabled={isLoading || disabled}>
        {label} &nbsp;
        {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> }
        {!isLoading && showArrow && <i className="fa-sharp fa-solid fa-arrow-right"></i>}
    </button>
  )
}

export default ButtonComponent