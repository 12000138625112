import { ErrorHandler } from '../services/ErrorHandler';
import { getPost, updatePost } from '../services/PostService';

export const getPostMethod = (id) => {
    return dispatch => {
        return ErrorHandler(dispatch)(getPost(id))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
}

export const updatePostMethod = (id, payload) => {
    return dispatch => {
        return ErrorHandler(dispatch)(updatePost(id, payload))
        .then(data => {
            return Promise.resolve(data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}