// Profile thumbnail image
export const getThumbnail = (url) => url?.replace("upload/", "upload/w_200/")
export const getThumbnailFromVideo = (url) => { return url?.replace("mp4", "jpg") }

// Story, Article and Forum thumbnail image
export const getStoriesImage = (url) => url?.replace("upload/", "upload/w_600/");
export const getStoriesArticlesImage = (url) => url?.replace("upload/", "upload/w_390/");
export const getArticlesFeaturedImage = (url) => url?.replace("upload/", "upload/w_390/");
export const getArticleForumImage = (url) => url?.replace("upload/", "upload/w_1000/");
export const getAdsPreviewImage = (url) => url?.replace("upload/", "upload/w_500/");

export const get500WidthImage = (url) => url?.replace("upload/", "upload/w_500/");

export const convertUrlToJpg = (url) => {
    const ext = url.split(/[#?]/)[0].split('.').pop().trim();
    return url?.replace(ext, "jpg")
}