import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ShimmerCategoryList } from "react-shimmer-effects";
import Profile from '../components/Profile'

import { useDispatch } from 'react-redux';
import { getPushNotificationsMethod } from '../../actions/PushNotificationAction';
import { getDateFormat } from '../../helpers/CommonFunction';
import { useSelector } from 'react-redux';
import { getCategories } from '../../actions/CategoryAction';
import Pagination from "react-js-pagination";


const PushNotifications = () => {

    /** Redux */
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.CategoryReducer.categories)

    /** State declaration */
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState({ data: [], total: 0 });
    const [page, setPage] = useState(1);

    const getNotifications = () => {
        let query = "page=" + page;
        setIsLoading(true);
        dispatch(getPushNotificationsMethod(query)).then((res) => {
            setNotifications(res.data);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getNotifications();

        if(!categories?.length){
            dispatch(getCategories());
        }
    }, [])

    useEffect(() => {
        getNotifications();
      }, [page]);

    const renderNotifications = notifications.data.map((n) => {
        return (
            <li className="nav-panel-item" key={`notification_${n?.id}`}>
                <div className="message-media">
                    {n.users_count === n.failure_users_count &&
                        <div className="check-mark red-background">
                            <i className="fas fa-2x fa-xmark text-white"></i>
                        </div>
                    }
                    {n.users_count === n.success_users_count &&
                        <div className="check-mark green-background">
                            <img src="/images/checked.png" className="img-fluid" alt=' ' />
                        </div>
                    }

                    {n.users_count > n.failure_users_count && n.failure_users_count > 0 &&
                        <div className="check-mark orange-background">
                            <i className="fas fa-2x fa-exclamation text-white"></i>
                        </div>
                    }

                    <div className="message-info">
                        <h5>{n.title}</h5>
                        <p><span>To:</span>
                            {n.target_group === "All" && <a>All Users</a>}
                            {n.target_group === "Category" && <a>Category: { categories.find(c => c.id === n.target_group_ids)?.name ?? "" }</a>}
                            {(n.target_group === "Role" || n.target_group === "Device") && <a>{n.target_group}:{n.target_group_ids}</a>}
                            &nbsp;
                            Sent on {getDateFormat(n.created_at)}
                        </p>
                    </div>
                </div>
                <div className="view-messages massage_box">
                    <p>Total Users: <span className='message-count'>{n.users_count}</span></p>
                    <p>Success Users: <span className='message-count'>{n.success_users_count}</span></p>
                    <p>Failed Users: <span className='message-count'>{n.failure_users_count}</span></p>
                </div>
                <div className="messages-action justify-content-end gap-3">
                    {/**<a href="#">
                        <img src="/images/delete-icon.svg" className="img-fluid" alt=' ' />
                    </a>*/}
                    <Link to={`/dashboard/push-notifications/${n.id}`} className="main-btn-capsule">Show</Link>
                </div>
            </li >
        )
    })

    return (
        <>
            <div className="admin-dashboard users-panel">
                <header>
                    <Profile heading="Push Notifications" pathurl="push-notifications/new" showbtn="yes" />
                </header>
                <div className="admin-dashboard-panel">
                    <div className="user-filter-panel">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-12">
                                <div className="filter-form">
                                    <form>

                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">

                            </div>
                        </div>
                    </div>
                    <div className="messages-listing-panel">
                        {!isLoading ? notifications?.data?.length > 0 ? (<ul className="nav-panel">{renderNotifications} </ul>) : (<span>No data available.</span>) : (<ShimmerCategoryList items={5} categoryStyle="STYLE_FIVE" />)}
                    </div>
                    <div className="row align-items-center">
                        <div className="col-10">
                            {notifications?.last_page !== 1
                            ? notifications?.data && (
                                <Pagination
                                activePage={notifications?.current_page}
                                totalItemsCount={notifications?.total}
                                itemsCountPerPage={notifications?.per_page}
                                onChange={(pageNumber) => setPage(pageNumber)}
                                itemClass="page-item"
                                linkClass="page-link"
                                itemClassFirst="first"
                                itemClassLast="last"
                                itemClassPrev="prev"
                                itemClassNext="next"
                                firstPageText="Frist"
                                lastPageText="Last"
                                prevPageText="Previous"
                                nextPageText="Next"
                                hideFirstLastPages={true}
                                />
                            )
                            : null}
                        </div>
                        <div className="col-2 d-flex justify-content-end align-items-center ">
                            <p className="font-weight-bold mx-4">
                            Total : {notifications?.total}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PushNotifications