import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { createTimeTablesMethod } from '../../actions/TimeTableAction';
import { NewTimetableClassObject } from '../../constants/Constants';


const TimetableModal = ({ show, payload, closeModal, arena_id, getTimetables }) => {
    const dispatch = useDispatch();

    const event = useSelector((state) => state.EventReducer.event);

    const [timetable, setTimetable] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [timeTableErrors, setTimeTableErrors] = useState(null);


    const handleCreateNewClass = (e, canCloseModal = true) => {
        e.preventDefault();
        setIsSubmitting(true)

        dispatch(createTimeTablesMethod(event?.id, timetable))
            .then((res) => {
                toast.success(res.message);
                setIsSubmitting(false)
                getTimetables();
                console.log(arena_id);
                if (canCloseModal) {
                    closeModal()
                } else {
                    setTimetable({ ...NewTimetableClassObject(arena_id) })
                }
                setIsSubmitting(false)
            }).catch((err) => {
                toast.error(err.message);
                setIsSubmitting(false);
                setTimeTableErrors(err?.errors);
            });
    }

    useEffect(() => {
        setTimetable(payload);
    }, [payload])

    const handleChangeNewClass = (e) => {
        const { name, value } = e.target
        setTimetable(timetable => ({ ...timetable, [name]: value }))
        setTimeTableErrors(timeTableErrors => ({ ...timeTableErrors, [name]: null }))
    }

    const handleChangeStartDate = (date) => {
        setTimetable((timetable) => ({ ...timetable, start_date: moment(date).format('YYYY-MM-DD HH:mm:ss') }))
        setTimeTableErrors(timeTableErrors => ({ ...timeTableErrors, start_date: null }))
    }

    const handleChangeStartTime = (datetime) => {

        if (datetime) {
            setTimetable((timetable) => ({ ...timetable, start_time: moment(datetime).format('HH:mm:ss') }))
        } else {
            setTimetable((timetable) => ({ ...timetable, start_time: null }))
        }
        setTimeTableErrors(timeTableErrors => ({ ...timeTableErrors, start_time: null }))
    }

    // const handleChangeEndDate = (date) => {
    //     setTimetable((timetable) => ({ ...timetable, end_date: moment(date).format('YYYY-MM-DD HH:mm:ss') }))
    //     setTimeTableErrors(timeTableErrors => ({ ...timeTableErrors, end_date: null }))
    // }

    return (
        <Modal show={show} size="lg" centered onHide={() => closeModal()}>
            <Modal.Body>
                <div className="modal-header">
                    <div className='d-flex'>
                        <img onClick={() => closeModal()} src="/images/Refund_back.svg" className="img-fluid" alt='' />
                        <h5 className='m-0 ms-3'>Add new class</h5>
                    </div>
                </div>

                <div className="modal-body timetable-modal-body">
                    <form className="">
                        <div className="form-group row">
                            <label className='col-sm-2 col-form-label'>Number</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    value={timetable?.number}
                                    name="number"
                                    className="form-control"
                                    onChange={(e) => handleChangeNewClass(e)}
                                    placeholder="Number"
                                />
                                {timeTableErrors?.number ? (<span className='text-danger'>{timeTableErrors?.number[0]}</span>) : null}
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className='col-sm-2 col-form-label'>Name*</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    value={timetable?.name}
                                    name="name"
                                    onChange={(e) => handleChangeNewClass(e)}
                                    className="form-control"
                                    placeholder="Name"
                                />
                                {timeTableErrors?.name ? (<span className='text-danger'>{timeTableErrors?.name[0]}</span>) : null}
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className='col-sm-2 col-form-label'>Start date*</label>
                            <div className="col-sm-5">
                                <DatePicker
                                    placeholderText="Start Date"
                                    dateFormat="MMMM d, yyyy"
                                    selected={new Date(timetable?.start_date)}
                                    onChange={(date) => handleChangeStartDate(date)}
                                    selectsStart
                                    minDate={new Date()}
                                    className="form-control"
                                />
                                {timeTableErrors?.start_date ? (<span className='text-danger'>{timeTableErrors?.start_date[0]}</span>) : null}
                            </div>

                            <div className="col-sm-5">
                                <DatePicker
                                    placeholderText="00:00"
                                    selected={timetable?.start_time ? new Date(moment().format('YYYY-MM-DD') + ' ' + timetable?.start_time) : null}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    onChange={(date) => handleChangeStartTime(date)}
                                    dateFormat="hh:mm aa"
                                    className="form-control"
                                    isClearable={true}
                                />
                                {timeTableErrors?.start_date ? (<span className='text-danger'>{timeTableErrors?.start_date[0]}</span>) : null}
                            </div>

                            {/**<div className="col-sm-5">
                                <DatePicker
                                    placeholderText="End Date"
                                    dateFormat="MMMM d, yyyy hh:mm aa"
                                    selected={new Date(timetable?.end_date)}
                                    onChange={(date) => handleChangeEndDate(date)}
                                    selectsStart
                                    showTimeSelect
                                    startDate={new Date(timetable?.start_date)}
                                    endDate={new Date(timetable?.end_date)}
                                    minDate={new Date(timetable?.start_date)}
                                    className="form-control"
                                />
                                {timeTableErrors?.end_date ? (<span className='text-danger'>{timeTableErrors?.end_date[0]}</span>) : null}
                            </div>**/}
                        </div>
                        <div className="form-group row">
                            <label className='col-sm-2 col-form-label'>Sponsor</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    value={timetable?.sponsor_by}
                                    name="sponsor_by"
                                    onChange={(e) => handleChangeNewClass(e)}
                                    className="form-control"
                                    placeholder="Sponsor"
                                />
                                {timeTableErrors?.sponsor_by ? (<span className='text-danger'>{timeTableErrors?.sponsor_by[0]}</span>) : null}
                            </div>
                        </div>
                        <div className='text-center'>
                            <button type='button' className="btn p-0" onClick={() => closeModal()}>Cancel</button>
                            <button type='button' disabled={isSubmitting} className="btn main-btn" onClick={(e) => handleCreateNewClass(e, timetable?.id === 0 ? false : true)}>
                                {timetable?.id === 0 ? 'Add' : 'Update'} &nbsp;
                                {isSubmitting ?
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    :
                                    <i className="fa-sharp fa-solid fa-arrow-right"></i>
                                }
                            </button>

                            {timetable?.id === 0 && <button type='button' disabled={isSubmitting} className="btn btn-secondary" onClick={(e) => handleCreateNewClass(e)}>
                                Add and close
                            </button>}
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default TimetableModal