import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import { getDateFormat } from '../../helpers/CommonFunction';

const ChangeDateComponent = ({date, onChange}) => {
    const [isEditing, setEditing] = useState(false)
  return (
    <div className="date-change-wrapper">
        {!isEditing ?
            <>
                <span>{getDateFormat(date)}</span>&nbsp;|&nbsp;
                <i onClick={() => setEditing(true)} className="cu-pt fa fa-edit"></i>
            </>
            :
            <div className="d-flex align-items-center">
                <DatePicker
                    className="form-control border-0"
                    placeholderText="Start Date"
                    dateFormat="MMMM d, yyyy hh:mm aa"
                    selected={new Date(date)}
                    onChange={onChange}                                                        
                    showTimeSelect
                />
                <i onClick={() => setEditing(false)} className="cu-pt ms-2 fa fa-times"></i>
            </div>
        }
    </div>
  )
}

export default ChangeDateComponent