import api from "./api";

const getRequest = async (query = "") => {
    const url = "requests" + query
    return await api.get(url)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        });
};

const setRequest = async (query) => {
    return await api.post("requests")
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        });
};

const updateRequestReviewed = async (id) => {
    const url = "requests/" + id + "/reviewed"
    return await api.get(url)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        });
};

const deleteRequest = async (id) => {
    return await api.delete(`requests/${id}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        });
};

export default {
    getRequest,
    setRequest,
    deleteRequest,
    updateRequestReviewed
};