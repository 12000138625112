import api from "./api";

const getEvents = async (query = "") => {
  let url = "events";
  if (query.length) {
    url = url + "?" + query;
  }
  return await api.get(url).then((response) => {
    return response.data;
  });
};

const setEvents = async (event) => {
  return await api.post("events", event).then((response) => {
    return response.data;
  });
};

const showEvents = async (event_id) => {
  const url = `events/${event_id}`;
  return await api.get(url).then((response) => {
    return response.data;
  });
};

const updateEvents = async (event_id, event) => {
  const url = `events/${event_id}`;
  return await api.put(url, event).then((response) => {
    return response.data;
  });
};

const deleteEvents = async (event_id) => {
  const url = `events/${event_id}`;
  return await api.delete(url).then((response) => {
    return response.data;
  });
};

const getEventTimeline = async (event_id, query) => {
  const url = `events/${event_id}/timeline` + query;
  return await api.get(url).then((response) => {
    return response.data;
  });
};

const deleteEventTimeline = async (event_id, timeline_id) => {
  const url = `events/${event_id}/timeline/${timeline_id}/delete`;
  return await api.delete(url).then((response) => {
    return response.data;
  });
};

const toggleFeaturedTimeline = async (event_id, timeline_id) => {
  const url = `events/${event_id}/timeline/${timeline_id}/toggle-featured`;
  return await api.get(url).then((response) => {
    return response.data;
  });
};

const updateEventTimelineDate = async (event_id, timeline_id, payload) => {
  const url = `events/${event_id}/timeline/${timeline_id}/date-update`;
  return await api.post(url, payload).then((response) => {
    return response.data;
  });
};

export default {
  getEvents,
  setEvents,
  updateEvents,
  showEvents,
  deleteEvents,
  getEventTimeline,
  deleteEventTimeline,
  toggleFeaturedTimeline,
  updateEventTimelineDate
};
